import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import ViewTopology from '../../../../../components/controls/viewTopology';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

var beautify = require("json-beautify");

var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class AssignFrymasterRecipeToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        if (libraryDetails.initialMethod === "Upload") {
            this.props.history.push({
                pathname: "/uploadSelectDevice",
                previouspath: "/assignFrymasterRecipeToTopology"
            });
        }
        else {
            this.props.history.push({
                pathname: "/frymasterRecipeCreation",
                isEdit: false,
                previouspath: "/assignFrymasterRecipeToTopology",
            });
        }
    }

    submitAddLibrary = (e,_customId) => {
        e.preventDefault();
        let _this = this;
        let libraryData = KCStore.getItem("libraryData");
        let libraryDataObj = JSON.parse(libraryData);
        let httpMethod;
        
        if (!libraryDataObj && !libraryDataObj.name.en_US) {
            _this.props.history.push(`/frymasterRecipeEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customerid': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'basecountry': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = libraryDataObj.name.en_US + '_' + Date.now();
                    libraryId = libraryId.replace(/\s+/g, '');
                }
                
                libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                libraryDataObj.version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                libraryDataObj.edit_date = todaysDateUTCFormatted;
                libraryDataObj.author = response.data.name;
                libraryDataObj.email = loginUser.username;
                let quality_timer = libraryDataObj.settings.quality_timer;
                libraryDataObj.settings.quality_timer = quality_timer ? "PT" + quality_timer.split(':')[0] + "M" + quality_timer.split(':')[1] + "S" : 0;
                let drain_timer = libraryDataObj.settings.drain_timer;
                libraryDataObj.settings.drain_timer = drain_timer ? "PT" + drain_timer.split(':')[0] + "M" + drain_timer.split(':')[1] + "S" : 0;
                let hold_timer = libraryDataObj.settings.hold_timer;
                libraryDataObj.settings.hold_timer = hold_timer ? "PT" + hold_timer.split(':')[0] + "M" + hold_timer.split(':')[1] + "S" : 0;
                let instant_on = libraryDataObj.settings.instant_on;
                libraryDataObj.settings.instant_on = instant_on ? "PT" + instant_on.split(':')[0] + "M" + instant_on.split(':')[1] + "S" : 0;

                libraryDataObj.ordered_steps && libraryDataObj.ordered_steps.map((item, i) => {
                    libraryDataObj.ordered_steps[i].duration = item.duration ? "PT" + item.duration.split(':')[0] + "M" + item.duration.split(':')[1] + "S" : 0;
                });

                libraryDataObj.conditional_steps && libraryDataObj.conditional_steps.map((item, i) => {
                    let actionduration = item.actions.prompt.duration;
                    let conditionduration = item.condition.property_value;
                    libraryDataObj.conditional_steps[i].condition.property_value = conditionduration ? ((parseInt(conditionduration.split(':')[0]) * 60) + parseInt(conditionduration.split(':')[1])) : 0;
                    libraryDataObj.conditional_steps[i].actions.prompt.duration = actionduration ? "PT" + actionduration.split(':')[0] + "M" + actionduration.split(':')[1] + "S" : 0;
                });

                let resourceDetailsData = KCStore.getItem("resourceDetails");
                let resourceDetailsObj = JSON.parse(resourceDetailsData);
                
                let imageSize = 0.00;
                resourceDetailsObj.map((item, i) => {
                    imageSize = imageSize + item.fileSize ? parseFloat(item.fileSize) : 0.00;
                })

                let resources = resourceDetailsObj;
                delete resources.Import;
                resources && resources.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                });

                libraryDataObj.recipeResources = resources;

                if (libraryDataObj.recipeSegmentType === "Multi") {
                    delete libraryDataObj.settings.set_point;
                }

                // convert JSON object to beautify String
                var jsonStr = beautify(libraryDataObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDataObj.name.en_US,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "Recipe",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": resources[0] && resources[0].s3FileName ? resources[0].s3FileName : "",
                    "imageFileFormat": resources[0] && resources[0].fileFormat ? resources[0].fileFormat.replace('.','') : "",
                    "imageFileSize": resources[0] && resources[0].fileSize ? resources[0].fileSize : "0.00",
                    "imageS3Url": resources[0] && resources[0].s3Url ? resources[0].s3Url : "",
                    "cgids": _customId,
                    "json": JSON.stringify(libraryDataObj),
                    "recipeResources": [],
                    "libraryGuid": libraryDataObj.id
                };

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "KC1222" : "KC1390"
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1235`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler}
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")} 
                    preSelect={libraryDetails.isEdit ? true : false} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignFrymasterRecipeToTopology));