// import axios from 'axios';
import http from '../../../service/httpService';
import React, { Component } from 'react';
import { unitAPI, userRoleURI, DeviceRegistration, smartTagMangement } from '../../../service/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import  authService from '../../../service/authService';
import $ from 'jquery';
import _ from 'lodash';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import authConstants from '../../../utils/authConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import SmartTagUnitDetail from '../smartTag/smartTagUnitDetail';
import KCStore from '../../../utils/kcStorage';

class AddUnit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customerId: parseInt(KCStore.getItem("customerId")),
			brandId: '',
			brandName: "",
			productId: "",
			brandDropDownSource: [],
			serialNumber: null,
			retypeSerialNumber: null,
			AssetNumber: null,
			retypeAssetNumber: null,
			modelFamilyName: "",
			modelName: "",
			error_modal: {
				open: false,
				message: ""
			},
			isFormValidated: false,
			serialNumberMisMatched: false,
			assetNumberMatched: true,
			loaderIconVisible: true,
			registrationCode: "",
			registrationCodeFromIMS:"",
			regCodeNotAlphaNumneric: false,
			regCodeCompination:false,
			errorOccured:false,
			smartTags:[],
			filteredSmartTags: [],
			selectedTag: undefined
		}

		this.getData = this.getData.bind(this);
		this.selectBrand = this.selectBrand.bind(this);
		this.onSerialNumberChange = this.onSerialNumberChange.bind(this);		
		this.AssetNumberChange = this.AssetNumberChange.bind(this);		
		this.addUnit = this.addUnit.bind(this);
	}

	componentDidMount = async() => {
		document.body.style.backgroundColor = "#F2F2F2";

		await this.loadSmartTags().then(stags=>{
			this.setState(()=>{
				return {
					smartTags: stags,
					filteredSmartTags : stags
				}
			})
		})

		http.get(unitAPI.getBrands, {
			headers: {
				'Content-Type': 'application/json'
			},
			data: {}
		}).then(response => {
			let defaultBrandList;
			if (KCStore.getItem("roleName") === "Customer Admin"){
				defaultBrandList = response.data;
			} else {
				defaultBrandList = this.getLoginUserBrands(response.data);
			}
			const sortedBrandList = _.sortBy(defaultBrandList, ['BRAND_NAME']);
			this.setState({
				 brandDropDownSource : sortedBrandList,
			});
			if (defaultBrandList){
				$("#serialNumberdiv").removeClass("disabled");
				$("#reTypeSerialNumberdiv").removeClass("disabled");
				$("#assetNumberdiv").removeClass("disabled");
				$("#reTypeAssetNumberdiv").removeClass("disabled");
			}
		}).catch(err => {
			//Error Goes Here
		});
		this.getAlreadySelInfo();		
		document.addEventListener("keydown", this.preventTabKeyPress);
	}

	getData = (url) => {
	
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					customerId: CUSTOMER_ID, 
					brandId: BRAND_ID
				},
				data: {}
			}).then(response => {
					resolve(this.responseSuccessParser(response));
				}).catch(err => {
					reject(this.responseErrorParser(err))
				})
		});
	}

	loadSmartTags = () => new Promise((resolve, reject)=>{
		let url = smartTagMangement.getTagList + "UNIT";
		let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				customerId:  parseInt(KCStore.getItem("customerId")),
				'cgid': KCStore.getItem("custGroupID"),
                'brandid': BRAND_ID,
                'registrationflag':registrationFlag,
			}
		}).then(resp =>{
			resolve(resp.data.data)			
		}).catch(error=>{
			resolve([]);
		})
	})
	

	responseSuccessParser = (response) => {
		return new Promise((resolve, reject) => {
			const { data } = response;
			if (data && data.length > 0) {
				resolve(response);
			} else {
				let { data } = response;
				if (data) {
					let { body } = data;
					if (body != null)
						reject(body);
				}
			}
		});
	}

	responseErrorParser = (err) => {
		const { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				return data;
			}
		}
	}

	selectBrand = (e) => {

		if (e.target.value) {
			this.state.brandId = e.target.value;
			this.setState({
				brandId: e.target.value,
				registrationCode:"",
				modelName:"",
				serialNumber:"",
				AssetNumber:"",
			},() => {
				if (this.state.brandId && this.state.serialNumber && this.state.customerId) {
					this.validateSerialNumber();
				}
			});			

			$("#serialNumberdiv").removeClass("disabled");
			$("#reTypeSerialNumberdiv").removeClass("disabled");
			$("#assetNumberdiv").removeClass("disabled");
			$("#reTypeAssetNumberdiv").removeClass("disabled");
			
		} else {
			document.getElementById('serialNumber').value = "";
			// document.getElementById('reTypeSerialNumber').value = "";
			document.getElementById('assetNumber').value = "";
			// document.getElementById('reTypeAssetNumber').value = "";

			$("#serialNumberdiv").addClass("disabled");
			$("#reTypeSerialNumberdiv").addClass("disabled");
			$("#assetNumberdiv").addClass("disabled");
			$("#reTypeAssetNumberdiv").addClass("disabled");

			this.setState({
				customerId: parseInt(KCStore.getItem("customerId")),
				brandId: '',
				brandName: "",
				productId: "",
				serialNumber: null,
				retypeSerialNumber: null,
				AssetNumber: null,
				retypeAssetNumber: null,
				modelFamilyName: "",
				modelName: "",
				isFormValidated: false,
				serialNumberMisMatched: false,
				assetNumberMatched: true,
				registrationCode:"",
			});
		}
	}

	preventTabKeyPress = (evt) => {
		if (document.getElementById("selectBrandId")) {
			if (document.getElementById("selectBrandId").selectedIndex === 0 && evt.key === "Tab") {
				evt.preventDefault();
			}
		}
	}
	getSerialNumberValidationUrl() {
		let customerId = this.state.customerId ? `customerId=${this.state.customerId}&` : "";
		let brandId = this.state.brandId ? `brandId=${this.state.brandId}&` : "";
		let serialNumber = this.state.serialNumber ? `serialNumber=${this.state.serialNumber}` : "";
		let url = unitAPI.getSerialNumber + customerId + brandId + serialNumber;
		//url = unitAPI.getSerialNumber + brandId + serialNumber; // due to the chargify implementation, default customer id will be as empty in IMS table.
		return url;
	}

	onSerialNumberChange = (e) => {		
		let value = e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
		if (value) {
			this.setState({loaderIconVisible: true,})
			this.state.serialNumber = value;
			this.setState({
				serialNumber:value
			},()=>{				
				this.validateSerialNumber();				
			})					
		}		
	}

	AssetNumberChange = (e) => {
		this.setState({
			AssetNumber: e.target.value
		});
	}

	validateSerialNumber = () => {
		let url = this.getSerialNumberValidationUrl();
		this.getData(url).then(response => {
			const { data } = response;
			console.log("data",data)
			this.setState({
				modelFamilyName: data[0].MODEL_FAMILY_NAME,
				modelName: data[0].MODEL_NAME,
				brandName: data[0].BRAND_NAME,
				productId: data[0].PRODUCTID,
				registrationCodeFromIMS : data[0].REGISTRATION_CODE,
				componentID: data[0].COMPONENT_ID || ''
			},() => {
				this.setState({loaderIconVisible: false,})
			})
		}).catch(err => {
			if (err && err.body) {
				if (err.body.message === "Unit already registered") {
					this.openErrorModel((err.body.stringID || err.body.message) || "");
				}				 
				$("input").blur();
				// document.onkeydown = function (e) { return false; }
			}
			this.setState({loaderIconVisible: false,})
		});
	}

	closeModel = () => {
		this.setState({
			// isFormValidated: false,
			// modelFamilyName: "",
			// modelName: "",
			// brandName: "",
			// productId: "",
			error_modal: {
				open: false,
				message: ""
			}
		});
		document.onkeydown = function (e) { return true; }
		document.getElementById("assetNumber").focus();
		if (this.state.errorOccured) {
			this.props.history.push(`/unitRegistration`);
		}
	}

	
    callAllocationAPI = () => {
        return new Promise(async(res, rej) => {
            const postData = {
                "componentId": this.state.componentID || '',
                "customer_id": this.state.customerId,
				"subscriptionType": "SAAS",
				"allocationType": "UPGRADE",
				"unitId" : this.state.serialNumber.toString().replace(/ /g,''),
                "chargifyAllocation": {
                    "allocation": {
                        "quantity": 1,
                        "memo": this.state.serialNumber.toString().replace(/ /g,''),
                        "accrue_charge": false,
                        "upgrade_charge": "prorated"
                    }
                }
            };
            await http({
                method: "POST",
                url: DeviceRegistration.allocationApi,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }

	addUnit = (e) => {
		e.preventDefault();

		let loggedinUser;
		let loginUser =   JSON.parse(KCStore.getItem("loginInfo"));
		loggedinUser = loginUser.username;
		const header = {
			'customerid': KCStore.getItem("customerId"),
			'useremail': loggedinUser,
			'basecountry': BASECOUNTRY_ID
		}
		const tags = this.state.filteredSmartTags.filter(tag=> tag.isSelected).map(tag=>tag.id);
		console.log("Selected Tags ", tags)
		this.setState({ loaderIconVisible: true	});
		let postData = {
			customerId: this.state.customerId,
			brandId: parseInt(this.state.brandId),
			serialNumber: this.state.serialNumber.toString().replace(/ /g,''),
			assetNumber: this.state.AssetNumber,
			brandName: this.state.brandName,
			productId: this.state.productId,
			"requestType":"unitRegisterAndUpdateCustomerBrand",
			"existingBrandsAccess":KCStore.getItem("brandId"),
			tags
		};
		http({
			method: "POST",
			url: unitAPI.addUnit,
			headers: header,
			data: postData
		}).then(async response  => {
			if(KCStore.getItem('isEAAS') === false){
				this.updateCustBrandAndFeature(postData);
			}else{
				// Commented because of subscrition management project
				// await this.callAllocationAPI();
				this.updateCustBrandAndFeature(postData);
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false,errorOccured : true });
			if (err && err.response && err.response.data && err.response.data.body && err.response.data.body.message) {
				this.openErrorModel(err.response.data.body.message ? err.response.data.body.message  : "");
			}
		});
	}

	/**
   * Get Default brands which was subscription by customer
   */
  getLoginUserBrands = (brands) =>{
	this.setState({
		loaderIconVisible: true
	});
    let loginUserBrands = authService.getLoginUserBrands();
    let viewBrandsList = [], allBrandsList = [...brands];
    loginUserBrands.forEach(bId => {
      let selectedBrans = allBrandsList.filter(item => item.ID === bId);
      if(selectedBrans && selectedBrans.length>0){
        let brand = {...selectedBrans[0]};
        brand.isChecked = false;
		viewBrandsList.push(brand);
		this.setState({
			loaderIconVisible: false
		});
      }
    });

    return viewBrandsList;
  }

  getAlreadySelInfo = () => {
	let customerId = KCStore.getItem("customerId");
	let userDet = KCStore.getItem("user");
	let token = KCStore.getItem("Access-Token");
	let Urls = unitAPI.getLastSelDetails + `/${customerId}/${userDet}`;
	http.get(Urls,
		{
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			data: {}
		}).then(response => {
			let selBrand = response.data.userHistory || {};
			this.setState({
				loaderIconVisible: false,
				brandId:selBrand.brandId ? selBrand.brandId : ''
			});
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
			})
		});
	};

	IsAlphaNumeric = async (e) => {
		e.target.value = e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
	}

	setRegCode = (value) => {		
		if (this.state.registrationCode !== value) {
			this.setState({ 
				registrationCode: value,	
				regCodeNotAlphaNumneric: false,	
				regCodeCompination: false,						
			});
		}		
	}	
	
	openErrorModel = (errMsg) => {
		this.setState({
			error_modal: {
				open: true,
				message: errMsg,
			}
		});
	}

	validateRegistrationCode = (val) => {		
		if (val.length > 0) {
			if (this.state.registrationCodeFromIMS === val) {
				this.setState({
					regCodeCompination: true,
					regCodeNotAlphaNumneric: false,
				})
				return;
			} else {
				this.setState({ 					
					regCodeCompination: false,	
					regCodeNotAlphaNumneric: false,			
				});
				let errMsg = "KC1092";
				this.openErrorModel(errMsg);
			}
		}
	}	

	setSerialNo = (value) => {	
		this.setState({			
			registrationCode:"",
			modelName:"",
			serialNumber:value,
			AssetNumber:"",
		});	
	}

	updateCustBrandAndFeature = async (postData) => {
		let customerId = KCStore.getItem("customerId");
		let roleId = KCStore.getItem("roleId");
		let Urls = userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + customerId + "&is_featurefunction_required=true"
		await http.get(Urls,
			{
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(async response => {
				let rolefeatures = response.data.data.features;
    			await KCStore.setItem(authConstants.allFeaturesKey, JSON.stringify(rolefeatures));
				let brandIDS = response.data.data.brandIds;
    			await KCStore.setItem('brandId', JSON.stringify(brandIDS));
				this.setState({ loaderIconVisible: false });
				WoopraEvents(`${Constants.ADD_UNIT}`, postData);			
				this.props.history.push(`/unitRegistration`);					
			}).catch(err => {
				this.setState({ loaderIconVisible: false });
				this.props.history.push(`/unitRegistration`);
			});
	}

	handleSmartTagSearch = (e) => {
		const searchString = (e.target.value).toLowerCase();
		const smartTags = [...this.state.smartTags];
		const filterTags = smartTags.filter(item => item.tagName.toLowerCase().includes(searchString));
		this.setState(()=>{
			return{
				filteredSmartTags: filterTags
			}
		})
	}	
	handleTagSelect = (index) => { 
		const { filteredSmartTags } = this.state;
		const updatedTags = [...filteredSmartTags];
		updatedTags[index].isSelected = !updatedTags[index].isSelected;
	  
		// Sort the array based on isSelected and tagName properties
		updatedTags.sort((a, b) => {
		  if (a.isSelected && !b.isSelected) {
			return -1;
		  } else if (!a.isSelected && b.isSelected) {
			return 1;
		  } else {
			return a.tagName.localeCompare(b.tagName);
		  }
		});
	  
		this.setState({ filteredSmartTags: updatedTags });
	  }
	  
	  
	showDetailPopUp = (selectedTag) =>{
		selectedTag = {selectedRow:{...selectedTag}, mode: 'isView', type: 'unitList'}
		KCStore.setItem('smartTagInfo', JSON.stringify(selectedTag))
		
		this.setState(()=>{
			return {
				selectedTag
			}
		}, ()=>{
			$('#stUnitDetailsModal').modal('show');
		})
		
	}

	

	render() {
		const { formatMessage } = this.props.intl;
		let {
			brandDropDownSource,
			customerId,
			brandId,
			serialNumberMisMatched,
			serialNumber,
			retypeAssetNumber,
			modelFamilyName,
			modelName,
			error_modal,
			AssetNumber,
			assetNumberMatched,regCodeCompination,
			isFormValidated,loaderIconVisible,registrationCode,regCodeNotAlphaNumneric } = this.state;

		if (AssetNumber || retypeAssetNumber) {
			assetNumberMatched = AssetNumber && retypeAssetNumber && AssetNumber === retypeAssetNumber;
		} else {
			assetNumberMatched = true;
		}


		isFormValidated = serialNumber && brandId && customerId && modelName && registrationCode && regCodeCompination && regCodeNotAlphaNumneric === false;// !serialNumberMisMatched && assetNumberMatched;
        if(isFormValidated){
			$("#btnfinish").addClass("activeState");
			$("#btnfinish").removeClass("disabled");
		}

		modelFamilyName = (modelFamilyName === undefined || modelFamilyName === '') ? 'Model Family' : modelFamilyName;
		modelName = (modelName === undefined || modelName === '') ? 'KC0064' : modelName;
		
		//Convotherm updates for Mini & maxx Customer Asset Number & Retype Customer Asset Number placeholder label changed into Article Number & Retype Article Number 
		let showArticleNumber = (brandId && brandId === 6) ? "KC1692" : "KC0653";
		let showRetypeArticleNumber = (brandId && brandId === 6) ? "Retype Article Number" : "Retype Customer Asset Number";
		let showArticleNumberError = (brandId && brandId === 6) ? "Article Number does not Match" : "Asset Number does not Match";
			
		return (
			<React.Fragment>

				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div className="loadingProgress">
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">

						<div className="unitRegistrationWrapper">
							<div className="addUnitHeader">
								<ul>
									<li>
										<Link to="/unitRegistration" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
									</li>
									<li>
										<FormattedMessage id="KC0650" />
									</li>
									<li className={isFormValidated ? "" : "disabled"}>
										<button id="btnfinish" type="button" className="btn btn-default-text unitAdd" title={formatMessage({ id: 'KC0056' })} onClick={(e) => this.addUnit(e)}><FormattedMessage id="KC0056" /></button>
									</li>
								</ul>
							</div>

							<div className="unitRegFormWrapper alignCenter">
								<div className={this.state.smartTags.length > 0 ? 'unitRegForm floatLeft':"unitRegForm"  }>
									<ul>
										<li className="marginBottom10px">
											<div className="input-group">
												<select className="form-control" id="selectBrandId" onChange={(e) => this.selectBrand(e)}>
													<option value="">{formatMessage({ id: 'KC0651' })}</option>
													{brandDropDownSource.map((item, i) =>
														<option key={i} value={item.ID} selected={brandId === item.ID}>{item.BRAND_NAME}</option>
													)}
												</select>
											</div>
										</li>
										<li>
											<div id="serialNumberdiv" className="input-group disabled">
												<input autocomplete="off" className="form-control" id="serialNumber" type="text" maxlength="30" placeholder={formatMessage({ id: 'KC0104' })} onBlur={(e) => this.onSerialNumberChange(e)} onChange={(e) => this.setSerialNo(e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, ''))} value={serialNumber} />
												<span className="requiredField">*</span>
											</div>
										</li>
										<li>
											<div className="input-group">
												<input type="text" className="form-control" placeholder={formatMessage({ id: 'KC0652' })} maxlength="21" onKeyUp={(e) => this.IsAlphaNumeric(e)} onBlur={(e) => this.validateRegistrationCode(e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, ''))} onChange={(e) => this.setRegCode(e.target.value.trim().replace(/[^a-zA-Z 0-9\n\r.]+/g, ''))} value={registrationCode} />
												<span className="requiredField">*</span>
												{regCodeNotAlphaNumneric ? <span className="field-validation-error"><FormattedMessage id="KC1691" /></span> : ""}
											</div>
										</li>
										<li>
											<div id="assetNumberdiv" className="input-group disabled">
												<input autoComplete="off" className="form-control" id="assetNumber" type="text" placeholder={formatMessage({ id: showArticleNumber })} onBlur={(e) => this.AssetNumberChange(e)} onChange={(e) => this.AssetNumberChange(e)} value={AssetNumber} />
											</div>

										</li>
										<li>
											<div className="input-group disabled">
												<div className="form-control"><FormattedMessage id={modelName} /></div>
											</div>
										</li>
									</ul>
									<div className="requiredNoteDiv"><FormattedMessage id="KC0130" /></div>
								</div>
								{this.state.smartTags.length>0 && <div className="smartTagsList">
									
									<div className="STunitsTable">
										<ul className="tHead">
											<li>
												<div className="tableSerarchBar">
													<input className="form-control tagsSearch" type="text" placeholder={formatMessage({ id: 'KC2618' })} onChange={this.handleSmartTagSearch} />
												</div>
											</li>
											<li><FormattedMessage id='KC0325' /></li>
											<li><FormattedMessage id='KC2131' /></li>
										</ul>
										
									</div>
									<div className="STunitsTableSrlBar">
										<div className="STunitsTable">
											{this.state.filteredSmartTags && this.state.filteredSmartTags.length ? this.state.filteredSmartTags.map((item, index)=>(
												<ul className={item.isSelected === true ? 'tBody activeRow': 'tBody' }  key={item.id}>
												<li><div className="tagTypeUnit"><span>{item.tagName}</span></div></li>
												<li><button type="button" className="btn stUnitsDetails" onClick={()=> this.showDetailPopUp(item)} data-toggle="modal">&nbsp;</button></li>
												<li>
													<div className="customCheckbox customCheckboxWOlabel">
														<input id={item.tagName} name="STunits" type="checkbox" value={item.isSelected} checked={item.isSelected} onChange={()=>this.handleTagSelect(index)} />
														<label for={item.tagName}></label>
													</div>
												</li>
											</ul>
											)):<div className='alignmentCenter'><FormattedMessage id="KC0791"/></div>}
										</div>
									</div>
								</div>}
								<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							</div>
						</div>
					</div>
					<UserManagementHeader headerName="Unit Registration and Assignment" headerNameStringID="KC1684" activeClass="UnitRegistrationNav" />
				</div>
				{this.state.selectedTag  &&
				<SmartTagUnitDetail 
					smartTag={this.state.selectedTag}
					resetState={()=>this.setState({ selectedTag: null })}
					onDownloadStart={()=> this.setState({loaderIconVisible: true}) } 
					onDownloadFinish={()=> this.setState({loaderIconVisible: false})}
				/>}
			</React.Fragment>
		)
	}
};

export default injectIntl(AddUnit);
