
import axios from 'axios';
import {BaseService} from '../../../service/api';
import KCStore from '../../../utils/kcStorage';

function uploadImageToCloud(getImageUploadUrl,postData,imagevideodata,httpType,url,isImageUpload) {
	
	return new Promise((resolve, reject) => {
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let token = KCStore.getItem('Access-Token');
        if(isImageUpload){
		axios({
			url : BaseService.root + getImageUploadUrl,
		    method :'get',
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${token}`,
			},
			data:{}	
		}).then(response => {
			let resvalue = response.data.body.message.url;
			// let Uploadeds3url = resvalue.split('?');
			// Uploadeds3url = Uploadeds3url[0];

			var xhr = new XMLHttpRequest();
			xhr.open("PUT", resvalue);
			xhr.setRequestHeader('Content-Type', 'multipart/form-data');
			xhr.onloadend =  (res) => {
				 if(res.target.status == 200){
					// postData.s3Url  = Uploadeds3url;					                					
					axios({
						method:httpType,
						url:BaseService.root + url,
						data: postData,
						headers: {
							'Content-Type': 'application/json', 
							'Authorization': `Bearer ${token}`,
							'userid': loggedinUser
						}
					}).then(res =>{
                        let { data } = res;
                        let { httpCode,  message } = data;
                       if(httpCode === 200){                          
                         resolve(message);
						}
						else{
							reject(res);
						}
                    }).catch(err => {
                        reject(err);
                    });
				 }
			};
			xhr.onerror = (err) =>{
                reject(err);
			};
			xhr.send(imagevideodata);

		
		}).catch(err => {
			reject(err);
        });
    } else {
        axios({
            method:httpType,
            url:BaseService.root + url,
            data: postData,
            headers: {
                'Content-Type': 'application/json', 
				'Authorization': `Bearer ${token}`,
				'userid': loggedinUser
            }
        }).then(res =>{
            let { data } = res;
            let { httpCode,  message } = data;
           if(httpCode === 200){                          
             resolve(message);
            }
            else{
                reject(res);
            }
        }).catch(err => {
            reject(err);
        });
    }
	});
}

export default uploadImageToCloud