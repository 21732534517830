import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo,smartGroupAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BaseService, ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import { getSelectedFilterList,resetSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserProfile } from '../../../service/api';
import { ungzip } from 'pako';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class OrganizationChartListView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			total_unit_count: 0,
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: '',
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",			
            oldSmartGroupName : "",
            oldSelectedPurposes:[],
			sumartGroupID:"",
			oldSelUnits:[],
			findPageName:"",
			dynastyValue:[]
		};

		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);

		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);

	}

	componentDidUpdate(prevState) {
		this.updateDimensions();
	}
	updateDimensions() {
		const windowHeight = $(window).height();
		const assignUListSrlBar = $(".assignUListSrlBar");
		assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	}

	componentDidMount= async () => {
		document.body.style.backgroundColor = "#F2F2F2";
		let cgid = KCStore.getItem('orgCgid');

        await this.getDynacity(cgid);
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		} 
		await this.getUnitDetails();        
        let {selectedoptionsFromReport,oldSelectedPurposes} = this.state;
        if (selectedoptionsFromReport === undefined ||selectedoptionsFromReport==="" || oldSelectedPurposes === undefined ||oldSelectedPurposes===""){
            this.getSmartGroupDetails();
        }
	}

	getDynacity = (selCgid) => {
		http.get(`${UserProfile.get_Dynacity_Elements}`,
		{
			headers: {
				'Content-Type': 'application/json',
				"cgids": selCgid,
			},
			data: {}
		})
		.then(response => {
            this.setState({dynastyValue:response.data.cgids})
		}) .catch(error => {
			this.setState({
				linkListValue: {},
				loaderIconVisible: false,
			})
		})
    }

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch(err) {
			console.error(err);
			return null;
		}
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {			
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
				oldSmartGroupName:this.props.location.state.oldSmartGroupName,
				oldSelectedPurposes:this.props.location.state.oldSelectedPurposes,
				sumartGroupID:this.props.location.state.sumartGroupID,
				findPageName:this.props.location.state.pageFrom,
			});
		}
		
	}

	getUnitDetails = async () => {
        this.setState({ loaderIconVisible: true });
        http({
            method: "GET",
            url: smartGroupAPI.createSmartGroupWithUnit,
            headers: {
                'Content-Type': 'application/json',
                'sgid': KCStore.getItem("sgID"),
                'cgid': KCStore.getItem("custGroupID"),
            }, data: {},
        }).then(response => {
            let { data } = response;
            if( data && data.units && data.units.length > 0) {
                const unitList = [];
                data.units.map(item => {
					if (item.isDeleted==="0" || item.isDeleted===0){
						return unitList.push(item.uId)
					}
                });
                this.setState({
                    oldSelUnits:unitList,
                    loaderIconVisible: false,
                })
            }

        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
            })
        });

    }

    getSmartGroupDetails = () => {
        this.setState({ loaderIconVisible: true });
        const sgid=KCStore.getItem("sgID") ;
        let queryString;
        queryString = `customerId=${KCStore.getItem("customerId")}`
        queryString = queryString + `&userId=${KCStore.getItem("user")}`
        
        let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;        
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let sGList = response.data;
                let selSignleSMGList=[];
                let exisPurposesIDS = [];

                sGList.map(i => {
                    if (i.smartGrpId === parseInt(sgid)) {
                        selSignleSMGList.push(i);
                    }
        
                });

                if (selSignleSMGList && selSignleSMGList.length>0){                    
                        selSignleSMGList[0].purpose.map(item => {
                        return exisPurposesIDS.push(item.purposeId)
                    });
                }

                this.setState({
					oldSmartGroupName:selSignleSMGList && selSignleSMGList.length>0?selSignleSMGList[0].smarGrpName:"",
                    selectedoptionsFromReport:this.state.selectedoptionsFromReport?this.state.selectedoptionsFromReport:exisPurposesIDS,
                    oldSelectedPurposes:this.state.oldSelectedPurposes?this.state.oldSelectedPurposes:exisPurposesIDS,
                    loaderIconVisible: false,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });

    }

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	requestData = async () => {		
		let selectedModels = [];
		let custGroupID = KCStore.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${UnitInfo.unitList}?compress=true`;

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid':KCStore.getItem("customerId"),
			'brandid': KCStore.getItem("unitBrandId"),
			'model': selectedModels.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			'basecountry': BASECOUNTRY_ID,			
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				if (response.data.compressed) {
					response.data.units = this.decompressData(response.data.units) || [];
				}
				let { units, total_unit_count, } = response.data;
				units = UnitBuilder(units);
				// const ids = this.props.location && this.props.location.getCgids ? this.props.location.getCgids.split(',') : [];
				const ids = this.state.dynastyValue && this.state.dynastyValue.length ? this.state.dynastyValue : [];
				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				let array1 = units;
				
				_.forEach(array1, a1 => {
                    const ifExist = ids.find(a2 =>  Number(a2) === a1.CUSTOMER_GROUP_ID); 
                    a1.checked = ifExist ? true : false;
                }); 

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll,
					total_unit_count: total_unit_count
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {		
		this.props.history.push({
			pathname: this.props.location.previousPath,
			state: {
				nameFromReport: this.state.nameFromReport,
				startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
				endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
				selectedoptionsFromReport: this.state.selectedoptionsFromReport,
				pageFrom:this.state.findPageName,
			},
		});		
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {

	};


	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c === true);

		if (checkAllTrue.length > 0) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}

	getSmartGroupID = async (e) => {
		this.setState({ loaderIconVisible: true, })		
		let postdata = {
            "name": this.state.nameFromReport,
			"purposeId": this.state.selectedoptionsFromReport,
			"customerId": KCStore.getItem("customerId"),
            "userId": KCStore.getItem("user"),
        };
		await http.post(smartGroupAPI.createSmartGroup , postdata,
			{
				headers: {
					'Content-Type': 'application/json',					
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });
				this.submitForExportReport(response.data.smartGrpID)
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	submitForExportReport = async () => {
		this.setState({ loaderIconVisible: true, })
		let selectedUnitList = [];
		let userChecked = this.state.userCheckedData;
		if (userChecked && userChecked.length > 0) {
			userChecked.forEach(function (item, index) {
				selectedUnitList.push(item.ID);
			})
		} else if (this.state.userSelectAll) {
			if (this.state.data && this.state.data.length > 0) {
				this.state.data.forEach(function (item, index) {
					selectedUnitList.push(item.ID);
				})
			}
        }
		let newSGDetails = JSON.parse(KCStore.getItem("afterEditSG"));
		let oldSGDetails = JSON.parse(KCStore.getItem("beforeEditSG"));
		let postdata = {
			"smartGroupId": KCStore.getItem("sgID"),
			"newName": newSGDetails.smarGrpName,
			"oldName": oldSGDetails.smarGrpName, 
			"newPurposeIds": newSGDetails.purposes,
			"oldPurposeIds": oldSGDetails.purposes,               
			"oldUnits": this.state.oldSelUnits.length>0?this.state.oldSelUnits:[],
			"newUnits": selectedUnitList,
			"customerId": KCStore.getItem("customerId"),
			"userId": KCStore.getItem("user"),
		};
		await http.put(smartGroupAPI.createSmartGroup, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });

				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: "KC1661",
					},
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	onFinishClickHandler() {
		let userCheckedData = this.state.userCheckedData;
		const { userSelectAll, data } = this.state;
		const unitList = [];
		if (userSelectAll === true) {
			data.map(item => {
				return unitList.push(item.CLOUD_UNIT_NAME);
			});
		} else {
			userCheckedData.map(item => {
				return unitList.push(item.CLOUD_UNIT_NAME);
			});
		}

		const unitData = [];
		if (userSelectAll === true) {
			data.map(item => {
				return unitData.push(
					{
						"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
						"UNITID": item.UNITID,
						"productId": item.PRODUCT_ID,
						"dynastyAttributes": item.dynastyAttributes,
					}
				)
			});
		} else {
			userCheckedData.map(item => {
				return unitData.push(
					{
						"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
						"UNITID": item.UNITID,
						"productId": item.PRODUCT_ID,
						"dynastyAttributes": item.dynastyAttributes,
					}
				)
			});
		}

		//modelNumber
		let startdateofReport = this.props.location.selectedstartDate;
		startdateofReport = new Date(startdateofReport);
		startdateofReport = format(startdateofReport, 'YYYY-MM-DD');

		let enddateofReport = this.props.location.selectedendDate;
		enddateofReport = new Date(enddateofReport).toDateString();
		enddateofReport = format(enddateofReport, 'YYYY-MM-DD');

		let token = KCStore.getItem('Access-Token');
		let ErrorReportPostData = {
			startDate: startdateofReport,
			endDate: enddateofReport,
			timeZone: "abc",
			unitIds: unitList,
			unitData: unitData
		};

		http({
			method: "POST",
			url: `${BaseService.root}${ReportManagement.deviceDataApi}`,
			data: ErrorReportPostData,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		}).then(response => {
			if (response)
				this.setState({
					loaderIconVisible: false,
					errorResponse: response.data.fileUrl
				});
			$("#exportReport").addClass("export-error-report");
			window.location = response.data.fileUrl;
		}).catch(err => {
			this.showError(err);
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = async () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/smartgroupslist`);
		await this.resetValue();		
	}

	resetValue = () =>{
        KCStore.removeItem("smartGroupFilter");
        const arr=[];
        this.props.resetSelectedFilterList(arr);
    }

	onClickListView = () => {
        this.props.history.push({
            pathname: "/organizationChartView",
            getCgids:this.props.location.getCgids,
			previousPath: this.props.location.previousPath
        });
    }

	render() {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal } = this.state;
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [ 
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0101", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0675" },
			{ path: "Within_Location", labelStringID: "KC0061" },
			{ path: "Smart_Group", labelStringID: "KC0065" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "" },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		let showHeader;

		showHeader = (
			<ul>
				<li><FormattedMessage id="KC1570" values={{ selectedCount: this.state.unitsSelected, offlineCount: this.state.unitsOffline }}/></li>
				<li>
					<button id="btnAssign" className="assignunitSite" onClick={this.onClickListView}>&nbsp;</button>
					<Link to="#" id="btnAssign" className="assignunitList active">&nbsp;</Link>
				</li>
			</ul>
		);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="reportWrapper">
							<div class="adminBgHeader">
								<ul>
									<li>
										<button onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</button>
									</li>
									<li className="txtboldmsg">
									<FormattedMessage id="KC1542"/>
                  </li>									
								</ul>
							</div>
							<div class="selectedUnitHeader">
								{showHeader}
							</div>
							<form class="SGlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="assignUListSrlBar">
								{unitList.map((item, i) => {
										const title = item.smartGroups && item.smartGroups.length > 0 ? item.smartGroups.map((i)=>i.smartGroupName) : [];
										return (
											<React.Fragment key={i}>
												<div class="SWlistUnitsTable">
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}>
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} alt="" /></li>
														<li>{item.BRAND_NAME}</li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID}</li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>
															{item.smartGroups && item.smartGroups.map((item, i) => {
																if(i===3){
																	return <span title={title.join(',')} className="contentOverflow active"> ... </span>
																}else if(i<=3){
																	return <span title={item.smartGroupName} className="sgFL">{item.smartGroupName.charAt(0)}</span>
																}
															})}
														</li>

														<li><span className={`${item.CONNECT_STATE === 'Offline' ? "" : ""}`}></span></li>
														{<li>
															<div className="customCheckbox customCheckboxWOlabel">
																<input id={item.order_id - 1} disabled type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={this.state.dynastyValue.includes(item.CUSTOMER_GROUP_ID)} />
																<label for={item.order_id - 1}></label>
															</div>
														</li>}
													</ul>
												</div> 
											</React.Fragment>
										)
									}
									)} 
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
							</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />					
					<UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" activeClass="mediaNav" history={this.props.history}/>
				</div>
				<div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationContentDiv">
									<h5><FormattedMessage id="KC0832"/></h5>
									<p><FormattedMessage id="KC1571" values={{ Updatedunits, unitsSelected, unitsOffline }}/></p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button"><FormattedMessage id="KC0777"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};


function mapStateToProps(state) {
    return {
		filterlistInformtion: state.filterModel,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
		getSelectedFilterList,
		resetSelectedFilterList,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrganizationChartListView));