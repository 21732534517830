import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import axios from 'axios';
import { MenuUploadAPI, BaseService, MediaManagementAPI, UnitInfo } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID } from '../../../../../utils/appConstants';
import  LibraryListPopup  from '../../libraryListPopup';
import  MediaListPopup  from '../../mediaListPopup';
import http from '../../../../../service/httpService';
import $ from 'jquery';
import { Cropper } from '../../../../controls/cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import { nanoid } from 'nanoid';
var request = require('request');

class StageInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            stageName: "",
            categoryImage: null,
            srcfile: {},
            importOption: "Library",
            showCropPopup: false,
            isImageChanged: false,
            isImageDeleted:false,
            cropWidth: 100,
            cropHeight: 100,
            isChanged: false,
            showDiscardPopup: false
        }
        this.onSave = this.onSave.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.stageNameChange = this.stageNameChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
        this.cancelImportClick = this.cancelImportClick.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
    }

    componentDidMount = async () => {
        let recipeStageIndex =  parseInt(KCStore.getItem("recipeStageID"))
        let libraryData = JSON.parse(KCStore.getItem("libraryData"));
        if(!KCStore.getItem("isConnexUser")){
			await this.getConnexUnits();
		}
        this.setState({
            stageName:libraryData[recipeStageIndex].instruction,
            imageDatab64:libraryData[recipeStageIndex].instructionImageb64
        })
        document.body.style.backgroundColor = "#000000";
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/stageInformation"
        });
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            previouspath: "/stageInformation",
        });
    }

    handleInputClick = (e) => {
        this.inputElement.click();
    }

    stageNameChange = (e) => {
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                stageName: e.target.value,
                isChanged: true
            });
        // }
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            this.props.history.push({
                pathname: "/recipeCreation",
                previouspath: "/stageInformation",
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }


    onSave = () => {
        this.setState({
            loaderIconVisible: true
        });
        let {isImageChanged} = this.state;
        let recipeStageIndex =  parseInt(KCStore.getItem("recipeStageID"))
        let libraryData = JSON.parse(KCStore.getItem("libraryData"));
        let stageName = this.state.stageName.trim();
        if (stageName.length === 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2253" 
                }
            });
        }
        else if (!this.state.imageDatab64) {
            libraryData[recipeStageIndex].instruction = stageName;
            libraryData[recipeStageIndex].instructionImage = "";
            libraryData[recipeStageIndex].instructionImageb64 = "";
            libraryData[recipeStageIndex].instructionImageS3Url = "";
            libraryData[recipeStageIndex].instructionImageSize = "";
            KCStore.setItem("libraryData", JSON.stringify(libraryData));
            this.props.history.push({
                pathname: "/recipeCreation",
                previouspath: "/stageInformation"
            });
        }
        else if (this.state.imageDatab64 && !isImageChanged) {
            libraryData[recipeStageIndex].instruction = stageName;
            KCStore.setItem("libraryData", JSON.stringify(libraryData));
            this.props.history.push({
                pathname: "/recipeCreation",
                previouspath: "/stageInformation"
            });
        }
        else {
            // let imagefilekey = stageName + '_' + Date.now() + this.state.imageFileFormat;
            let imagefilekey = nanoid() + '_' + Date.now() + this.state.imageFileFormat;
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey':encodeURIComponent(imagefilekey),
                'jsonfilekey': "",
                'audiofilekey': "",
                'isthumbnail': true,
                'encryption' : "true"
            }

            return new Promise((resolve, reject) => {
                let token = KCStore.getItem('Access-Token');
                headerpresigned.Authorization = `Bearer ${token}`;
                axios({
                    url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                    method: "GET",
                    headers: headerpresigned,
                    data: {}
                }).then(response => {
                    let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                    let imageurl = imagePresignedURL;
                    let thumbnailurl = thumbnailPresignedURL;

                    let UploadedImages3url = "";
                    if (imageurl) {
                        UploadedImages3url = imageurl.split('?');
                        UploadedImages3url = UploadedImages3url[0];
                    }
                    var xhrthumb = new XMLHttpRequest();
                    xhrthumb.open("PUT", thumbnailurl);
                    xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                    xhrthumb.onloadend = (res) => {
                        if (res.target.status == 200) {
                            var xhrimage = new XMLHttpRequest();
                            xhrimage.open("PUT", imageurl);
                            xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                            xhrimage.onloadend = (res) => {
                                if (res.target.status == 200) {
                                    libraryData[recipeStageIndex].instruction = stageName;
                                    libraryData[recipeStageIndex].instructionImage = "/Resources/Images/" + imagefilekey;
                                    libraryData[recipeStageIndex].instructionImageb64 = this.state.imageThumbnailb64;
                                    libraryData[recipeStageIndex].instructionImageS3Url = UploadedImages3url;
                                    let imageSize = Buffer.byteLength(this.state.imageDataBuffer);
                                    libraryData[recipeStageIndex].instructionImageSize = (imageSize / 1048576).toFixed(2);
                                    KCStore.setItem("libraryData", JSON.stringify(libraryData));
                                    this.props.history.push({
                                        pathname: "/recipeCreation",
                                        previouspath: "/stageInformation"
                                    });
                                }
                            };
                            xhrimage.onerror = (err) => {
                                reject(err);
                            };
                            xhrimage.send(this.state.imageDataBuffer);
                        }
                    };
                    xhrthumb.onerror = (err) => {
                        reject(err);
                    };
                    xhrthumb.send(this.state.imageThumbnailBuffer);
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName;
            let fileext =  fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.'+fileext, "");
            let filelength = fileName.length;

            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            //User Story 86440: MRE - Uploading a picture of minimum 100*100 pixels size in Category/Sub-Category/Recipes - Connex supports all sizes above or below 100*100 pixels.
                            if ((width > 100 || height > 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								this.setState({
									loaderIconVisible: false,
									showCropPopup:true,
									src: reader.result,
                                    cropWidth: width < height ? width : height,
									cropHeight: width < height ? width : height,
									imageData: imageData
								});
								$('#popupTrigger').trigger('click');
							}
                            else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (srcthumb) => {
                                            srcthumb = srcthumb.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(srcthumb, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 400,
                                                maxHeight: 400,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageFileName: fileName,
                                                        imageFileFormat: "."+ fileext,
                                                        imageFileSize: fileSize,
                                                        categoryImage: URL.createObjectURL(srcfile),
                                                        srcfile: srcfile,
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: srcthumb,
                                                        isImageChanged: true,
                                                        isChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                }
                                            }).createThumbnail();
                                        },
                                        onError: function (err) {
                                            this.setState({
                                                loaderIconVisible: false
                                            });
                                        }
                                    }).createThumbnail();
                                }
                            };
                        }
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1225" 
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249" 
                    }
                });
            }
            event.target.value = '';
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    handleInputClick = (e) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#ConfirmationOfImportModal').modal('hide');
        }
    }

    closeCropPopup = () => {
		this.setState({
			showCropPopup: false
		});
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    getConnexUnits = () => {
        const { formatMessage } = this.props.intl;
		this.setState({
			loaderIconVisible: true
		});
		let url = `${UnitInfo.list}`;
		let pageNumber = '?pageNum=1';
		let pageLimit = '&pageSize=50';

		let apiUrl = url + pageNumber + pageLimit;

		let searchParam = {
			'modelName': 'connex'
		};
		apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
		return new Promise((resolve, reject) => {
			http.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'cgid': KCStore.getItem("custGroupID"),
					'cid': CUSTOMER_ID,
					'brandid': BRAND_ID,
					'model': "",
					'machinetype': "",
					'sortby': "",
					'sorttype': ""
				},
				data: {}
			}).then(response => {
				KCStore.setItem("isConnexUser", response &&  response.data && response.data.units ? response.data.units.length > 0 : false)
                this.setState({
                    loaderIconVisible: false
                });
                resolve(response);
			}).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
				reject(err);
			});
		});
	}

    async OnImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let md5 = require('md5');
            let imageData = {};
            imageData.imagefileName = item.FILE_NAME;
            imageData.imagefileext = item.FILE_FORMAT.replace('.','');
            imageData.imagefileNamewithOutExt = item.FILE_NAME.replace(item.FILE_FORMAT, '');
            imageData.imagefileSize = item.FILE_SIZE;
            imageData.imagefilelength = item.FILE_SIZE;

            imageData.resolution = item.RESOLUTION;
            imageData.category = 'picture';
            if(item.FILE_FORMAT === '.svg'){
                var res = await fetch(item.thumbUrl);
                var blob = await res.blob();
                let thumbsrc = await new Promise((resolve, reject) => {
                  var reader  = new FileReader();
                  reader.addEventListener("load", function () {
                      resolve(reader.result);
                  }, false);
                  reader.onerror = () => {
                    return reject(this);
                  };
                  reader.readAsDataURL(blob);
                })
                let imgBuffer =  this.base64ToArrayBuffer(thumbsrc.replace("data:multipart/form-data;base64,", ""))
                imageData.crc = md5(blob, "base64");
                _this.setState({
                    imageFileName: item.FILE_NAME,
                    imageFileFormat: item.FILE_FORMAT,
                    imageFileSize: item.FILE_SIZE,
                    categoryImage: null,
                    srcfile: imgBuffer,
                    imageDataBuffer: imgBuffer,
                    imageDatab64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    imageData: imageData,
                    imageThumbnailBuffer: imgBuffer,
                    imageThumbnailb64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    isImageChanged: true,
                    loaderIconVisible: false
                });
            }else{
            await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'type': item.TYPE
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                        let src = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                        imageData.crc = md5(body, "base64");
                        Jimp.read(body)
                        .then(image => {
                            image.resize(80, 80, (err, image)  => {
                                image.getBufferAsync('image/png').then(buffer => {
                                    let thumbsrc = buffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                    _this.setState({
                                        imageFileName: item.FILE_NAME,
                                        imageFileFormat: item.FILE_FORMAT,
                                        imageFileSize: item.FILE_SIZE,
                                        categoryImage: null,
                                        srcfile: body,
                                        imageDataBuffer: body,
                                        imageDatab64: src,
                                        imageData: imageData,
                                        imageThumbnailBuffer: buffer,
                                        imageThumbnailb64: thumbsrc,
                                        isImageChanged: true,
                                        loaderIconVisible: false
                                    });
                                })
                            })
                        })
                        .catch(err => {
                            // Handle an exception.
                        });
                    })
                })
                .catch(ex => {
                    console.log(ex);
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
            }
        }
        catch (ex) {
            console.log(ex);
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
		
        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                    image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                        let md5 = require('md5');
                        let fileSize = Buffer.byteLength(imagebuffer);
                        fileSize = (fileSize / 1048576).toFixed(2);
                        _this.state.imageData.resolution = "400 x 400";
                        _this.state.imageData.category = "picture";
                        _this.state.imageData.crc = md5(imagebuffer);
                        _this.state.imageData.fileSize = fileSize;
                        Jimp.read(imagebuffer)
                        .then(thumbnail => {
                            thumbnail.resize(80, 80, (err, thumbnail) => {
                                thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                    thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                        _this.setState({
                                            imageFileName: croppedImage.name,
                                            imageFileFormat: "." + croppedImageType.replace('image/',''),
                                            imageFileSize: fileSize,
                                            categoryImage: null,
                                            srcfile: imagebuffer,
                                            imageDataBuffer: imagebuffer,
                                            imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            imageData: _this.state.imageData,
                                            imageThumbnailBuffer: thumbnailbuffer,
                                            imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            isImageChanged: true,
                                            loaderIconVisible: false
                                        });
                                    })
                                })
                            })            
                        })
                    })
                })
            })            
        })
        .catch(err => {
            console.log(err);
            _this.setState({
                loaderIconVisible: false
            });
        })
	}

    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    cancelImportClick = (event) => {
        $('#ConfirmationOfImportModal').modal('hide');
    }

    onClearImage = () => {
        this.setState({
            imageDatab64: null,
            imageThumbnailb64: null,
            isImageDeleted: true,
            isImageChanged: true
        });
    }

    render() {
        let { stageName, loaderIconVisible, imageDatab64, error_modal, success_modal, imageData, importOption, cropWidth, cropHeight, isChanged, showDiscardPopup } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload disabled" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1211"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div className="rcSaveForm">
                                                    <div className="rcNameDiv">
                                                        <label><FormattedMessage id="KC2254"/></label>
                                                        <input type="text" maxLength="30" className="form-control" value={stageName} onChange={(e) => { this.stageNameChange(e) }} />
                                                    </div>
                                                    <div className="importImageDiv editActive">
                                                        <div className="importImage">
                                                            <button type="button" class="btn cancelButton" onClick={() => this.onClearImage()}>&nbsp;</button>
                                                            <div className="menuBrowse" title={formatMessage({ id: 'KC1251' })}>
                                                                <a href="javascript:void(0)" class="menuBrowse" data-toggle="modal" data-target="#ConfirmationOfImportModal" title={formatMessage({ id: 'KC1251' })}><img src={imageDatab64 ? `data:${'image/png'};base64,${imageDatab64}` : require("../../../../../images/recipecreation/photosPictures.png")} /></a>
                                                            </div>
                                                        </div>
                                                        <label><FormattedMessage id="KC1229"/></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSave() }} className="btn btn-default-text saveButton" title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div className="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Confirmation Modal --> */}
                <div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="selectRecipeModal" tabindex="-1" role="dialog" aria-labelledby="selectRecipeModalLabel">
                    <div class="modal-dialog modal-lg">
                        <LibraryListPopup libraryType={'CookBook'} />
                    </div>
                </div>
                {/* <!-- //Confirmation --> */}
                {/* <!-- Import Image Modal --> */}
                { KCStore.getItem("isConnexUser") && <MediaListPopup mediaType={'image'} OnImportImage={(item) => this.OnImport(item)} /> }
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1232"/></h5>
                                    <div class="uploadOption">
                                        <div class="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id='KC1924' /></label>
                                        </div> 
                                        <div class="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id='KC0021' /></button>
                                <input type="file" accept="image/png, image/jpeg, image/jpg,image/gif,image/bmp,image/svg+xml" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                <button class="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleInputClick}><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(StageInformation)
