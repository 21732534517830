import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { LocationInfo } from '../../../service/api';
import { LocationBuilder } from '../../../utils/locationResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BaseService, ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import multiDownload from './multiple-file-download';
import OfflineMediaImg from '../../../images/icons/offlineT.svg';
import KCStore from '../../../utils/kcStorage';

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class ReportAssignStoreList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect:false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			total_stores_count: 0,
			nameFromReport: '',
			reportName:'',
			startDate: '',
			endDate: '',
			reportByUnit: false,
			reportByStore: false,
			reportFormat: "XLSX",
			reportOptions: [],
			selectedoptionsFromReport: '',
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",
			fixedHeaderHeight: ''
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
			const {location} = this.props;
			if(location && location.state){
				this.setState({...location.state});
			}
		} catch (error) {
			//Error Goes Here
		}
		this.fixedPageHeader(); 
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				startDateFromReport: this.props.location.state.startDateFromReport,
				endDateFromReport: this.props.location.state.endDateFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
			});
		}
	}
	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	requestData = async () => {
		let selectedCids = [];
		let selectedModels = [];
		let custGroupID = KCStore.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${LocationInfo.list}`;

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': selectedCids.join(','),
			'brandid': 2,
			'model': selectedModels.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			'basecountry': BASECOUNTRY_ID			
		}

		if (KCStore.getItem("selectedbrandID") === "4" || KCStore.getItem("selectedbrandID") === "3" || KCStore.getItem("selectedbrandID") === "9") {
			headerValue = {
				'Content-Type': 'application/json',
				'cgid': cgids,
				'brandid': KCStore.getItem("selectedbrandID"),
				'cid':KCStore.getItem("customerId"),					
			}
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				let { locations, total_stores_count, } = response.data;
				locations = LocationBuilder(locations);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				locations.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: locations,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll,
					total_stores_count: total_stores_count
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {
		if (KCStore.getItem("brandName") === "CREM") {
			this.props.history.push({
				pathname: `/newReportWithOptions`,
				state: {
					nameFromReport: this.state.nameFromReport,
					startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
					endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
					selectedoptionsFromReport: this.state.selectedoptionsFromReport,
				},
			});
		} else if (KCStore.getItem("brandName") === "FRYMASTER") {
			this.props.history.push({
				pathname: `/fryerReport`,
				state: {
					reportName: this.state.reportName,
					startDate: new Date(this.state.startDate),// "2020-04-09",
					endDate: new Date(this.state.endDate),// "2020-04-09",
					unitChecked: this.state.reportByUnit,
					storeChecked: this.state.reportByStore,
					pdfChecked: (this.state.reportFormat=== "PDF"),
					excelChecked: (this.state.reportFormat=== "XLSX"),
					reportOptions: this.state.reportOptions
				},
			});
		} else if (KCStore.getItem("brandName") === "GARLAND") {
			this.props.history.push({
				pathname: `/garlandReport`,
				state: {
					reportName: this.state.reportName,
					startDate: new Date(this.state.startDate),
					endDate: new Date(this.state.endDate),
					unitChecked: this.state.reportByUnit,
					storeChecked: this.state.reportByStore,
					pdfChecked: (this.state.reportFormat=== "PDF"),
					excelChecked: (this.state.reportFormat=== "XLSX"),
					reportOptions: this.state.reportOptions
				},
			});
		} else {
			this.props.history.push(`/newReport`);
		}
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];
		units.forEach(function (e, index) {
			checkedCopy.push(userSelectAll);
		});

		units && units.length > 0 && units.map(item => {
			item.checked = userSelectAll;
		});

		this.isSelectAssign(checkedCopy);
		let unitsOfflineCount = units.filter(c => c.CONNECT_STATE === "Offline");

		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				isIndeterminateSelect:false,
				unitsSelected: units.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: units
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				isIndeterminateSelect:false,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
			});
		}
	};

	getCheckBoxstate(ListCount,unselectedCount) {
		if (ListCount == unselectedCount) {
		  return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (unselectedCount == 0) {
		  return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
		  return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.checked === false || c.checked === undefined);
		let checked = units.filter(c => c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");

		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			...this.getCheckBoxstate(units.length,checkAll.length)
		});
		
		this.isSelectAssign(checkedCopy);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c === true);
		if (checkAllTrue.length > 1 || checkAllTrue.length === 0) {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
		else {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
	}

	onClickFinish  = async (e) => {
		if(KCStore.getItem("brandName") === "CREM") {
			this.submitForExportReport();
		}
		else if(KCStore.getItem("brandName") === "FRYMASTER") {
			this.submitForFryerReport();
		}
		else if(KCStore.getItem("brandName") === "GARLAND") {
			this.submitForGarlandReport();
		} else {
			this.onFinishClickHandler();
		}
	}

	submitForExportReport = async (e) => {
		this.setState({ loaderIconVisible: true, })
		let selectedUnitList = [];
		let userChecked = this.state.userCheckedData;
		if (userChecked && userChecked.length > 0) {
			userChecked.forEach(function (item, index) {
				selectedUnitList.push(item.UNITID);
			})
		} else if (this.state.userSelectAll) {
			if (this.state.data && this.state.data.length > 0) {
				this.state.data.forEach(function (item, index) {
					selectedUnitList.push(item.UNITID);
				})
			}
        }

		let postdata = {
            'startDate': this.props.location.state.startDateFromReport,
            'endDate': this.props.location.state.endDateFromReport,
            "customerId": KCStore.getItem("customerId"),
            "brandId": KCStore.getItem("selectedbrandID"),
            "filename": this.state.nameFromReport,
            "selectedoptions": this.props.location.state.selectedoptionsFromReport,// ["1","2","3","4","5","6"]
            "units": selectedUnitList,
        };
		await http.post(ReportManagement.submitExportReport, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
					basecountry:KCStore.getItem("basecountry"),
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });

				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: "Report created successfully.",
					},
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    disbleFinishBtn = async () => {
        $("#btnfinish").removeClass("activeState");
        $("#btnfinish").addClass("disabled");
    };
    
    // Frymaster Report Finish Handler
    submitForFryerReport = () => {
        return new Promise((resolve, reject) => {
            this.setState({ loaderIconVisible: true }, () => {
                let userChecked = [];
                const {
                    userSelectAll,
                    data,
                    userCheckedData,
                    startDate,
					endDate,
					reportName,
					reportByUnit,
					reportByStore,
					reportFormat,
					reportOptions
                } = this.state;
                userChecked = userCheckedData;
				const reportOptionObj = {};
				if(reportByStore){
					_.each(reportOptions.filter(el => el.isChecked), obj => { reportOptionObj[obj.fieldName]=obj.isChecked });
				}
                const postdata = {
					startDate, endDate, reportName, reportByUnit, reportByStore, reportFormat,
					brandName: KCStore.getItem("brandName"),
					timeZone: "abc",
					brandId: +KCStore.getItem("selectedbrandID"),
					customerId: +KCStore.getItem("customerId"),
                    custGrpId: userChecked.map(el => el.cust_gp_id),
					...reportOptionObj
				};
                const heders = {
                    headers: {
                        'Content-Type': 'application/json',
                        basecountry: KCStore.getItem("basecountry")
                    }
                };
                return http.post(ReportManagement.exportFryerReport, postdata, heders).then(async (response) => {
                    if (Array.isArray(response.data)) {
                        const urls = response.data;
                        await this.disbleFinishBtn();
                        await multiDownload(urls, { delay: 1000 });
                        await this.delay(1000);
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "Reports created successfully."
                            }
                        });
                        resolve(response.data);
                    } else {
                        resolve(response.data);
                        this.props.history.push(`/reportList`);
                    }
                }).catch(err => {
                    this.setState({ loaderIconVisible: false });
                    reject(err);
                });
            });
        });
    };

	// Garland Report Finish Handler
    submitForGarlandReport = () => {
        return new Promise((resolve, reject) => {
            this.setState({ loaderIconVisible: true }, () => {
                let userChecked = [];
                const {
                    userSelectAll,
                    data,
                    userCheckedData,
                    startDate,
					endDate,
					reportName,
					reportByUnit,
					reportByStore,
					reportFormat,
					reportOptions
                } = this.state;
                userChecked = userCheckedData;
				const reportOptionObj = {};
				if(reportByStore){
					_.each(reportOptions.filter(el => el.isChecked), obj => { reportOptionObj[obj.fieldName]=obj.isChecked });
				}
                const postdata = {
					startDate, endDate, reportName, reportByUnit, reportByStore, reportFormat,
					brandName: KCStore.getItem("brandName"),
					timeZone: "abc",
					brandId: +KCStore.getItem("selectedbrandID"),
					customerId: +KCStore.getItem("customerId"),
                    custGrpId: userChecked.map(el => el.cust_gp_id),
					...reportOptionObj
				};
                const heders = {
                    headers: {
                        'Content-Type': 'application/json',
                        basecountry: KCStore.getItem("basecountry")
                    }
                };
                return http.post(ReportManagement.exportGarlandReport, postdata, heders).then(async (response) => {
                    if (Array.isArray(response.data)) {
                        const urls = response.data;
                        await this.disbleFinishBtn();
                        await multiDownload(urls, { delay: 1000 });
                        await this.delay(1000);
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "Reports created successfully."
                            }
                        });
                        resolve(response.data);
                    } else {
                        resolve(response.data);
                        this.props.history.push(`/reportList`);
                    }
                }).catch(err => {
                    this.setState({ loaderIconVisible: false });
                    reject(err);
                });
            });
        });
	};
	
	onFinishClickHandler() {
		this.setState({ loaderIconVisible: true })
		const { data } = this.state;
		const unitList = [];
		const unitData = [];
		if(data && data.length > 0){
			data.map(item => {
				if(item.checked){
					unitList.push(item.CLOUD_UNIT_NAME);
					unitData.push(
						{
							"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
							"PRODUCT_NAME": item.PRODUCT_NAME,
							"UNITID": item.UNITID,
							"PRODUCT_NUMBER": item.PRODUCT_NUMBER,
							"dynastyAttributes": item.dynastyAttributes,
						});
				}
			});

		}

		//modelNumber
		let startdateofReport = this.props.location.selectedstartDate;
		startdateofReport = new Date(startdateofReport);
		startdateofReport = format(startdateofReport, 'YYYY-MM-DD');

		let enddateofReport = this.props.location.selectedendDate;
		enddateofReport = new Date(enddateofReport).toDateString();
		enddateofReport = format(enddateofReport, 'YYYY-MM-DD');

		let token = KCStore.getItem('Access-Token');
		let ErrorReportPostData = {
			startDate: startdateofReport,
			endDate: enddateofReport,
			timeZone: "abc",
			unitIds: unitList,
			unitData: unitData
		};

		http({
			method: "POST",
			url: `${BaseService.root}${ReportManagement.deviceDataApi}`,
			data: ErrorReportPostData,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			}
		}).then(response => {
			if (response)
				this.setState({
					loaderIconVisible: false,
					errorResponse: response.data.fileUrl
				});
			$("#exportReport").addClass("export-error-report");
			window.location = response.data.fileUrl;
		}).catch(err => {
			this.showError(err);
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/reportList`);
	}

	fixedPageHeader = () => {
		let { fixedHeaderHeight } = this.state;
	    let windowHeight = $(window).height();
	    let selectedUnitHeader = $(".selectedUnitHeader").height(); 
		let adminBgHeader = $(".adminBgHeader").height(); 
		let reportAsgnListH = $(".reportAsgnList .tHead").height(); 
		let reportAsgnListSrlBarH = ((windowHeight) - (adminBgHeader + selectedUnitHeader + reportAsgnListH)) - 70;
	    this.setState({ fixedHeaderHeight : reportAsgnListSrlBarH}, () => ({ reportAsgnListSrlBarH})); 
   }

	render() {
		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal, fixedHeaderHeight } = this.state;
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [
			{ path :"", label:"#"},
			{ path :"", label:""},
			{ path:"location_name", label:"Location"},
			{ path:"country", label:"Country"},
			{ path:"city", label:"City"},
			{ path:"street", label:"Street"},
			{ path:"total_Units", label:"Number Of Units"},
			{ path:"", label:"Status", isNbSp:true},
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		let showHeader;
		const brandsName = KCStore.getItem("brandName");
		if (brandsName === "CREM") {
			showHeader = (
				<ul>
					<li>{this.state.unitsSelected} Units selected / {this.state.unitsOffline} Units offline</li>
					<li>
						<Link to="/assignReportUnitToTopology" className="btn assignunitSite">&nbsp;</Link>
						<a id="btnAssign" href="#" className="assignunitList active" >&nbsp;</a>
					</li>
				</ul>
			);
		}
		else if (brandsName === "FRYMASTER") {
			showHeader = (
				<ul>
					<li>{this.state.unitsSelected} Locations selected / {this.state.unitsOffline} Locations offline</li>
					<li>
						<Link to="/reportAssignStoreList" className="btn btn-default-text assignunitList active">&nbsp;</Link>
					</li>
				</ul>
			);
		} else {
			showHeader = (
				<ul>
					<li>{this.state.unitsSelected} Locations selected / {this.state.unitsOffline} Locations offline</li>
					<li>
						<Link to="/reportAssignUpdateList" className="btn btn-default-text assignunitList active">&nbsp;</Link>
					</li>
				</ul>
			);
		}

		const dynamicHeaderReport = `Reports - ${_.capitalize(brandsName)}`;

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">

						<div className="reportWrapper">
							<div className="adminBgHeader">
								<ul>
									<li>
										<a href="javascript:void(0)" onClick={() => this.onBackClickHandler()} title="Back" className="backIcon">&nbsp;</a>
									</li>
									<li className="txtboldmsg">
										Select Locations
				            	</li>
									<li>
										<a id="btnfinish" href="javascript:void(0)" title="Finish" className="disabled" onClick={() => this.onClickFinish()}>Finish</a>
									</li>
								</ul>
							</div>
							<div className="selectedUnitHeader">
								{showHeader}
							</div>
							<form className="reportAsgnListOuter">
                            <div className="reportAsgnList">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
							</div>	
							<div className="reportAsgnListSrlBar" style={{ height: `${fixedHeaderHeight}px` }}>  
                                <div className="reportAsgnList">
									{
										unitList.map((item, i) => (
											<ul className={item.CONNECT_STATE === "Offline" ? "tBody disabled" : "tBody"} key={i}>
												<li>{i + 1}</li>
												<li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
												<li>{item.location_name}</li>
												<li>{item.country}</li>
												<li>{item.city}</li>
												<li>{item.street}</li>
												<li>{item.total_Units}</li>
												<li>{item.CriticalOffline + item.NonCriticalOffline + item.NoErrorOffline === item.total_Units ?
													 <img src = { OfflineMediaImg} /> : <span className='number'>{}</span>}
												</li>
												{<li>
													<div className="customCheckbox customCheckboxWOlabel">
														<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
															checked={item.checked}
															onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
														<label for={item.order_id - 1}></label>
													</div>
												</li>}
											</ul>
										))
									}
								</div>
								</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName={dynamicHeaderReport} activeClass="reportNav" />
				</div>
				<div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabIndex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="confirmationContentDiv">
									<h5>Software Update</h5>
									<p>{Updatedunits} / {unitsSelected} units successfully updating. {unitsOffline} units pending (offline)</p>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button">OK</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

export default ReportAssignStoreList;