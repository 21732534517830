import http from '../../service/httpService';
import React from 'react';
import { unitAPI } from '../../service/api';
import { userRoleURI } from '../../service/api';
import authService from '../../service/authService';
import { setUserDataModel } from '../../actions/stateActionsData/userAction';
import { FeaturesList } from '../../components/controls/featureList';
import { BrandList } from '../../components/controls/brandList';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../components/layoutSection/userManagementHeader';
import { BRAND_ID } from '../../utils/appConstants';
import authConstants from '../../utils/authConstants';
import _ from 'lodash';
import $ from 'jquery';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import KCStore from "../../utils/kcStorage";

const { allFeaturesKey } = authConstants;
const FeaturesCategory = {
	GF: { title: "Global Functions", stringID: "KC0622" },
	FP: { title: "Unit Data", stringID: "KC0625" },
	MMA: { title: "Main Menu Apps", stringID: "KC0587" }
}

const SelectRole = (props) => {

	const [roleId, setRoleId] = React.useState(0);
	let [corborateRolesDropDownSource, setCoroporateRoleDropdown] = React.useState([]);
	const [allDefaultBrandsList, setAllDefaultDropdown] = React.useState([]);
	const [roleFeaturesList, setRoleFeatureList] = React.useState([]);
	const [allFeaturesData, setAllFeatureList] = React.useState([]);
	const [loaderIconVisible, setLoading] = React.useState(false);
	const [viewBrandsList, setViewBrandList] = React.useState([]);

	const FeaturesCategoryList = [FeaturesCategory.GF, FeaturesCategory.FP, FeaturesCategory.MMA];
	const dispatch = useDispatch();
	const userModel = useSelector(state => state.userReducer.UserModel)
	const locale = useSelector(state => state.localeInfo.locale)

	React.useEffect(() => {
		document.body.style.backgroundColor = "#F2F2F2";
		if (userModel.roleId) {
			setRolesAndBrand(userModel.roleId);
		}
		else {
			setDefaultBrandsAndFeatures()
		}
	}, [])

	React.useEffect(() => {
		document.body.style.backgroundColor = "#F2F2F2";
		fetchData();
		async function fetchData() {
			await setDefaultBrandsAndFeatures()
		}
		setRolesAndBrand(userModel.roleId);
	}, [locale])

	const UserRole = (e) => {
		props.history.push('/assignUserToTopology');
	}

	const selectBrand = (e) => {
		if (e.target.value) {
			setRoleId(e.target.value);
			userModel.roleId = e.target.value;
			setRolesAndBrand(e.target.value);
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		} else {
			setDefaultBrandsAndFeatures()
		}
	}

	const setRolesAndBrand = (roleId) => {
		loadingPopupShowHide(true);
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loginFeatureInfo = JSON.parse(KCStore.getItem(allFeaturesKey));
		let loggedinUser = loginUser.username;
		let loggedinUserRoleId = loginFeatureInfo[0].role_id;
		let rolesBrandsFeatures = allDefaultBrandsList.length > 0 ? [
			getData(userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + KCStore.getItem("customerId"), { LANGCODE: true }),
			getData(userRoleURI.getRolesBrand + roleId),
			getData(userRoleURI.getRolesByCustomer + KCStore.getItem("customerId") + "&user_email=" + loggedinUser + "&loginUserRoleid=" + loggedinUserRoleId + "&brandIds=" + BRAND_ID),
			getData(userRoleURI.getAllFeatures, { BRAND_ID })] : [getData(userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + KCStore.getItem("customerId"), { LANGCODE: true }),
			getData(userRoleURI.getRolesBrand + roleId),
			getData(userRoleURI.getRolesByCustomer + KCStore.getItem("customerId") + "&user_email=" + loggedinUser + "&loginUserRoleid=" + loggedinUserRoleId + "&brandIds=" + BRAND_ID),
			getData(userRoleURI.getAllFeatures, { BRAND_ID }),
			getData(unitAPI.getBrands)]

		Promise.all(rolesBrandsFeatures)
			.then(responses => {
				let featuresData = responses && responses.length >= 1 ? responses[0].data : [];
				let userBrands = responses && responses.length >= 2 ? responses[1].data.data : [];
				let featureList = featuresData && featuresData.data && featuresData.data.features.length > 0 ? featuresData.data.features.filter((c) => c.feature_name != "Ready Recipe") : [];
				let rolesList = responses.length >= 3 ? responses[2].data.data : [...corborateRolesDropDownSource];
				const brands = responses.length >= 5 ? responses[4].data : [...allDefaultBrandsList];
				const allDefaultFeatures = responses.length >= 4 ? responses[3].data.data.filter((c) => c.feature_name != "Ready Recipe") : [...allFeaturesData.filter((c) => c.feature_name != "Ready Recipe")];
				let selctedRole = rolesList.filter(item => item.Id === parseInt(roleId));
				if (selctedRole && selctedRole.length > 0)
					selctedRole[0].isSelected = true;
				setRoleId(roleId)
				setAllFeatureList(allDefaultFeatures);
				setViewBrandList(getBrandsByUserRoleSelection(getLoginUserBrands(brands), userBrands))
				setRoleFeatureList(featureList)
				setAllDefaultDropdown(brands)
				setCoroporateRoleDropdown(rolesList);
				loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				loadingPopupShowHide(false);
			});
	}

	const setDefaultBrandsAndFeatures = () => {
		loadingPopupShowHide(true);
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loginFeatureInfo = JSON.parse(KCStore.getItem(allFeaturesKey));
		let loggedinUser = loginUser.username;
		let loggedinUserRoleId = loginFeatureInfo[0].role_id;
		return Promise.all([getData(userRoleURI.getAllFeatures, { BRAND_ID }),
		getData(unitAPI.getBrands),
		getData(userRoleURI.getRolesByCustomer + KCStore.getItem("customerId") + "&user_email=" + loggedinUser + "&loginUserRoleid=" + loggedinUserRoleId + "&brandIds=" + BRAND_ID)])
			.then(responses => {

				let featuresData = responses[0].data.data.filter((c => c.feature_name != "Ready Recipe"));
				let brands = responses[1].data;
				let rolesList = responses && responses.length >= 2 ? responses[2].data.data : [];
				featuresData.forEach(f => {
					f.feature_active = false;
				});
				setRoleId(0)
				setAllFeatureList(featuresData);
				setViewBrandList(getLoginUserBrands(brands))
				setRoleFeatureList([])
				setAllDefaultDropdown(brands)
				setCoroporateRoleDropdown(rolesList);
				loadingPopupShowHide(false);
			})
			.catch(err => {
				//Error
				loadingPopupShowHide(false);
			});
	}

	const getData = (url, args = {}) => {
		let headers = {};
		const { roleId, BRAND_ID, LANGCODE } = args;
		headers["Content-Type"] = 'application/json';
		if (roleId) {
			headers["role_id"] = roleId;
		} else if (BRAND_ID) {
			headers["brandid"] = BRAND_ID;
			headers["langcode"] = locale ? locale : 'en-US';
		} else if (LANGCODE) {
			headers["langcode"] = locale ? locale : 'en-US';
		}
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: headers,
				data: {}
			}).then(response => resolve(responseSuccessParser(response))).catch(err => {
				loadingPopupShowHide(false);
			});
		});

	}

	const responseSuccessParser = (response) => {
		return new Promise((resolve, reject) => {
			const { data } = response;
			if (data) {
				resolve(response);
			} else {
				let { data } = response;
				if (data) {
					let { body } = data;
					if (body != null)
						reject(body);
				}
			}
		});
	}

	const responseErrorParser = (err) => {
		const { response } = err;
		let { data } = response;
		if (data) {
			let { body } = data;
			if (body != null)
				return body;
		}
	}

	const setUserToState = (e) => {
		userModel.roleId = roleId;
		dispatch(setUserDataModel(userModel));
	}

	const setUserToRole = (e) => {
		if (roleId === 0) {
			e.preventDefault();
		}
	}

	const loadingPopupShowHide = (isActive) => {
		setLoading(isActive)
	}

	/**
   * Get Default brands which was subscription by customer
   */
	const getLoginUserBrands = (brands) => {

		let loginUserBrands = authService.getLoginUserBrands();
		let viewBrandsList = [], allBrandsList = [...brands];
		loginUserBrands.forEach(bId => {
			let selectedBrans = allBrandsList.filter(item => item.ID === bId);
			if (selectedBrans && selectedBrans.length > 0) {
				let brand = { ...selectedBrans[0] };
				brand.isChecked = false;
				viewBrandsList.push(brand);
			}
		});

		return viewBrandsList;
	}

	/**
	 * Get Brands which was chosen by selected role
	 */
	const getBrandsByUserRoleSelection = (viewBrandsList, selectedBrands) => {

		for (let index = 0, len = selectedBrands.length; index < len; index++) {
			let selectedBrans = viewBrandsList.filter(item => item.ID === selectedBrands[index]);
			if (selectedBrans && selectedBrans.length > 0) {
				selectedBrans[0].isChecked = true;
			}
		}

		return viewBrandsList;
	}



	corborateRolesDropDownSource = _.orderBy(corborateRolesDropDownSource, [corborateRolesDropDownSource => corborateRolesDropDownSource.role_name && corborateRolesDropDownSource.role_name.toString().toLowerCase()]);
	const { formatMessage } = props.intl;

	return (

		<div id="adminWrapper">
			<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
				<div className="loadingProgressIn"></div>
			</div>
			<div id="page-content-wrapper">
				<div className="userManagementWrapper" >
					<div className="addUserHeader">
						<ul>
							<li>
								<Link to={{
									pathname: userModel.previousPage,
									data: props.location.data
								}} title={formatMessage({ id: 'KC0989' })} onClick={(e) => setUserToState(e)} className="backIcon">&nbsp;</Link>
							</li>
							<li>
								<FormattedMessage id={'KC0603'} />
							</li>
							<li className={roleId ? "activeState" : "disabled"}>
								<Link className={roleId ? "btn btn-default-text activeState" : ""} id="btnnext" to={{
									pathname: "/assignUserToTopology",
									data: userModel.roleId
								}} title={formatMessage({ id: "KC0543" })} onClick={(e) => setUserToRole(e)}><FormattedMessage id={'KC0543'} /></Link>

							</li>
						</ul>
					</div>

					<div className="chooseUserFormWrapper" style={{ background: "#fff" }}>

						<div className="chooseUserForm">
							<ul>
								<li>
									<div className="userNameDP">
										<span className="userDPImg"><img src={require("../../images/icons/neutral.svg").default} alt="" /></span>
										<span className="userDText">{userModel.user.firstName + ' ' + userModel.user.lastName}</span>
									</div>
								</li>
								<li className="marginBottom10px">
									<div className="input-group">
										<select className="form-control" onChange={(e) => selectBrand(e)}>
											<option value=""> {formatMessage({ id: "KC0621" })}</option>
											{corborateRolesDropDownSource.map((item, i) =>
												<option key={i} value={item.Id} selected={item.isSelected}>{item.role_name}</option>
											)}
										</select>
									</div>
								</li>

							</ul>
						</div>
						<BrandList
							brandList={viewBrandsList}
							isBrandEditable={false} />
						{FeaturesCategoryList.map((item, i) =>
							<FeaturesList key={i}
								isRoleFeatureUpdated={true}
								isRoleEditable={false}
								featureTitle={item.title}
								featureTitleStringID={item.stringID}
								roleFeaturesList={roleFeaturesList}
								allFeaturesData={allFeaturesData} />
						)}
					</div>
				</div>
			</div>
			<UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
		</div>
	)

};


export default injectIntl(SelectRole);

