import http from "../../service/httpService";
import { setcustomerId } from "../../utils/appConstants";
import { setbasecountryId } from "../../utils/appConstants";
import { setaccessCountryId } from "../../utils/appConstants";
import { DeviceRegistration } from "../../service/api";
import csvExUnitRedirect from "../../utils/csvExUnitRedirect";
import WoopraEvents from "../../woopraConfig/WoopraEvents";
import Constants from "../../woopraConfig/Woopraconstant";
import auth from "../../service/authService";

import KCStore from "../../utils/kcStorage";

export const loginAPIUtils = (inputData, push, settingLocale) => {
  return new Promise(async (resolve, _) => {
    auth.setLocalStorage(inputData.loginData, inputData.rememberme);
    KCStore.setItem("isCookieAccepted", true);
    KCStore.setItem("Access-Token", inputData.loginData.accessToken);
    KCStore.setItem("isEAAS", false);

    await loginResponseAction(
      inputData.username,
      inputData.res,
      push,
      settingLocale
    );

    // 
    // TODO: Needed to resolve during hardening! Possible mocked data!
		//

    // http
    //   .get(DeviceRegistration.eaasThankyou, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       username: inputData.username,
    //       role_id: inputData.loginData.role,
    //       customer_id: inputData.loginData.customerId,
    //     },
    //     data: {},
    //   })
    //   .then(async (response) => {
    //     let { data } = response;
    //     //user is eaas customer and not subscribed
    //     if (data && data.eaas_flag && !data.isSubscribed) {
    //       // if (inputData.from === "createPassword") {
    //       //     this.setState({ goToConsumer: true })// to do
    //       // }

    //       if (KCStore.getItem("isCookieAccepted")) {
    //         KCStore.clear();
    //         KCStore.setItem("isCookieAccepted", true);
    //       } else {
    //         KCStore.clear();
    //       }

    //       KCStore.setItem("isEAAS", data.eaas_flag);
    //       KCStore.setItem("Access-Token", inputData.loginData.accessToken);
    //       KCStore.setItem("consumerData", JSON.stringify(data));
    //       resolve(push("/consumer"));
    //     } else if (
    //       data &&
    //       data.eaas_flag &&
    //       data.isSubscribed &&
    //       data.showUnits
    //     ) {
    //       if (KCStore.getItem("isCookieAccepted")) {
    //         KCStore.clear();
    //         KCStore.setItem("isCookieAccepted", true);
    //       } else {
    //         KCStore.clear();
    //       }
    //       KCStore.setItem("consumerData", JSON.stringify(data));
    //       KCStore.setItem("isEAAS", data.eaas_flag);
    //       KCStore.setItem("Access-Token", inputData.loginData.accessToken);
    //       const loginResponse = {
    //         username: inputData.username,
    //         loginData: inputData.loginData,
    //       };
    //       KCStore.setItem(
    //         "loginConsumserResponse",
    //         JSON.stringify(loginResponse)
    //       );
    //       resolve(push("/list-units"));
    //     } else if (
    //       data &&
    //       data.eaas_flag &&
    //       data.isSubscribed &&
    //       !data.showUnits
    //     ) {
    //       KCStore.setItem("isEAAS", data.eaas_flag);
    //       await loginResponseAction(
    //         inputData.username,
    //         inputData.res,
    //         push,
    //         settingLocale
    //       );
    //     } else {
    //       KCStore.setItem("isEAAS", false);
    //       await loginResponseAction(
    //         inputData.username,
    //         inputData.res,
    //         push,
    //         settingLocale
    //       );
    //     }
    //   })
    //   .catch(async (err) => {
    //     // this catch block contains normal login process because the above http api is created to check the customer is eaas user and its newly created api, for better handle puprpose this code is written. Once stable api in all ENV, need to update with appropiate error.
    //     await loginResponseAction(
    //       inputData.username,
    //       inputData.res,
    //       push,
    //       settingLocale
    //     );
    //   });
  });
};

export const loginResponseAction = async (
  username,
  res,
  push,
  settingLocale,
  input
) => {
  if (input?.from === "selfSingOn") {
    await auth.setLocalStorage(input.loginData, input.rememberme);
  }
  KCStore.setItem("customerId", res.data.customerId);
  KCStore.setItem("basecountry", res.data.basecountry);
  KCStore.setItem("accesscountry", res.data.accesscountry);
  KCStore.setItem("roleId", res.data.role);
  KCStore.setItem(
    "timeFormat",
    res.data.userTimeNumberPreferences.length > 0 &&
      res.data.userTimeNumberPreferences[0].preference_value
      ? parseInt(res.data.userTimeNumberPreferences[0].preference_value)
      : parseInt("12")
  );
  KCStore.setItem(
    "thousandSeparator",
    res.data.userTimeNumberPreferences.length > 1 &&
      res.data.userTimeNumberPreferences[1].preference_value
      ? res.data.userTimeNumberPreferences[1].preference_value
      : ","
  );
  setcustomerId(res.data.customerId ? res.data.customerId : 1);
  setbasecountryId(res.data.basecountry ? res.data.basecountry : "");
  setaccessCountryId(res.data.accesscountry ? res.data.accesscountry : "");
  KCStore.setItem("user", username);
  // getUserRole(res.data.customerId, username);
  if (input?.from !== "selfSingOn") {
    settingLocale(res.data.language_preference.language_code);
  }
  navigateNext(push, input);
  // this.setState({
  //     success_modal: {
  //         open: false,
  //         message: `You have successfully logged in "${username}".` //MLS:TODO
  //     },
  // });
};
const navigateNext = (push, input) => {
  let accessFeatures = JSON.parse(KCStore.getItem("allFeatures"));
  if (accessFeatures[0].feature_category === "Main Menu Apps") {
    push("/mainmenu");
  } else {
    /** starts:pwp for exporbar csv redirect*/
    const unitBrandId = KCStore.getItem("csvUnitBrandId");
    const unitId = KCStore.getItem("csvUnitID");
    if (unitId !== null && unitBrandId !== null) {
      // csvExUnitRedirect(unitId, unitBrandId, props.history.push)
      csvExUnitRedirect(unitId, unitBrandId, push);
    } else {
      if (input?.from !== "selfSingOn") {
        redirect(push);
      }
    }
    /**ends:pwp */
  }
};

const redirect = (push) => {
  if (KCStore.getItem("kcbrandsitedata")) {
    let objbrandsitedata = JSON.parse(KCStore.getItem("kcbrandsitedata"));
    if (objbrandsitedata.readyrecipeids) {
      if (objbrandsitedata.brand.toUpperCase() === "MERRYCHEF") {
        return push("/assignLegacyRecipeToTopology");
      } else if (objbrandsitedata.brand.toUpperCase() === "CONVOTHERM") {
        return push("/assignLegacyCookbookToTopology");
      }
    }
  } else {
    var urlParams = new URLSearchParams(window.location.search);
    const redirectParam = urlParams.get("redirectUri");
    if (redirectParam === "payment") {
      push("/subscription");
    } else {
      push("/dashboardLocations");
    }
  }
};

// TODO: To Discuss with Pawan for Woopra
// Woopra Initial Call
const woopraIndentify = (username, roleName, id) => {
  window.woopra.identify({
    email: username,
    customerId: id,
    rolename: roleName,
    childUser: KCStore.getItem("childUsersEmail") || [],
    baseCountry: KCStore.getItem("basecountry"),
  });
  window.woopra.identify("email", username).push();
  WoopraEvents(`${Constants.USER_LOGIN}`);
};
