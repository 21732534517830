import axios from 'axios';
import http from '../../../service/httpService';
import { CUSTOMER_ID, BRAND_ID,BASECOUNTRY_ID, } from '../../../utils/appConstants';
import { BaseService, MediaManagementAPI } from '../../../service/api';
import KCStore from '../../../utils/kcStorage';

export const MEDIA_LIBRARY_LIST_REQUEST = 'MEDIA_LIBRARY_LIST_REQUEST';
export const MEDIA_LIBRARY_LIST_SUCCESS = 'MEDIA_LIBRARY_LIST_SUCCESS';
export const MEDIA_LIBRARY_LIST_FAIL = 'MEDIA_LIBRARY_LIST_FAIL';

export function getMediaLibraryList(req) {
    return dispatch => {
        dispatch({
            type: MEDIA_LIBRARY_LIST_REQUEST
        })
        let custGroupID = KCStore.getItem("custGroupID");
		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
        let token = KCStore.getItem("Access-Token");
        axios.get(BaseService.root + MediaManagementAPI.getMediaLibraryAPI,

            {
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token}`,
                    customerId: CUSTOMER_ID,
                    brandId: BRAND_ID,   
                    "cgids": cgids,    
                    'basecountry': BASECOUNTRY_ID       
                },
                data:{}

            })        
        .then(response => {
            console.log('MEDIALIBRARYLIST_VIEWINFO' ,response.data);
            dispatch({
                type: MEDIA_LIBRARY_LIST_SUCCESS,
                mediaLibraryListInformation: response.data,
             })
        })            
        .catch(error => {
                dispatch({
                    type: MEDIA_LIBRARY_LIST_FAIL,
                    error
                })
            })
    }
}


