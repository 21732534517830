import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from '../../components/Loader';
import UserManagementHeader from '../../components/layoutSection/userManagementHeader';
import { UserManagement, UserMgmtAddUser } from '../../service/api';
import http from '../../service/httpService';
import AddUserForm from '../../Features/userMgmt/create';
import KCLink from "../../UI_Components/KCLink";
import { setUserDataModel } from '../../actions/stateActionsData/userAction';
import { LanguagePreference } from '../../service/api';

import KCStore from "../../utils/kcStorage";

const EditUserPage = (props) => {
    const [loader, setLoading] = React.useState(false);
    const initialValues = React.useRef({});
    const [countryCode, setCountryCode] = React.useState([]);
    const { formatMessage } = props.intl;
    const roleId = props.location.role_id;
    const customeremail = props.location.email;
    const [languageList, setLanguageList] = React.useState([]);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        init();
    }, [])

    const init = async () => {
        let customerid = KCStore.getItem("customerId");
        setLoading(true);
        await getCountryCodeList();
        const language = await getLanguageList();        
        const response = await http({
            method: "GET",
            url: UserManagement.geteditUserDetails,
            headers: {
                'customerid': customerid,
                'email': customeremail,
                'Content-Type': 'application/json',
                'basecountry': KCStore.getItem("basecountry"),
                'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
            },
            data: {}
        });
        const resdata = response.data;
        let fields = {
            firstName: resdata && resdata.first_name && resdata.first_name !== "" ? resdata.first_name : resdata.name,
            lastName: resdata && resdata.last_name ? resdata.last_name : "",
            email: customeremail,
            retypeEmail: customeremail,
            countryCode: resdata.country_code,
            phoneNumber: resdata.phone_number,
            languageList : resdata?.language_preference?.Id ? resdata.language_preference.Id : language[0].Id,
        }
        initialValues.current = fields;
        setLoading(false)
    }

    const getLanguageList = async () => {        
        const url = LanguagePreference.getLanguages;
        let result = await http.get(url, {
            headers: { 'Content-Type': 'application/json' },
            data: {}
        });
        let { data } = result.data;
        setLanguageList(data);
        return data;        
    }

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            email: Yup.string()
                .required(formatMessage({ id: 'KC1675' }))
                .email('Email is invalid'),
            retypeEmail: Yup.string()
                .required(formatMessage({ id: 'KC1675' }))
                .oneOf([Yup.ref('email'), null], 'Retype email does not match'),
        })
    }

    const setUserToState = (values) => {
        let userModel = {};
        userModel.user = values;
        userModel.previousPage = "/user/edit";
        userModel.roleId = roleId;
        userModel.assignUserBackPage = "/user/select-role";
        let newList = countryCode;
        newList.forEach(item => {
            if (item.code === values["countryCode"]) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        userModel.user.country_code = values["countryCode"];
        userModel.user.country_code = userModel.user.country_code ? userModel.user.country_code : newList.length > 0 && newList[0].code
        userModel.countryCodeList = newList;
        dispatch(setUserDataModel(userModel));
    }

    const dispatch = useDispatch();


    const getCountryCodeList = async () => {
        let result = await http.get(UserMgmtAddUser.getCountryCodeApi, {
            headers: { 'Content-Type': 'application/json' },
            data: {}
        });
        let { data } = result.data;
        setCountryCode(data);
    }
    return (
        <>
            <Loader loading={loader} />
            <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
            <div id="adminWrapper">
                <div id="page-content-wrapper">
                    {!loader ? <Formik
                        initialValues={initialValues.current}
                        validationSchema={validationSchema}
                        isInitialValid={true}
                    >
                        {(formik) => (
                            <div className="userManagementWrapper">
                                <div className="addUserHeader">
                                    <ul>
                                        <li>
                                            <KCLink to="/user/list" title={formatMessage({ id: 'KC0989' })} className="backIcon"></KCLink>
                                        </li>
                                        <li>
                                            <FormattedMessage id="KC0583" />
                                        </li>
                                        <li>
                                            <KCLink id="btnnext" className={`btn-default-text ${formik.isValid ? '' : 'disabled'}`} to={{
                                                pathname: "/user/select-role",
                                                data: formik.values
                                            }} title={formatMessage({ id: "KC0543" })}
                                                onClick={(e) => setUserToState(formik.values)}
                                            ><FormattedMessage id={'KC0543'} /></KCLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="addUserFormWrapper">
                                    <div className="addUserForm">
                                        <AddUserForm countryCodeList={countryCode} formik={formik} formDisabled={true} languageList = {languageList}/>
                                        <div className="requiredNoteDiv"><FormattedMessage id={'KC0654'} /></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Formik> : null}
                </div>
            </div>

        </>
    )
}


export default injectIntl(EditUserPage);