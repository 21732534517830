import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { UserProfile } from '../../../service/api';
import qs from 'query-string';
import jwt_decode from 'jwt-decode';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { format } from 'date-fns';
import KCStore from '../../../utils/kcStorage';

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate() - 1));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
let custSelctedId;
let savestartDate = sixMonthsFromNow;
let saveendDate = Yesterdaydate;
$(function () {
	$('#startdate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
	$('#enddate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
});

$(window).on('beforeunload', function () {
	if ($(".fleet-export-error-report").length > 0) {
		$("#fleetExportReport").removeClass("fleet-export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

const reportOptions = [{
    fieldName: "grill_info",
	displayName: "Grill Info",
	isChecked: true,
	disabled: true
},
{
	fieldName: "cooking_info",
	displayName: "Cooking Information",
	isChecked: true,
	disabled: false
},
{
    fieldName: "error_statistics",
    displayName: "Error Statistics",
	isChecked: true,
	disabled: false
},
{
    fieldName: "clean_info",
    displayName: "Cleaning Information",
	isChecked: true,
	disabled: false
},
{
    fieldName: "sleep_info",
    displayName: "Sleeping Information",
	isChecked: true,
	disabled: false
},
{
    fieldName: "quick_error",
    displayName: "Quick Error Guide",
	isChecked: true,
	disabled: false
}];

class garlandReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			ErrorReport: false,
			FleetReport: false,
			startDate: sixMonthsFromNow,
			endDate: Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			custid: 0,
			userData: {},
			reportName: "",
			selChkInArr: "",
			unitChecked: true,
			storeChecked: false,
			pdfChecked: false,
			excelChecked: true,
			reportOptions: [...reportOptions],
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.getUserProfileInformation = this.getUserProfileInformation.bind(this);
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		this.getUserProfileInformation();
		let second_date = new Date(todaysDate);
		second_date.setMonth(second_date.getMonth() + 6)
		this.setState({
			maxdate: second_date,
			loaderIconVisible: true
		});
	}
	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			let values = this.props.location.state
			this.setState({ ...values });
		}
	}
	
	getUserProfileInformation = () => {
		let token = KCStore.getItem("Access-Token");
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		var decrypt = jwt_decode(token)
		let customeremail = decrypt.email;
		customeremail = loggedinUser;
		this.setState({ emailfromTokan: loggedinUser, loaderIconVisible: true });
		const values = qs.parse(this.props.location.search);
		if (values.user !== undefined) {
			customeremail = values.user;
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			customeremail = setEmailValue;
			this.setState({ queryString: values.user });
		}

		http.get(UserProfile.getUserProfileDetails,
			{
				headers: {
					'customerid': KCStore.getItem("customerId"),
					'email': customeremail,
					'Content-Type': 'application/json',
					'basecountry': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
				}, data: {},
			}).then(response => {
				this.setState({
					loaderIconVisible: false
				})
				if (response.data.role_name === "Brand Admin") {
					if (KCStore.getItem("brandId").indexOf(2) > -1) {
						$("#ErrorReportdiv").removeClass("displaynone");
						$("#FleetReportdiv").removeClass("displaynone");
					}
					else if (KCStore.getItem("brandId").indexOf(4) > -1) {
						$("#FleetReportdiv").removeClass("displaynone");
					}
				}
				else {
					if (KCStore.getItem("brandId").indexOf(2) > -1) {
						$("#ErrorReportdiv").removeClass("displaynone");
					}
				}
			}).catch(err => {
				console.log('catch exception occured');
				this.setState({ loaderIconVisible: false });
			});
	}

	onChangecust = (e) => {
		let idx = e.target.selectedIndex;
		let custid = e.target.options[idx].text;
		custSelctedId = Number(e.target.value);

		this.setState({ custid: custid });
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
		});
		if (savestartDate > saveendDate) {
			this.setState({
				error_modal: {
					open: true,
					message: "Start date should be lesser than End date."
				}
			});
		}
	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date
		});
		if (savestartDate > saveendDate) {
			this.setState({
				error_modal: {
					open: true,
					message: "End date should be greater than Start date."
				}
			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	
	onSelectCheckbox = (e) => {
		const { reportOptions } = this.state;
		const reportIndex = reportOptions.findIndex(el => el.fieldName == e.target.name);
		const copyReportOption = [...reportOptions];
		copyReportOption[reportIndex] = { ...reportOptions[reportIndex] };
		copyReportOption[reportIndex].isChecked = e.target.checked;
		this.setState({ reportOptions: copyReportOption });
	}

	onSelectRadioCheckbox = (e) => {
		const obj = {unitChecked:false, storeChecked:false};
		if(e.target.value === "unit"){
			obj.unitChecked = true;
		}
		if(e.target.value === "store"){
			obj.storeChecked = true;
		}
		this.setState({ ...obj });
	}

	NameChange = (e, item) => {
		let rName = e.target.value;
		this.setState({
			reportName: rName,
		})
	}


	render() {
		let { success_modal, error_modal, loaderIconVisible, reportName, startDate, endDate, unitChecked, storeChecked, reportOptions } = this.state;
		let isNextEnabled = false
		if ( reportName && startDate != undefined && endDate != undefined) {
			if ((unitChecked == true || storeChecked == true)) {
				isNextEnabled = true
			}
		}

		const displayReportOptions = reportOptions && reportOptions.map(item => ( <ul>
			<li>
				<div className={item.disabled?"customCheckbox customCheckboxWOlabel disabled":"customCheckbox customCheckboxWOlabel"}>
					<input onChange={(e) => this.onSelectCheckbox(e)} checked={item.isChecked} id={item.fieldName} name={item.fieldName} type="checkbox" value={item.isChecked}/>
					<label for={item.fieldName}>{item.displayName}</label>
				</div>
			</li>
		</ul>));
		let showOptions;
		let showLeftSideDetails;
		showOptions = (
			<div class="reportTypeListCBox">
				<ul>
					<li>Report Options</li>
				</ul>
				{displayReportOptions}
			</div>
		);

		showLeftSideDetails = (
			<div class="newReportWrapperL">
				<div class="reportTypeList">
					<h6>Report Name *</h6>
					<ul>
						<li>
							<input type="text" value={reportName} onChange={(e) => this.NameChange(e)} class="form-control" placeholder="Enter Name" />
						</li>
					</ul>
				</div>
			</div>
		);

		/** Garland report generation by serial number or store */
		let showReportType;
		showReportType = (
			<div className="newReportForm">
					<h5>Report Type</h5>
					<ul>
						<li>
							<div className="customRadioBtn">
								<input id="unitChecked" type="radio" name="ReportType" checked={unitChecked} value="unit" onChange = {(e) => this.onSelectRadioCheckbox(e)}/>
								<label for="unitChecked">By Unit</label>
							</div>
						</li>
						<li>
							<div className="customRadioBtn">
								<input id="storeChecked" type="radio" name="ReportType" checked={storeChecked} value="store" onChange = {(e) => this.onSelectRadioCheckbox(e)}/>
								<label for="storeChecked">By Store</label>
							</div>
						</li> 
					</ul>
				</div>
		);

		return (

			<div id="adminWrapper">
				<div id="fleetExportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="reportWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to="/reportList" title="Back" class="backIcon">&nbsp;</Link>
								</li>
								<li className="txtboldmsg">
									New Report
				             	</li>
								<li>
									<Link className={isNextEnabled ? "activeState" : "disabled"} id="AssignUpdate" to={{
										pathname: unitChecked === true ? "/reportAssignUpdateList" :"/reportAssignStoreList",
										state: {
											reportName,
											startDate: format(startDate, "YYYY-MM-DD"),
											endDate: format(endDate, "YYYY-MM-DD"),
											reportByUnit: unitChecked,
											reportByStore: storeChecked,
											reportFormat: "XLSX",
											reportOptions
										}
									}} title="Next">Next</Link>
								</li>
							</ul>
						</div>
						<div class="newReportWrapper">
							{showLeftSideDetails}
							<div class="newReportWrapperR">
								<div class="newReportForm">
									<h6>Select Duration</h6>
									<ul>
										<li>
											<label>Start Date *</label>
											<div class="input-group">
												<DatePicker autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
										<li>
											<label>End Date</label>
											<div class="input-group">
												<DatePicker autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
									</ul>
								</div>
								<div class="maxDuration">* Maximum duration: 6 months prior.</div>
								{showReportType}
								{showOptions}
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="Reports" activeClass="reportNav" />

				{/* <!-- Info Modal --> */}
				<div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
					<div class="modal-dialog modal-md infoModal">
						<div class="modal-content">
							<div class="modal-header modal-header-danger">
								Fleet Data <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div class="modal-body">
								<div class="detailsInfoContent">
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

export default garlandReport;

