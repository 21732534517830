import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import {UserProfile} from '../../service/api';
import http from '../../service/httpService';
import authService from '../../service/authService'; 
import fConsts from '../../utils/featureConstants'; 
import { BRAND_ID } from '../../utils/appConstants';
import $ from 'jquery';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';

import KCStore from '../../utils/kcStorage';

var index=1;
class AssignedUnitGroups extends Component {

	constructor(props) {
		super(props);
		this.toggleHidden = this.toggleHidden.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.getOrgnanizationView=this.getOrgnanizationView.bind(this);	
		this.getCustGroupID=this.getCustGroupID.bind(this);
		this.showLinks = this.showLinks.bind(this);	
		this.state = {			
			isHidden: false,
			loaderIconVisible: false,
			linkList:{},
			findTreeLevel:"",
		};
	}

	toggleHidden(event) {
		event.preventDefault();

		if (!this.state.isHidden) {
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);

		}
		this.setState({
			isHidden: !this.state.isHidden
		})
	}

	handleOutsideClick(event) {
		// ignore clicks on the component itself             
		if (this.pop) {
			//const domNode = ReactDOM.findDOMNode(this.pop)
			if (this.pop.contains(event.target)) {
				return;
			}
			this.toggleHidden(event);
		}
	}

	
	componentDidMount() {
		this.getCustGroupID()	
		document.body.style.backgroundColor = "#F2F2F2";
	}
	
	componentWillMount(){
        this.getCustGroupID()
    } 

	getCustGroupID = () => {
		let custGroupIDList = "";
		var setcustGroupIDList="";
		let loginUser =   JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		this.setState({loaderIconVisible: true,})
		http({
			method: "GET",			
			url: `${UserProfile.get_Cust_GroupID}`,	
			headers: {			
				'customerid': KCStore.getItem("customerId"),
				'email': loggedinUser,	
				'Content-Type': 'application/json',
                'basecountry': KCStore.getItem('basecountry'),
                'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
			},data:{},
		}).then(response => {
			custGroupIDList=response.data.data;
			custGroupIDList.forEach(p => {
				setcustGroupIDList?setcustGroupIDList=setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList=p.customer_group_id			
			});

			this.setState({
				linkList: response.data,
			})
			
			if  (setcustGroupIDList !=="")
			{
				this.getOrgnanizationView(setcustGroupIDList);
			}
			// this.setstate({loaderIconVisible: false,})
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({
				linkList: [],	
				loaderIconVisible: false,			 
			})
		});
	}

	processOrgnanizationJSON(orgJSON) {
		let findLeveltoShow = orgJSON.start_level_name
		if (findLeveltoShow==="level1")
		{
			findLeveltoShow = orgJSON.level1[0]
		}
		else if (findLeveltoShow==="level2")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0]
		}
		else if (findLeveltoShow==="level3")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0]
		}
		else if (findLeveltoShow==="level4")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0].level4[0]			
		}
		else if (findLeveltoShow==="level5")
		{
			findLeveltoShow = orgJSON.level1[0].level2[0].level3[0].level4[0].level5[0]
		}
		findLeveltoShow = orgJSON.level1[0]
        this.setState({
			linkList: orgJSON,
			findTreeLevel: findLeveltoShow, 
			loaderIconVisible: false,				 
		})
	}
	
	getOrgnanizationView = async(values) => {
			this.setState({loaderIconVisible: true,})
			let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        	let loggedinUser = loginUser.username;
			await http({
				method: "GET",			
				url: `${UserProfile.get_Orgnanization_View + KCStore.getItem("customerId")}`,	
				headers: {				
					"Cgids":values,
					'Content-Type': 'application/json',	
					'brandids': BRAND_ID,
					'useremail': loggedinUser,
					'basecountry': KCStore.getItem('basecountry')				
				},data:{},
			}).then(async response => {
				let orgJSON = await readArchivesFromCompressedResponse(response.data);
            	this.processOrgnanizationJSON(orgJSON);
				// if (response.data.fetchFromS3) {
				// 	await $.getJSON(response.data.presignedS3URL, (data) => {
				// 		this.processOrgnanizationJSON(data);
				// 	});
				//  }
				//  else {
				// 	 this.processOrgnanizationJSON(response.data);
				//  }
			}).catch(err => {
				console.log('catch exception occured');
				this.setState({
					linkList: [],	
					loaderIconVisible: false,			 
				})
			});
		
	}

	showLinks(linkList) {
		if (!linkList) return null;
		if (linkList) {
			index++;									
			return <React.Fragment>
					{linkList.nextLevelAccess ? <div class="assignedUnitGrpsTable"> 
						<ul class="tBody"> 
							<li><a role="button" data-toggle="collapse" data-parent="#agedUGrpsAccordion" href={`#agedUGrpsAcc` + index} aria-expanded="false" aria-controls="agedUGrpsAcc23333" class="btn collapseExpand">&nbsp;</a> {linkList.group_name}</li>
							<li>{linkList.child_count}</li>
						</ul>
					</div> : ""}
					{/* <!-- Header 02 Content --> */}
					
					<div id={`agedUGrpsAcc` + index} class="panel-collapse show collapse" role="tabpanel" aria-labelledby="agedUGrpsAccH2">
						<div class="panel-body assignedUnitGrpsTable">
							{linkList["level" + parseInt(linkList.level_id + 1)] && linkList["level" + parseInt(linkList.level_id + 1)].map((item, i) =>
							 	<div className="treeSecondNode">
									{this.showLinks(item)}	
								</div>		 
							)}

							{linkList["units"] && linkList["units"].map((item, i) =>
								<ul class="tBody">
									<li class="assignedUnitGrpsTableUnitLi">{item.UNITID}</li>
									<li>&nbsp;</li>									
								</ul>
							)}
						</div>						
					</div>  
				</React.Fragment>
		}

	}
	
	// Woopra Events
	trackEvent = (e) => {
		let title = e.trackTitle === "Time & Number Format" ? "Time and Number Format" : e.trackTitle;
		WoopraEvents(`${title}`);
	}

	render() {	
		let {loaderIconVisible}= this.state;
		const { formatMessage } = this.props.intl;
		let NotificationManagementEditOrViewLink;
		let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);		  
		if(userNotificationAuthFeature && userNotificationAuthFeature.feature_active) {				
			
			NotificationManagementEditOrViewLink="/NotificationManagement" ;
		}
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to="/myinformationEdit" title={formatMessage({id: 'KC1916'})} onClick={(e)=> {e.trackTitle="My Information";this.trackEvent(e)}}><FormattedMessage id='KC1916' /></Link>
										<Link to="/assignedunitgroupsEdit" title={formatMessage({id: 'KC1917'})} class="active"><FormattedMessage id='KC1917' /></Link>
										{NotificationManagementEditOrViewLink && <Link to="/notificationmanagement" title={formatMessage({id:'KC0109'})} onClick={(e)=> {e.trackTitle="Notification Management";this.trackEvent(e)}}><FormattedMessage id='KC0109' /></Link>}
										<Link to="/timeNumberFormatedit" title={formatMessage({id:'KC0110'})} onClick={(e)=> {e.trackTitle="Time & Number Format";this.trackEvent(e)}}><FormattedMessage id='KC0110' /></Link>
										{/* <Link to="/mySubscriptionEdit" title="Subscription Management">Subscription Management</Link> */}
										<Link to="/termsPricingCondition" title={formatMessage({id: 'KC2439'})} ><FormattedMessage id='KC2439' /></Link>
									</li>
								</ul>
							</div>
							<div class="assignedUnitGroupsTableDiv">
								<div class="assignedUnitGrpsTableOuter">
									<div class="panel panel-default" id="agedUGrpsAccordion" role="tablist" aria-multiselectable="true">										
										{this.state.linkList && this.state.linkList.level1 && this.showLinks(this.state.findTreeLevel)}																			
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage="User Profile" activeClass="UserProfileNav" />
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {	
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignedUnitGroups));
