import React, { Component } from 'react';
import { UserProfile } from '../../../service/api';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import $ from 'jquery';
import _ from 'lodash';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { readArchivesFromCompressedResponse } from '../../../utils/helpers';
import KCStore from '../../../utils/kcStorage';

var bg = require('../../../images/unit/orgDefault.png')
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;

export class ReportOrgModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            generic_modal: {
                open: false,
                message: "",
                messagetype: ""
            },
            level: 1,
            link: {},
            linkList: {},
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            isUnitItemClicked: false,
            checkedUnits: []
        };
        this.showLinks = this.showLinks.bind(this);              
    }

    componentDidUpdate = async (prevProp) => {
        document.body.style.backgroundColor = "#F2F2F2";
        if (prevProp.reportID !== this.props.reportID) {
            $(".assignOrgTreeOuter").scrollTop(0);
            $("#orgznSiteTreediv").css("display","none");
            $("#loadingMsg").css("display","block");
            let brandid = KCStore.getItem("selectedbrandID");
            await this.getDynacity(brandid).then(response => {
                let dynasty = response ? response : [];
                dynasty = dynasty.map(d => d.dynasty);
                this.setState({
                    selectedGroupName: response,
                    selectedDynasty: dynasty,
                    loaderIconVisible: false
                })
                this.getCustGroupID();
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
		$("div").removeClass("modal-backdrop fade show");
	}

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $("#orgViewModal .modal-header").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 280;   
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    getCustGroupID = () => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true, })

        this.setState({ queryString: loggedinUser });
        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customerid': KCStore.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'basecountry': KCStore.getItem('basecountry'),
                'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });

            if (setcustGroupIDList !== "") {
                this.getOrgnanizationView(setcustGroupIDList);
            }
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    processOrgnanizationJSON(orgJSON) {
        // const length = orgJSON.start_level_id;
        const length =1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
		
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,            
            isUnitItemClicked: true
        });
    }

    getOrgnanizationView = async(values) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + KCStore.getItem("customerId")}?removeCache=${new Date().getTime()}`,
            headers: {
                "Cgids": values,
                'brandids': KCStore.getItem("selectedbrandID"),
                'Content-Type': 'application/json',
				'useremail': loggedinUser,
                "basecountry": BASECOUNTRY_ID,
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
			// 	await $.getJSON(response.data.presignedS3URL, (data) => {
			// 		this.processOrgnanizationJSON(data);
			// 	});
			// }
			// else {
			// 	this.processOrgnanizationJSON(response.data);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
			this.processOrgnanizationJSON(orgJSON);
            this.setState({
                cgIds: values
            })
            $("#orgznSiteTreediv").css("display","block");
            $("#loadingMsg").css("display","none");
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    onItemClick = async (e, link, isSelected) => {
        this.setState({ 
            loaderIconVisible: false
        });
        return;
    }

    onUnitItemClick(e, unit) {
        this.setState({ 
            loaderIconVisible: false,
        });
        return;
    }

    onOrgCheckBoxChange(e, unit) {
        this.setState({ 
            loaderIconVisible: false
        });
        return;
    }

    showUnits(link) {
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            let checkedUnits = KCStore.getItem("selectedUnitID") ? JSON.parse(KCStore.getItem("selectedUnitID")) : [];
            return link.units.map((unit, index) => {
                let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled" : ""
                treeActive = "";
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = KCStore.getItem('modelImage') ? JSON.parse(KCStore.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                let unitInActive = (unit.ACTIVATION_STATUS === "INACTIVE") ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
                
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false
                treeActive = ifExist ? "treeActive" : "";
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';

                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        {!showCollpaeCount && <div class="customCheckbox">
                            <input id={unit.UNITID} name={unit.UNITID} type="checkbox" checked={ifExist} value={unit.UNITID} onChange={(e) => this.onOrgCheckBoxChange(e, unit)} />
                            <label for={unit.UNITID}></label>
                        </div>}
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel"
                                >{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }

    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }

    onCollapseButtonClick(e, link) {
        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }
        this.setState({ showLink: showLink })
    }

    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }

    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        let selectedGroupID = this.state.selectedGroupName;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;
                let cust_gp_value = selectedGroupID.length > 0 ?  selectedGroupID.find(cust => cust.cust_gp_id === link.cust_gp_id.toString()) : "";
                const isParentDivActive = (link.dynasty && (selectedDynasty.indexOf(link.dynasty) > -1 || (cust_gp_value !== undefined && cust_gp_value !== ""))) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && (selectedDynasty.indexOf(link.dynasty) > -1 || (cust_gp_value !== undefined && cust_gp_value !== ""))) ? 'treeActive' : '';
                const showCollpaeCount = this.showCollpaseItemCount(link);
                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link, isParentDivActive === '') }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt="" />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList, isParentDivActive === '') }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }

    getDynacity = () => {
        let selCgid = KCStore.getItem("selectedGroupID");
        return new Promise((resolve, reject) => {
            if (selCgid) {
                http.get(`${UserProfile.get_customer_Dynasty}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgids': [selCgid],
                    },
                    data: {}
                })
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                })
            }
            else {
                resolve([]);
            }
        })
    }

    render() {
        let { selectedGroupName } = this.state;
        const { linkList, findTreeLevel } = this.state;  
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                $(".assignOrgTreeOuter").scrollTop(0);
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={"Level" + item.level_id} >{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div class="modal fade orgViewModal" data-keyboard="true" data-backdrop="static" id="orgViewModal" tabindex="-1" role="dialog" aria-labelledby="orgViewModalLabel">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ minHeight: $(window).height() - 60 }}>
                        <div class="modal-header modal-header-danger">
                            <FormattedMessage id="KC2204"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                        </div>
                        <div class="modal-body">
                            <div id="adminWrapper">
                                    <div className="orgznManagementWrapper">
                                        <div className="selectedUnitHeader">
                                            {
                                                <ul>
                                                    <li>
                                                    {
                                                    selectedGroupName.map((subitem, j) =>
                                                        <span className="selectedVal">
                                                        {subitem.group_name} &nbsp; 
                                                        </span>
                                                    )}
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                            <div className="collapseExpandHeader">
                                                <ul>
                                                    {levelHeader}
                                                </ul>
                                            </div>
                                            <div className="assignOrgTreeOuter">
                                                <div id="orgznSiteTreediv" className="orgznSiteTree">
                                                    {linkList && linkList.level1 &&
                                                        linkList.level1.map((item) => this.showLinks(findTreeLevel))}
                                                </div>
                                                <div id="loadingMsg" class="orgznSiteTree loadertextblue">
                                                    <FormattedMessage id="KC1151"/>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
