import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export class FeaturesList extends PureComponent {

    getCheckBoxstate(ListCount, SelectedCount) {
        if (SelectedCount === ListCount) {
            return { isAllSelect: true, isIndeterminateSelect: false };
        } else if (SelectedCount === 0 && ListCount !== 0) {
            return { isAllSelect: false, isIndeterminateSelect: false };
        } else if (ListCount >= SelectedCount) {
            return { isAllSelect: true, isIndeterminateSelect: true };
        }
    }

    checkPermission = (item, features) => {
        const featureindex = features.findIndex(f => f.Id === item.parent_feature_id);
        return features[featureindex].is_view_only === 0 && features[featureindex].is_editable === 0;
    }

    render() {

        let { roleFeaturesList, featureTitle, featureTitleStringID, allFeaturesData, isRoleEditable, isRoleFeatureUpdated, disabled } = this.props;

        let features = [...allFeaturesData];

        isRoleFeatureUpdated && features.forEach(f => {
            f.is_view_only = 0;
            f.is_editable = 0;
            f.feature_active = 0;
        });

        isRoleFeatureUpdated && roleFeaturesList.forEach(feature => {
            for (let index = 0; index < features.length; index++) {
                if (features[index].Id === feature.feature_id) {
                    features[index].feature_id = feature.feature_id;
                    features[index].feature_category = feature.feature_category;
                    features[index].feature_name = feature.feature_name;
                    features[index].is_view_only = feature.feature_active ? feature.is_view_only : 0;
                    features[index].is_editable = feature.feature_active ? feature.is_editable : 0;
                    features[index].feature_active = feature.feature_active;
                }
            }
        });


        features && features.filter(item => !item.feature_id).forEach(f => {
            f.feature_id = f.Id;
            f.is_view_only = f.feature_active ? f.is_view_only : 0;
            f.is_editable = f.feature_active ? f.is_editable : 0;
            f.feature_active = f.feature_active ? f.feature_active : 0;
        });

        let selectedFeatures = features.filter(item => item.feature_category === featureTitle);


        if (roleFeaturesList.length === 0) {

            selectedFeatures.forEach(feature => {
                feature.feature_id = feature.Id;
                feature.is_view_only = feature.is_view_only ? feature.is_view_only : 0;
                feature.is_editable = feature.is_editable ? feature.is_editable : 0;
                feature.feature_active = feature.feature_active ? feature.feature_active : 0;
            });
        }


        let checkBoxViewState = this.getCheckBoxstate(selectedFeatures.length, selectedFeatures.filter(item => item.is_view_only === 1).length);
        let checkBoxEditState = this.getCheckBoxstate(selectedFeatures.length, selectedFeatures.filter(item => item.is_editable === 1).length);

        let isAllViewOnlySelected = checkBoxViewState && checkBoxViewState.isAllSelect ? checkBoxViewState.isAllSelect : false;
        let isAllViewOnlyIndeterminateSelect = checkBoxViewState && checkBoxViewState.isIndeterminateSelect ? checkBoxViewState.isIndeterminateSelect : false;
        let isAllEditableSelected = checkBoxViewState && checkBoxEditState.isAllSelect ? checkBoxViewState.isAllSelect : false;
        let isAllEditableIndeterminateSelect = checkBoxEditState && checkBoxEditState.isIndeterminateSelect ? checkBoxEditState.isIndeterminateSelect : false;

        console.log("Selected features", selectedFeatures);
        let isAllViewOnlyDisabled = selectedFeatures.length === selectedFeatures.filter(item => item.isViewDefaultDisable === false).length;
        console.log("selectedFeatures.filter(item => item.isViewDefaultDisable === false)", selectedFeatures.filter(item => item.isViewDefaultDisable === false));
        let isAllEditableDisabled = selectedFeatures.length === selectedFeatures.filter(item => item.isEditDefaultDisable === false).length;
        console.log("selectedFeatures.filter(item => item.isEditDefaultDisable === false)", selectedFeatures.filter(item => item.isEditDefaultDisable === false));

        if (featureTitle === 'Global Functions') {
            const subFeatures = selectedFeatures.filter(f => f.feature_name !== "Fleet Dashboard" && f.is_editable === 1);
            let checkBoxState = this.getCheckBoxstate(selectedFeatures.length - 1, subFeatures.length)
            isAllEditableSelected = checkBoxState && checkBoxState.isAllSelect ? checkBoxState.isAllSelect : false;
            isAllEditableIndeterminateSelect = checkBoxState && checkBoxState.isIndeterminateSelect ? checkBoxState.isIndeterminateSelect : false;
        }

        const selectAllEditCheckBoxClassName = isAllEditableIndeterminateSelect ? ['customCheckbox customCheckboxWOlabel selectAllDiv'] : ['customCheckbox customCheckboxWOlabel'];
        const selectAllViewCheckBoxClassName = isAllViewOnlyIndeterminateSelect ? ['customCheckbox customCheckboxWOlabel selectAllDiv'] : ['customCheckbox customCheckboxWOlabel'];
        const selectSingleCheckBoxClassName = ['customCheckbox customCheckboxWOlabel'];
        let disabledClass = null;
        if (!isRoleEditable || disabled) {
            disabledClass = 'cursor-not-allowed';
        }

        let newOrder = selectedFeatures.filter((i) => !i?.parent_feature_id);
        for (let index = 0; index < newOrder.length; index++) {
            newOrder[index]['rowCount'] = index + 1;
        }
        let childFeatures = selectedFeatures.filter((i) => i?.parent_feature_id);
        for (let index = 0; index < childFeatures.length; index++) {
            const element = childFeatures[index];
            const findIndex = newOrder.findIndex((i) => i.Id === element?.parent_feature_id);
            newOrder.splice(findIndex + 1, 0, element);
        }
        selectedFeatures = newOrder
        return (
            <form className={featureTitle} style={{ display: selectedFeatures.length > 0 ? "block" : " none" }}>
                <div className="userRoleVETable">
                    <ul className="tHead">
                        <li>&nbsp;</li>
                        <li>{featureTitleStringID ? <FormattedMessage id={featureTitleStringID} /> : featureTitle}</li>
                        <li><img src={require("../../images/icons/viewD.svg").default} alt="" /></li>
                        <li>
                            <div className={selectAllViewCheckBoxClassName.join(' ')}>
                                <input id={featureTitle + "ViewOnly"} name={featureTitle + "ViewOnly"} type="checkbox"
                                    disabled={!isRoleEditable || disabled || !isAllViewOnlyDisabled}
                                    value={isAllViewOnlySelected}
                                    checked={isAllViewOnlySelected}
                                    onChange={(e) => isRoleEditable && this.props.onAllCheckBoxChange(e, featureTitle)} />
                                <label htmlFor={featureTitle + "ViewOnly"} className={!isAllViewOnlyDisabled ? 'cursor-not-allowed' : disabledClass}>&nbsp;</label>
                            </div>
                        </li>
                        {featureTitle !== "Unit Data" ? <React.Fragment>
                            <li><img src={require("../../images/icons/penD.svg").default} alt="" /></li>
                            <li><div className={selectAllEditCheckBoxClassName.join(' ')}>
                                <input id={featureTitle + "EditOnly"} name={featureTitle + "EditOnly"} type="checkbox"
                                    disabled={!isRoleEditable || disabled || !isAllEditableDisabled}
                                    value={isAllEditableSelected}
                                    checked={isAllEditableSelected}
                                    onChange={(e) => isRoleEditable && this.props.onAllCheckBoxChange(e, featureTitle)} />
                                <label htmlFor={featureTitle + "EditOnly"} className={!isAllEditableDisabled ? 'cursor-not-allowed' : disabledClass}>&nbsp;</label>
                            </div>
                            </li> </React.Fragment> : <React.Fragment><li className={selectSingleCheckBoxClassName.join(' ')}></li><li></li></React.Fragment>
                        }
                    </ul>
                    {
                        selectedFeatures.map((item, i) => {
                            const isCheckboxEnable = item?.parent_feature_id ? this.checkPermission(item, selectedFeatures) : false;
                            if (item?.parent_feature_id) {
                                selectedFeatures[i].isEditDefaultDisable = isCheckboxEnable;
                                selectedFeatures[i].isViewDefaultDisable = isCheckboxEnable;
                            }
                            return (
                                <ul className="tBody" key={i}>
                                    {!item.parent_feature_id ? <li>{item?.rowCount || i + 1}</li> : <li>&nbsp;</li>}
                                    <li className={`${isRoleEditable === 0 ? "disabled" : ""} ${item?.parent_feature_id ? "childClm" : ""} `}>{item.feature_name}</li>
                                    <li>&nbsp;</li>
                                    <li>
                                        <div className={selectSingleCheckBoxClassName.join(' ')} >
                                            <input id={item.Id + "is_view"} name={item.Id + "is_view"} type="checkbox"
                                                disabled={!isRoleEditable || disabled || item.isViewDefaultDisable || isCheckboxEnable}
                                                value={item.is_view_only}
                                                checked={item.is_view_only}
                                                onChange={(e) => isRoleEditable && this.props.onCheckBoxChange(e, item)} />
                                            <label htmlFor={item.Id + "is_view"} className={item.isViewDefaultDisable ? "cursor-not-allowed" : disabledClass}>&nbsp;</label>
                                        </div>
                                    </li>
                                    <li>&nbsp;</li>
                                    {featureTitle === "Unit Data" || item.feature_name === "Fleet Dashboard" || item.feature_name === "How To Manual" ? <li>&nbsp;</li> : <li>
                                        <div className={selectSingleCheckBoxClassName.join(' ')}>
                                            <input id={item.Id + "is_edit"} name={item.Id + "is_edit"} type="checkbox"
                                                disabled={!isRoleEditable || disabled || item.isEditDefaultDisable || isCheckboxEnable}
                                                value={item.is_editable}
                                                checked={item.is_editable}
                                                onChange={(e) => isRoleEditable && this.props.onCheckBoxChange(e, item)} />
                                            <label htmlFor={item.Id + "is_edit"} className={item.isEditDefaultDisable ? "cursor-not-allowed" : disabledClass}>&nbsp;</label>
                                        </div>
                                    </li>
                                    }
                                </ul>
                            )
                        })
                    }

                </div>
            </form>

        )


    }
}

FeaturesList.propTypes = {
    roleFeaturesList: PropTypes.array.isRequired,
    featureTitle: PropTypes.string.isRequired,
    allFeaturesData: PropTypes.array.isRequired,
    isRoleEditable: PropTypes.bool.isRequired,
    isRoleFeatureUpdated: PropTypes.bool.isRequired
}


