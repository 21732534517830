import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import Switch from "react-switch";
import Slider from 'react-rangeslider'
import axios from 'axios';
import { MenuUploadAPI, BaseService, MediaManagementAPI } from '../../../../../service/api';
import { CUSTOMER_ID } from '../../../../../utils/appConstants';
import  MediaListPopup  from '../../mediaListPopup';
import http from '../../../../../service/httpService';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
import LoadingPopup from '../../../../controls/loadingPopup';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { nanoid } from 'nanoid';
import KCStore from '../../../../../utils/kcStorage';
var request = require('request');

class Sound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            audio_volume: "",
            sound_keypress: "",
            audioType: "",
            endofcook_tone: "Bleep.wav",
            warning_tone: "Alert.wav",
            error_tone: "Stop.wav",
            dooropen_tone: "Open.wav",
            importOption: "Library"
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onVolumeChange = this.onVolumeChange.bind(this);
        this.onMuteTouchscreenSelect = this.onMuteTouchscreenSelect.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.onAudioChange = this.onAudioChange.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
        this.cancelImportClick = this.cancelImportClick.bind(this);
        this.handleImportClick = this.handleImportClick.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $('.mCustomScrollbar').mCustomScrollbar();
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonSound = JSON.parse(jsonSettingsFile);

            let jsonlibraryDetails = KCStore.getItem("libraryDetails");
            let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
            this.setState({
                audio_volume: jsonSound.audio_volume ? jsonSound.audio_volume : 0,
                sound_keypress: jsonSound.sound_keypress ? jsonSound.sound_keypress : "false",
                endofcook_tone: (ObjlibraryDetails.settings && ObjlibraryDetails.settings.find(a => a.fileType === "end_of_cycle")) ? ObjlibraryDetails.settings.find(a => a.fileType === "end_of_cycle").fileName : "Bleep.wav",
                warning_tone: (ObjlibraryDetails.settings && ObjlibraryDetails.settings.find(a => a.fileType === "pad_press")) ? ObjlibraryDetails.settings.find(a => a.fileType === "pad_press").fileName : "Alert.wav",
                error_tone: (ObjlibraryDetails.settings && ObjlibraryDetails.settings.find(a => a.fileType === "error")) ? ObjlibraryDetails.settings.find(a => a.fileType === "error").fileName : "Stop.wav",
                dooropen_tone: (ObjlibraryDetails.settings && ObjlibraryDetails.settings.find(a => a.fileType === "door_open")) ? ObjlibraryDetails.settings.find(a => a.fileType === "door_open").fileName : "Open.wav" 
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/sound"
        });
    }

    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    cancelImportClick = (event) => {
        $('#popupTrigger').trigger('click');
    }

    handleImportClick = (event) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#popupTrigger').trigger('click');
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        try {
            let { audio_volume , sound_keypress } = this.state;
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.audio_volume = parseInt(audio_volume);
            ObjSettingsFile.sound_keypress = sound_keypress.toString();
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/settingsEditor`);
        }
        catch (error) {

        }
    }

    onVolumeChange = (value) => {
        this.setState({
            audio_volume: value
        });
    }

    onMuteTouchscreenSelect = (e) => {
        this.setState({
            sound_keypress: e
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    handleInputClick = (e, audioType) => {
        this.setState({
            audioType : audioType
        })
        $('#popupTrigger').trigger('click');
    }

    onAudioChange = (event, audioType) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let audioData = {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            // fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileextSplit = fileName.split('.');
            let fileext = fileextSplit[1];

            audioData.fileName = fileName;
            audioData.fileFormat = "." + fileext;
            audioData.fileSize = fileSize;
            audioData.s3Url = "";
            audioData.type = "audio";
            audioData.fileType = audioType;
            audioData.isDefault = false;

                if (fileext.toLowerCase() == "wav") {
                    let audioFile = event.target.files[0];
                    var reader = new FileReader();
                    reader.readAsDataURL(audioFile);
                    reader.onload = (e) => {
                        // let audiofilekey = audioData.fileName.replace(audioData.fileFormat, '') + '_' + Date.now() + audioData.fileFormat;
                        let audiofilekey = nanoid() + '_' + Date.now() + audioData.fileFormat;
                        let headerpresigned = {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'imagefilekey': '',
                            'jsonfilekey': '',
                            'audiofilekey': audiofilekey,
                            'isthumbnail': false
                        }

                        return new Promise((resolve, reject) => {
                            let token = KCStore.getItem('Access-Token');
                            headerpresigned.Authorization = `Bearer ${token}`;
                            axios({
                                url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                                method: "GET",
                                headers: headerpresigned,
                                data: {}
                            }).then(response => {
                                let { audioPresignedUrl } = response.data.presignedURLs
            
                                let UploadedAudios3url = "";
                                if (audioPresignedUrl) {
                                    UploadedAudios3url = audioPresignedUrl.split('?');
                                    UploadedAudios3url = UploadedAudios3url[0];
                                }
                                var xhraudio = new XMLHttpRequest();
                                xhraudio.open("PUT", audioPresignedUrl);
                                xhraudio.setRequestHeader('Content-Type', 'multipart/form-data');
                                xhraudio.onloadend = (res) => {
                                    if (res.target.status == 200) {
                                        let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                                        let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                                        var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType === audioType);
                                        audioData.s3Url = UploadedAudios3url;
                                        audioData.s3FileName = audiofilekey;
                                        audioData.isChanged = false;
                                        ObjlibraryDetails.settings[foundIndex] = audioData;
                                        KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                                        let libraryData = KCStore.getItem("librarySettingsData");
                                        let libraryDataObj = JSON.parse(libraryData);
                                        libraryDataObj.sounds = ObjlibraryDetails.settings;
                                        KCStore.setItem("librarySettingsData", JSON.stringify(libraryDataObj));

                                        if (audioType === "end_of_cycle") {
                                            this.setState({
                                                endofcook_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "pad_press") {
                                            this.setState({
                                                warning_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "error") {
                                            this.setState({
                                                error_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "door_open") {
                                            this.setState({
                                                dooropen_tone: audioData.fileName
                                            })
                                        }
                                        this.setState({
                                            loaderIconVisible: false
                                        });
                                    }
                                };
                                xhraudio.onerror = (err) => {
                                    reject(err);
                                };
                                xhraudio.send(audioFile);
                            }).catch(err => {
                                reject(err);
                            });
                        });
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1297"
                        }
                    });
                }
            event.target.value = ''
        }
    }

    async OnImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let audioType = _this.state.audioType;
            let audioData = {};
            audioData.fileName = item.FILE_NAME;
            audioData.fileFormat = item.FILE_FORMAT;
            audioData.fileSize = item.FILE_SIZE;
            audioData.s3Url = "";
            audioData.type = "audio";
            audioData.fileType = audioType;
            audioData.isDefault = false;

            await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                        // let audiofilekey = audioData.fileName.replace(audioData.fileFormat, '') + '_' + Date.now() + audioData.fileFormat;
                        let audiofilekey = nanoid() + '_' + Date.now() + audioData.fileFormat;
                        let headerpresigned = {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'imagefilekey': '',
                            'jsonfilekey': '',
                            'audiofilekey': audiofilekey,
                            'isthumbnail': false
                        }

                        return new Promise((resolve, reject) => {
                            let token = KCStore.getItem('Access-Token');
                            headerpresigned.Authorization = `Bearer ${token}`;
                            axios({
                                url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                                method: "GET",
                                headers: headerpresigned,
                                data: {}
                            }).then(response => {
                                let { audioPresignedUrl } = response.data.presignedURLs
            
                                let UploadedAudios3url = "";
                                if (audioPresignedUrl) {
                                    UploadedAudios3url = audioPresignedUrl.split('?');
                                    UploadedAudios3url = UploadedAudios3url[0];
                                }
                                var xhraudio = new XMLHttpRequest();
                                xhraudio.open("PUT", audioPresignedUrl);
                                xhraudio.setRequestHeader('Content-Type', 'multipart/form-data');
                                xhraudio.onloadend = (res) => {
                                    if (res.target.status == 200) {
                                        let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                                        let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                                        var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType === audioType);
                                        audioData.s3Url = UploadedAudios3url;
                                        audioData.s3FileName = audiofilekey;
                                        audioData.isChanged = false;
                                        ObjlibraryDetails.settings[foundIndex] = audioData;
                                        KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                                        let libraryData = KCStore.getItem("librarySettingsData");
                                        let libraryDataObj = JSON.parse(libraryData);
                                        libraryDataObj.sounds = ObjlibraryDetails.settings;
                                        KCStore.setItem("librarySettingsData", JSON.stringify(libraryDataObj));

                                        if (audioType === "end_of_cycle") {
                                            _this.setState({
                                                endofcook_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "pad_press") {
                                            _this.setState({
                                                warning_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "error") {
                                            _this.setState({
                                                error_tone: audioData.fileName
                                            })
                                        }
                                        else if (audioType === "door_open") {
                                            _this.setState({
                                                dooropen_tone: audioData.fileName
                                            })
                                        }
                                        _this.setState({
                                            loaderIconVisible: false
                                        });
                                    }
                                };
                                xhraudio.onerror = (err) => {
                                    reject(err);
                                };
                                xhraudio.send(body);
                            }).catch(err => {
                                reject(err);
                            });
                        });
                    })
                    .catch(err => {
                        // Handle an exception.
                        _this.setState({
                            loaderIconVisible: false
                        });
                    });
                })
                .catch(ex => {
                    console.log(ex);
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
        }
        catch (ex) {
            console.log(ex);
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    render() {
        let { audio_volume, sound_keypress, success_modal, error_modal, endofcook_tone, warning_tone, error_tone, dooropen_tone, audioType, importOption, loaderIconVisible } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <LoadingPopup loading={loaderIconVisible} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#ConfirmationOfImportModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button className="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1290"/>
				                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn">
                                                <div className="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div className="rcSettingsList02">
                                                                <ul>
                                                                    <li className="width100"><FormattedMessage id="KC1291"/></li>
                                                                    <li>
                                                                        <div className='slider custom-labels'>
                                                                            <div className='slider-horizontal rangeslider-height-level'>
                                                                                <Slider
                                                                                    min={0}
                                                                                    max={100}
                                                                                    value={audio_volume === "OFF" ? '0' : audio_volume === "MAX" ? '100' : audio_volume}
                                                                                    orientation='horizontal'
                                                                                    handleLabel={audio_volume === "OFF" ? '0' : audio_volume === "MAX" ? '100' : audio_volume}
                                                                                    tooltip={false}
                                                                                    onChange={(e) => { this.onVolumeChange(e)}}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="rcSettingsList02 soundLable"> 
                                                                <ul>  
                                                                    <li><FormattedMessage id="KC1292"/></li>
                                                                    <li>{endofcook_tone} <img src={require("../../../../../images/recipecreation/rightArrow.png")}  onClick={(e) => this.handleInputClick(e, 'end_of_cycle')} alt="" /></li>
                                                                </ul> 
                                                                <ul>  
                                                                    <li><FormattedMessage id="KC1293"/></li>
                                                                    <li>{warning_tone} <img src={require("../../../../../images/recipecreation/rightArrow.png")}  onClick={(e) => this.handleInputClick(e, 'pad_press')}  alt=""/></li>
                                                                </ul> 
                                                                <ul>  
                                                                    <li><FormattedMessage id="KC1294"/></li>
                                                                    <li>{error_tone} <img src={require("../../../../../images/recipecreation/rightArrow.png")}  onClick={(e) => this.handleInputClick(e, 'error')}  alt=""/></li>
                                                                </ul> 
                                                                <ul>  
                                                                    <li><FormattedMessage id="KC1295"/></li>
                                                                    <li>{dooropen_tone} <img src={require("../../../../../images/recipecreation/rightArrow.png")}  onClick={(e) => this.handleInputClick(e, 'door_open')}  alt=""/></li>
                                                                </ul>   
                                                            </div>
                                                            <div className="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1296"/></li>
                                                                    <li>
                                                                        <div className="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onMuteTouchscreenSelect(e)}
                                                                                checked={(sound_keypress === "true" || sound_keypress == true) ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />    
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} className="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div className="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Import Image Modal --> */}
                <MediaListPopup mediaType={'audio'} OnImportImage={(item) => this.OnImport(item)} />
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1299"/></h5>
                                    <div className="uploadOption">
                                        <div className="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id='KC0658' /></label>
                                        </div> 
                                        <div className="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id='KC0021' /></button>
                                <input type="file" accept="audio/wav" ref={input => this.inputElement = input} onChange={(e) => this.onAudioChange(e, audioType)} className="fileInputField" id="menuUpdate" />
                                <button className="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleImportClick}><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Sound)
