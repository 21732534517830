import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService, UserProfile, unitAPI, UserMgmtAddUser, DeviceRegistration, userRoleURI, UserManagement } from '../../service/api';
import { setaccessCountryId, BASECOUNTRY_ID, setcustomerId, setbasecountryId, THANK_YOU_TEXT_END, THANK_YOU_TEXT } from '../../utils/appConstants';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect'
import { brandUnits } from '../../utils/imgContants';
import authConstants from '../../utils/authConstants';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AccessModal from '../modal/access-model';
import FLSuccessMoal from '../modal/fl-success-model';
import FLSuccessMoalBackToHome from '../modal/fl-home-success-model';
import queryString from 'query-string';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';
import { loginResponseAction } from '../login/loginUtils';
import LanguagePreferenceCommon from '../languageDropdown/LanguagePreferenceCommon';
import { connect } from 'react-redux';

import KCStore from '../../utils/kcStorage';

const HTTP_METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE'
}

const Mailto = ({ email, subject, body, children }) => {
    return (
        <a href={`mailto:${email}`}>{children}</a>
    );
};

let headers = {
    'Content-Type': 'application/json',
};

class AddUnitSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            showhidepassword: false,
            type: 'password',
            jwtToken: '',
            validatedResponse: {},
            isPageLoad: true,
            email: '',
            password: '',
            errors: {
                'emailid': '',
                'password': ''
            },
            isFormValidated: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            access_modal: {
                open: false,
                message: ""
            },
            fl_success_modal: {
                open: false,
                message: ""
            },
            fl_home_success_modal: {
                open: false,
                message: ""
            },
            fields: {},
            errors: {},
            saveData: {},
            showhidenewpassword: false,
            showhideretypenewpassword: false,
            newpwdtype: 'password',
            oldpwdtype: 'password',
            retypenewpwdtype: 'password',
            showFirstLogin: false,
            maintenanceText: '',
            userData: [],
            jwtTokanVal: "",
            validateEmail: '',
            emailPopup: false,
            isResendClicked: false,
            isQRCode: false
        };
        this.emailblurevent = this.emailblurevent.bind(this);
        this.emailValidateblurevent = this.emailValidateblurevent.bind(this);
        this.passwordblurevent = this.passwordblurevent.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    async componentDidMount() {
        $('body').addClass("deviceRegtrnBg");
        var urlParams = new URLSearchParams(window.location.search);

        let jwtToken = urlParams.get("auth");
        if (!jwtToken) {
            jwtToken = await this.getJWTToken(urlParams);
            jwtToken = this.state.jwtTokanVal;
        }
        if (!jwtToken) {
            this.setState({ loaderIconVisible: false });
            return;
        }
        await KCStore.setItem("Access-Token", jwtToken);
        this.setState({ isPageLoad: jwtToken ? true : false, jwtToken }, async () => {
            await this.validateDevice();
            this.setState({ loaderIconVisible: false })
        })
        this.OTPInputFocus();
    }

    getJWTToken = async (urlParams) => {
        const serialNumber = urlParams.get("serialNumber");
        const brandName = urlParams.get("brand");
        const registrationCode = urlParams.get("registrationCode");

        let url = DeviceRegistration.validateDeviceRegistration;
        const stringified = queryString.stringify({
            brandName,
            serialNumber,
            registrationCode,
            'flow_initiated_from': 'KC_REG_UNIT'
        });

        url = url + (stringified ? `?${stringified}` : '');

        await http.get(url, {
            headers,
            data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data.httpCode === 200) {
                    if (response.data.body && response.data.body.length) {
                        if (response.data.body[0].jwtToken) {
                            this.setState({
                                jwtTokanVal: response.data.body[0].jwtToken
                            })
                        } else {
                            this.setState({
                                errors: {
                                    errors: 'KC0973',
                                    emailid: ''

                                },
                                isQRCode: true
                            })
                        }
                    }
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: "KC0973",
                        },
                        isQRCode: true
                    })
                }
                this.setState({
                    loaderIconVisible: false
                });
            }
        }).catch(err => {
            this.setState({
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data && err.response.data.body && err.response.data.body.message ? err.response.data.body.message : "Something went wrong",
                },
                isQRCode: true
            })
        });
    }

    togglePwd = () => {
        this.setState((prevState) => ({
            showhidepassword: !prevState.showhidepassword,
            type: prevState.type === 'password' ? 'text' : 'password'
        }))
    }

    emailblurevent = () => {
        if (!this.state.email) {
            return;
        }
        let fields = {};
        let inputdataemailobj = this.state.email;
        let errors = { ...this.state.errors };
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    emailid: ''

                }
            })
        }
        return false;
    }

    emailValidateblurevent = () => {
        if (!this.state.validateEmail) {
            return;
        }
        let fields = {};
        let inputdataemailobj = this.state.validateEmail;
        let errors = { ...this.state.errors };
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    validateEmail: ''

                }
            })
        }
        return false;
    }
    passwordblurevent = () => {
        if (!this.state.password) {
            return;
        }
        let fields = {};
        let inputdatapwdobj = this.state.password

        let errors = { ...this.state.errors };
        var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);
        // remove error condition for pwd id
        if (inputdatapwdobj && inputdatapwdobj !== "undefined" && pwdmatch.test(inputdatapwdobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    password: ''
                }
            })
        }
        return false;
    }

    validateForm = () => {
        let { email, password } = this.state;
        let errors = {};
        let formIsValid = true;

        if (!email) {
            formIsValid = false;
            errors["emailid"] = <FormattedMessage id="KC2910"/>;
        }

        if (typeof email !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                formIsValid = false;
                errors["emailid"] = <FormattedMessage id="KC2909"/>;
            }
        }

        if (!password) {
            formIsValid = false;
            errors["password"] = <FormattedMessage id="KC0019"/>;
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    showError = (err, inputdataemailobj, inputdatapwdobj) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 500) {
                let { body } = data;

                if (data.errorCode === "FIRST-LOGIN") {

                    this.setState({
                        loaderIconVisible: false,
                        fl_success_modal: {
                            open: true,
                            message: <FormattedMessage id="KC0947"/>                            
                        },
                        error_modal: {
                            open: false,
                        },
                        showFirstLogin: true,
                        username: inputdataemailobj,
                        password: inputdatapwdobj,

                    });
                }
                else if (data.responseCode === "KC_ERR_500" && data.message === "User is not active.") {
                    this.setState({
                        loaderIconVisible: false,
                        access_modal: {
                            open: true,
                            message: <FormattedMessage id="KC0903"/>
                        },
                        username: inputdataemailobj
                    });
                }
                else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: "KC0020"
                        },
                        email: '',
                        password: '',
                        fields: {
                            ...this.state.fields,
                            emailid: '',
                            password: ''
                        },
                        loaderIconVisible: false

                    });
                    let fields = {};
                }
            }
        } else {
            this.setState({
                fields: {},
                success_modal: {
                    open: false,
                    message:<FormattedMessage id="KC0905"/>
                },
                loaderIconVisible: false,
                showFirstLogin: false
            });
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
            fl_success_modal: {
                open: false
            },
            fl_home_success_modal: {
                open: false,
                message: ""
            }
        })
        if (this.state.isQRCode) {
            this.props.history.replace('/register-unit');
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        })
    }


    getCustomerID = (customerID, emailID, baseCountry) => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customerid': customerID,
                'email': emailID,
                'Content-Type': 'application/json',
                'basecountry': baseCountry,
                'emulation': false
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });

            KCStore.setItem("custGroupID", setcustGroupIDList);
            this.getUserStartLevelID(setcustGroupIDList);
            let accessFeatures = JSON.parse(KCStore.getItem("allFeatures"));
            if (accessFeatures[0].feature_category == "Main Menu Apps") {
                // this.props.history.push(`/mainmenu`);
            }
            else {
                /** starts:pwp for exporbar csv redirect*/
                const unitBrandId = KCStore.getItem("csvUnitBrandId");
                const unitId = KCStore.getItem("csvUnitID");
                if (unitId !== null && unitBrandId !== null) {
                    csvExUnitRedirect(unitId, unitBrandId, this.props.history.push)
                }
                else {
                    if (KCStore.getItem("kcbrandsitedata")) {
                        let objbrandsitedata = JSON.parse(KCStore.getItem("kcbrandsitedata"));
                        if (objbrandsitedata.readyrecipeids) {
                            KCStore.setItem("selectedbrandID", objbrandsitedata.brandid);
                            // this.props.history.push(`/legacySelectDevice`);
                        }
                    }
                    else {
                        // this.props.history.push(`/dashboardLocations`);
                    }
                }
                /**ends:pwp */
            }
        }).catch(err => {

        });
    }

    getUserStartLevelID = async (values) => {
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + KCStore.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'useremail': loggedinUser
            }, data: {},
        }).then(async response => {
            let data = await readArchivesFromCompressedResponse(response.data);
            KCStore.setItem("custLevelID", data.start_level_name);
            KCStore.setItem("startLevelID", data.start_level_id);

            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         KCStore.setItem("custLevelID", data.start_level_name);
            //         KCStore.setItem("startLevelID", data.start_level_id);
            //     })
            // }
            // else {
            //     KCStore.setItem("custLevelID", response.data.start_level_name);
            //     KCStore.setItem("startLevelID", response.data.start_level_id);
            // }
        }).catch(err => {
        });
    }

    getChildUserEmailList = async (customerId, loggedinUser) => {
        const urlVal = UserMgmtAddUser.addUser + "?customerId=" + customerId + "&email=" + loggedinUser;
        await http({
            method: "GET",
            url: urlVal,
            headers: {
                "cgid": '',
                'Content-Type': 'application/json',
            }, data: {},
        }).then(response => {
            let email = [];
            if (response.data && response.data.data && response.data.data !== "No Records Found") {
                for (let i = 0; i < response.data.data.length; i++) {
                    const ele = response.data.data[i];
                    email.push(ele.email);
                }
            }
            KCStore.setItem('childUsersEmail', JSON.stringify(email));
        }).catch(err => {
            KCStore.setItem('childUsersEmail', JSON.stringify([]));
        });
    }

    getUserRole = async (customerId, userName) => {
        http.get(UserProfile.getUserProfileDetails,
            {
                headers: {
                    'customer_id': customerId,
                    'email': userName,
                    'Content-Type': 'application/json'
                }, data: {},
            }).then(response => {
                KCStore.setItem("roleName", response.data.role_name);
            }).catch(err => {
            });
    }

    putData = (postData, url, method, rememberme) => {
        let username = this.state.email;
        return new Promise((resolve, reject) => {
            auth.login(postData, rememberme).then(async res => {
                let { status, data: loginData } = res;
                if (status === 200) {
                    this.setState({
                        isFormValidated: true,
                        // success_modal: {
                        //     open: false,
                        //     message: `You have successfully logged in "${username}".`
                        // },
                    });
                    // KCStore.setItem("customerId", res.data.customerId);
                    // KCStore.setItem("basecountry", res.data.basecountry);
                    // KCStore.setItem("accesscountry", res.data.accesscountry);
                    // KCStore.setItem("roleId", res.data.role);
                    // KCStore.setItem("timeFormat", res.data.userTimeNumberPreferences.length > 0 && res.data.userTimeNumberPreferences[0].preference_value ? parseInt(res.data.userTimeNumberPreferences[0].preference_value) : parseInt("12"));
                    // KCStore.setItem("thousandSeparator", res.data.userTimeNumberPreferences.length > 1 && res.data.userTimeNumberPreferences[1].preference_value ? res.data.userTimeNumberPreferences[1].preference_value : ",");
                    // setcustomerId(res.data.customerId ? res.data.customerId : 1);
                    // setbasecountryId(res.data.basecountry ? res.data.basecountry : "");
                    // setaccessCountryId(res.data.accesscountry ? res.data.accesscountry : "");
                    // KCStore.setItem("user", username);
                    // this.getCustomerID(res.data.customerId, username, res.data.basecountry);
                    // await this.getChildUserEmailList(res.data.customerId, username);
                    // await this.getUserRole(res.data.customerId, username);
                    // resolve(res.data)

                    const validatePermissions = await this.validatePermissions(res.data.features);
                    if (!validatePermissions.unitRegistration) {
                        await this.getUserProfileInformation();
                        $('#confirmationDeniedModal').modal('show');
                        this.setState({ loaderIconVisible: false, showFirstLogin: false })
                    } else {
                        const payLoadData = {
                            'userId': username,
                            "roleId": loginData.role,
                            "customerId": loginData.customerId,
                            "content": "required",
                        }
                        const tandcDetails = await this.checkTandCList(payLoadData);
                        const inputData = {
                            username,
                            from:"selfSingOn",
                            loginData,
                            res,
                            tandcDetails,
                            rememberme,
                            validatePermissions,
                            validatedResponse:this.state
                        }
                        if (tandcDetails?.body?.policyAccepted) {
                            await loginResponseAction(inputData.username,inputData.res, this.props.history.push, this.settingLocale,inputData);
                            await this.registerDevice(res.data, validatePermissions)
                        } else {
                            resolve(this.props.history.push({
                                pathname: "/termsandconditions",
                                inputData: inputData
                            }));
                        }
                    }
                }
                else {
                    reject({
                        res: {
                            data: res.data
                        }
                    });
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    settingLocale = (data) => {
		this.props.setLocale(data)
	}

    checkTandCList = async (postdata) => {
		const url = BaseService.getTandCdetails;
		const headerVal = {
			'Content-Type': 'application/json',
		};		
		try {
			const response = await http.post(url, postdata, { headers: headerVal });
			if (response && response.data) {
				return (response.data);
			}
			return {};
		} catch (err){
			return (err);
		}		
	};

    validatePermissions = (features) => {
        const filteredPermissions = features.filter((i) => i.feature_id === 9 || i.feature_id === 10);
        let permission = {
            'unitRegistration': false,
            'org': false
        };
        for (let i = 0; i < filteredPermissions.length; i++) {
            const ele = filteredPermissions[i];
            if (ele.is_editable === 1) {
                permission[ele.feature_name === 'Organization Management' ? 'org' : 'unitRegistration'] = true
            }
        }
        return permission;
    }

    validateDevice = () => {
        return new Promise((res, rej) => {
            const { jwtToken } = this.state;
            this.setState({ loaderIconVisible: true });
            let url = DeviceRegistration.jwtTokenAuth;
            headers['token'] = jwtToken;
            http.get(url, {
                headers,
                data: {}
            }).then(async response => {
                if (response && response.data) {
                    if (response.data.httpCode === 200) {
                        if (response.data.body && response.data.body.length) {
                            await this.setState({ validatedResponse: response.data.body[0] });
                            res(true);
                        }
                    } else {
                        this.props.history.replace('/register-unit');
                        res(false);
                    }
                }
            }).catch(err => {
                this.props.history.replace('/register-unit');
                res(false);
            });
        })
    }

    triggerEmail = async () => {
        this.setState({ loaderIconVisible: true });
        const { validatedResponse } = this.state;
        let loggedinUser;
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        let postData = {
            "username": loggedinUser,
            "email": loggedinUser,
            "unitId": validatedResponse.SERIALNO
        };

        await http({
            method: "POST",
            url: unitAPI.triggerEmail,
            headers: {
                'Content-Type': 'application/json',
            },
            data: postData
        }).then(response => {
            this.setState({ loaderIconVisible: false });
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    callAllocationAPI = (response) => {
        return new Promise(async (res, rej) => {
            const { validatedResponse } = this.state;
            const postData = {
                "componentId": validatedResponse.COMPONENT_ID || '',
                "customer_id": response.customerId,
                "subscriptionType": "SAAS",
                "allocationType": "UPGRADE",
                "unitId": validatedResponse.SERIALNO || "",
                "chargifyAllocation": {
                    "allocation": {
                        "quantity": 1,
                        "memo": validatedResponse.SERIALNO,
                        "accrue_charge": false,
                        "upgrade_charge": "prorated"
                    }
                }
            };
            await http({
                method: "POST",
                url: DeviceRegistration.allocationApi,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }

    validateOTP = (token, status) => {
        return new Promise(async (res, rej) => {
            let postData = {
                "customer_email": this.state.validateEmail,
                token,
                status
            };
            await http({
                method: "POST",
                url: DeviceRegistration.validateOTP,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }

    generateOTP = (isResend, token) => {
        return new Promise(async (res, rej) => {
            const { locale } = this.props;
            let postData = {
                "customer_email": this.state.validateEmail,
                isResend,
                "status": !isResend ? "Initiated" : "Resend",
                "language_code": locale
            };
            if (isResend) {
                postData['token'] = token
            }
            await http({
                method: "POST",
                url: DeviceRegistration.generateOTP,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                const decodeToken = response && response.data && response.data.customerDetails && response.data.customerDetails.token ? atob(response.data.customerDetails.token) : '';
                const decodeTokenSplit = decodeToken.split('C#@RG!6Y==!');
                this.setState({ emailToken: decodeTokenSplit && decodeTokenSplit.length ? decodeTokenSplit[decodeTokenSplit.length - 1] : '', customerDetails: response.data.customerDetails })
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }

    registerDevice = (response, validatePermissions) => {
        const { validatedResponse } = this.state;
        let loggedinUser;
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;
        const header = {
            'customerid': response.customerId,
            'useremail': loggedinUser,
            'basecountry': BASECOUNTRY_ID
        }
        this.setState({ loaderIconVisible: true });

        let postData = {
            "customerId": response.customerId,
            "brandId": parseInt(validatedResponse.BRANDID),
            "serialNumber": validatedResponse.SERIALNO.toString().replace(/ /g, ''),
            "assetNumber": '',
            "brandName": validatedResponse.BRAND_NAME,
            "productId": validatedResponse.PRODUCTID,
            "inventoryId": validatedResponse.INVENTORY_ID,
            "registrationCode": validatedResponse.REGISTRATION_CODE,
            "requestType": "unitRegisterAndUpdateCustomerBrand",
            "existingBrandsAccess": KCStore.getItem("brandId"),
        };

        http({
            method: "POST",
            url: unitAPI.addUnit,
            headers: header,
            data: postData
        }).then(async responseValue => {
            this.setState({ showFirstLogin: false });
            await this.callAllocationAPI(response);
            if (!validatePermissions.org) {
                await this.triggerEmail()
                $('#confirmationRegisteredModal').modal('show');
            } else {
                $('body').removeClass("deviceRegtrnBg");
                $('body').removeClass("loginBg");
                await this.updateCustBrandAndFeature()
                // this.props.history.push(`/unitRegistration`);
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, showFirstLogin: false });
            this.props.history.replace(`/register-unit`);
        });
    }

    updateSelfsignup = () => {
        return new Promise((res, rej) => {
            const { validatedResponse } = this.state;
            http({
                method: "PUT",
                url: DeviceRegistration.updateSelfSignipSession,
                data: {
                    'session_uuid': validatedResponse.session_uuid || '',
                    'signup_stage': 'SESSION_TERMINATED'
                },
            }).then(async response => {
                res(true);
            }).catch(err => {
                res(false);
            });
        })
    }

    signIn = async () => {
        if (await this.validateDevice()) {
            if (this.validateForm()) {
                this.setState({ loaderIconVisible: true }, async () => {
                    await this.updateSelfsignup();
                    let postData = {
                        username: this.state.email,
                        password: this.state.password,
                    }
                    this.putData(postData, BaseService.login, HTTP_METHOD.POST, false).then(async (response) => {
                        this.setState({ fields: {} });
                        // const validatePermissions = await this.validatePermissions(response.features);
                        // if (!validatePermissions.unitRegistration) {
                        //     await this.getUserProfileInformation();
                        //     $('#confirmationDeniedModal').modal('show');
                        //     this.setState({ loaderIconVisible: false })
                        // } else {
                        //     this.registerDevice(response, validatePermissions)
                        // }
                    }).catch(err => {
                        this.setState({ fields: { emailid: '', password: '' }, loaderIconVisible: false });
                        this.showError(err, postData.username, postData.password);
                        return;
                    });
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false
            });
            this.props.history.replace('/register-unit')
        }
    }

    getBrandUnitImage = (BRAND) => {
        let pickBrand = brandUnits.find(board => board.name.toUpperCase() === BRAND);
        if (pickBrand != null) {
            return pickBrand.img;
        }
        else {
            return require('../../images/unit/MerrychefeikonE2S.svg').default;
        }
    }

    showEmailForm = () => {
        let { validateEmail, errors, jwtToken } = this.state;

        if (typeof validateEmail !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(validateEmail)) {
                errors["validateEmail"] = "Please enter valid email-ID.";
                this.setState({ errors, emailPopup: false });
            } else {
                this.setState({ loaderIconVisible: true }, async () => {
                    if (await this.validateDevice()) {
                        let url = DeviceRegistration.validateEmail;
                        await http.get(url, {
                            headers: {
                                'Content-Type': 'application/json',
                                'email': validateEmail
                            },
                            data: {}
                        }).then(async response => {
                            if (response && response.data && response.data.httpCode === 200) {
                                const verifyObj = KCStore.getItem('verifyObj') ? JSON.parse(KCStore.getItem('verifyObj')) : {};
                                if (verifyObj.email && verifyObj.email === validateEmail && verifyObj.jwtToken && verifyObj.jwtToken === jwtToken) {
                                    this.props.history.push('/select-plan');
                                    return;
                                }
                                await this.generateOTP(false);
                                this.setState({ emailPopup: true, loaderIconVisible: false }, () => {
                                    this.OTPInputFocus();
                                    return;
                                });
                            } else {
                                let { data } = response;
                                let showErrMsg = 'This Email address is already been registered with KitchenConnect.Please signin with this or try with different email.'
                                if (data && data.body && data.body.message  && data.body.message === 'Email id is not valid'){
                                    showErrMsg = "Please enter valid email-ID.";
                                }                                
                                let errors = this.state.errors;
                                errors['validateEmail'] = showErrMsg;
                                this.setState({ errors, loaderIconVisible: false });
                                return;
                            }
                        }).catch(err => {
                            let showErrMsg = "This Email address is already been registered with KitchenConnect.Please signin with this or try with different email.";
                            let { response } = err;                            
                            if (response && response.status === 400) {
                                let { data } = response;                                
                                if (data && data.body && data.body.message  && data.body.message === 'Email id is not valid'){
                                    showErrMsg = "Please enter valid email-ID.";
                                }
                            }
                            let errors = this.state.errors;
                            errors['validateEmail'] = showErrMsg;
                            this.setState({ errors, loaderIconVisible: false });
                        });
                    }else{
                        this.props.history.replace('/register-unit');
                    }
                })

            }
        } else {
            this.setState({ loaderIconVisible: true }, async () => {
                await this.generateOTP(false);
                this.setState({ emailPopup: true, loaderIconVisible: false }, () => {
                    this.OTPInputFocus();
                    return;
                });
            })
        }
    }

    signUp = async () => {
        this.setState({ loaderIconVisible: true });
        const { jwtToken, emailToken, customerDetails } = this.state;
        const value = `${document.getElementById('first').value ? document.getElementById('first').value : ""}${document.getElementById('second').value ? document.getElementById('second').value : ""}${document.getElementById('third').value ? document.getElementById('third').value : ""}${document.getElementById('fourth').value ? document.getElementById('fourth').value : ""}${document.getElementById('fifth').value ? document.getElementById('fifth').value : ""}${document.getElementById('sixth').value ? document.getElementById('sixth').value : ""}`;
        if (value.length === 6) {
            if (Number(value) === Number(emailToken)) {
                let seconds = 0 ;
                if (customerDetails && customerDetails.updatedAt){
                    const currentTime = new Date() / 1000;
                    const otpGeneratedTime = new Date(customerDetails.updatedAt) / 1000;
                    seconds = currentTime - otpGeneratedTime;
                }
                if (seconds < 600) {
                    let errors = this.state.errors;
                    errors['otpIssue'] = '';
                    errors['otpInvalid'] = '';
                    this.setState({ errors });
                    await KCStore.setItem('authorizeJwtToken', jwtToken);
                    await KCStore.setItem("Access-Token", jwtToken);
                    await KCStore.setItem("saas-customer-email", this.state.validateEmail);
                    await this.validateOTP(value, "Verified");
                    if (await this.validateDevice()) {
                        const verifyObj = {
                            jwtToken,
                            email: this.state.validateEmail,
                            "status": "Verified",
                            "token": value
                        }
                        await KCStore.setItem('verifyObj', JSON.stringify(verifyObj));
                        this.props.history.push('/select-plan');
                    } else {
                        this.props.history.replace('/register-unit');
                    }
                } else {
                    if (this.state.isResendClicked) {
                        await this.validateOTP(value, "Expired")
                        this.setState({
                            error_modal: {
                                open: true,
                                message:  'KC2891'
                            },
                            isQRCode: true,
                            loaderIconVisible: false
                        });
                    } else {
                        await this.validateOTP(value, "Expired")
                        let errors = this.state.errors;
                        errors['otpIssue'] = <FormattedMessage id="KC2908"/>;
                        errors['otpInvalid'] = '';
                        this.setState({ errors, loaderIconVisible: false });
                    }
                }
            } else {
                if (this.state.isResendClicked) {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'KC2891'
                        },
                        isQRCode: true,
                        loaderIconVisible: false
                    });
                } else {
                    let errors = this.state.errors;
                    errors['otpIssue'] = <FormattedMessage id="KC2906"/>;
                    errors['otpInvalid'] = '';
                    this.setState({ errors, loaderIconVisible: false });
                }
            }
        } else {
            let errors = this.state.errors;
            errors['otpInvalid'] = <FormattedMessage id="KC2907"/>;
            this.setState({ errors, loaderIconVisible: false });
        }
    }

    updateCustBrandAndFeature = async (val) => {
        let customerId = KCStore.getItem("customerId");
        let roleId = KCStore.getItem("roleId");
        let Urls = userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + customerId + "&is_featurefunction_required=true"
        http.get(Urls,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(response => {
                let rolefeatures = response.data.data.features;
                KCStore.setItem(authConstants.allFeaturesKey, JSON.stringify(rolefeatures));
                let brandIDS = response.data.data.brandIds;
                KCStore.setItem('brandId', JSON.stringify(brandIDS));
                this.setState({ loaderIconVisible: false })
                this.props.history.push(`/unitRegistration`);
            }).catch(err => {

            });
    }

    OTPInputFocus = () => {
        const inputs = document.querySelectorAll('#otp > *[id]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keydown', function (event) {
                if (event.key === "Backspace") {
                    inputs[i].value = '';
                    if (i !== 0) inputs[i - 1].focus();
                }
                else {
                    if (i === inputs.length - 1 && inputs[i].value !== '') { return true; }
                    else if (event.keyCode > 47 && event.keyCode < 58) {
                        inputs[i].value = event.key;
                        if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault();
                    }
                    else if (event.keyCode > 64 && event.keyCode < 91) {
                        inputs[i].value = String.fromCharCode(event.keyCode);
                        if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault();
                    }
                }
            });
        }
    };

    getUserProfileInformation = () => {
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        if (!loginUser)
            return this.props.history.push("/");

        let loggedinUser = loginUser.username;
        this.setState({ emailfromTokan: loggedinUser, loaderIconVisible: true, });
        http({
            method: "GET",
            url: UserProfile.getCustomerAdmin + KCStore.getItem("customerId"),
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(response => {
            this.setState({
                userData: response.data
            }, () => { })
            this.setState({
                loaderIconVisible: false,
            });
        }).catch(err => {
            this.setState({ loaderIconVisible: false, });
        });
    }

    accessNowModel = () => {
        this.setState({
            access_modal: {
                open: false
            }
        })

        let postData = {
            "type": "request-login-access",
            "user": {
                "email": this.state.username
            }
        }

        http({
            method: "POST",
            url: UserManagement.requestAccess,
            data: postData,
        }).then(response => {
            let { status } = response;
            if (status === 200) {
                this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: "Email notification sent to admin."
                    },
                });
            }
        })
    }

    accessLaterModel = () => {
        this.setState({
            access_modal: {
                open: false
            }
        })
        this.props.history.push('/');
    }

    createPassword = (e) => {
        this.setState({
            loaderIconVisible: true
        });
        let username = this.state.fields.emailid;
        let userPassword = this.state.fields.newpassword;

        if (this.validateSignupForm()) {
            let fields = {};
            this.setState({ fields: fields });
            let postData = {
                username: this.state.username,
                oldPassword: this.state.password,
                newPassword: this.state.fields.newpassword
            };

            http({
                method: "PUT",
                url: BaseService.first_login,
                data: postData,
            }).then(async res => {
                let { data } = res;
                let { statusCode } = data;
                if (statusCode === 200) {
                    let postDataLogin = {
                        username: this.state.username,
                        password: userPassword,
                    }
                    this.putData(postDataLogin, BaseService.login, HTTP_METHOD.POST, false).then(async (response) => {
                        this.setState({ fields: {} });
                        const validatePermissions = await this.validatePermissions(response.features);
                        if (!validatePermissions.unitRegistration) {
                            await this.getUserProfileInformation();
                            $('#confirmationDeniedModal').modal('show');
                            this.setState({ loaderIconVisible: false, showFirstLogin: false })
                        } else {
                            this.registerDevice(response, validatePermissions)
                        }
                    }).catch(err => {
                        this.setState({ fields: { emailid: '', password: '' }, loaderIconVisible: false, showFirstLogin: false });
                        this.showError(err, postData.username, postData.password);
                        return;
                    });
                }
            }).catch(err => {
                this.showSignupError(err);
            });
        } else {
            this.setState({
                loaderIconVisible: false
            });
        }
    }

    validateSignupForm = () => {

        let fields = this.state.fields;
        let oldPasswordCheck = this.state.password;
        let errorOldPassword = "";
        let errorNewPassword = "";
        let errorRetypeNewPassword = "";
        let formIsValid = true;

        if (!fields["newpassword"]) {
            formIsValid = false;
            errorNewPassword = "Please enter your new password.";
        }
        else if (!fields["newpassword"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?_+;.,:><|/]).*$/)) {
            formIsValid = false;
            errorNewPassword = "New Password should be minimum of 8 characters and contain at least one lowercase, uppercase, numeric and special characters.";
        }

        if ((errorNewPassword == "") && (oldPasswordCheck === fields["newpassword"])) {
            formIsValid = false;
            errorNewPassword = "New password should not be same as temporary password.";
        }

        if (!fields["retypenewpassword"]) {
            formIsValid = false;
            errorRetypeNewPassword = "Please enter your retype new password.";
        }

        if ((errorNewPassword == "" && errorRetypeNewPassword == "") && (fields["newpassword"] != fields["retypenewpassword"])) {
            formIsValid = false;
            errorRetypeNewPassword = "Retype new password should match with new password.";
        }

        this.setState({
            errorNewPassword: errorNewPassword,
            errorRetypeNewPassword: errorRetypeNewPassword
        });
        return formIsValid;
    }

    rememberMeCheckboxChange = (e) => {
        this.setState({
            rememberme: e.currentTarget.checked
        });
    };

    showSignupError = (err) => {
        let { response } = err;
        let postData = {
            username: this.state.fields.emailid,
            password: this.state.fields.password,
            newpassword: this.state.fields.newpassword
        }
        if (response) {
            let { data } = response;
            let { statusCode } = data;
            let fields = {};
            let inputdataemailobj = this.state.fields.emailid;
            let inputdatapwdobj = this.state.fields.password;
            let inputdatanewpwdobj = this.state.fields.newpassword;
            if (data) {
                this.setState({
                    error_modal: {
                        open: true,
                        message: "KC0020"
                    },
                    fields: {
                        ...this.state.fields,
                        emailid: '',
                        password: '',
                        newpassword: '',
                        retypenewpassword: ''
                    },
                    loaderIconVisible: false

                });
                let fields = {};
            }
        }
        else {
            this.setState({
                success_modal: {
                    open: false,
                    message: `You successfully created your personal password! Please Log in`
                },
                loaderIconVisible: false,
                showFirstLogin: false
            });
        }
    }

    onFLSuccessCloseModal = () => {
        this.setState({
            fl_home_success_modal: {
                open: false
            }
        })
        if (this.state.goToConsumer) {
            this.setState({ goToConsumer: false })
            this.props.history.push('/consumer')
        } else {
            this.props.history.push('/');
        }
    }

    ValidateinputField = (e) => {
        let controlid = e.target.name;
        let errorOldPassword = "";
        let errorNewPassword = "";
        let errorRetypeNewPassword = "";

        if (e.target.name != null) {

            if (controlid === "password") {
                this.setState({ errorOldPassword: errorOldPassword });
            }
            if (controlid === "newpassword") {
                this.setState({ errorNewPassword: errorNewPassword });
            }
            if (controlid === "retypenewpassword") {
                this.setState({ errorRetypeNewPassword: errorRetypeNewPassword });
            }
        }
    }

    removeSpacesOnChange = (e) => {
        var keyCode = e.which || e.keyCode;
        if (keyCode === 32) {
            e.preventDefault();
            return false;
        }
    }

    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    toggleOldPwd = () => {
        this.setState({
            showhideoldpassword: !this.state.showhideoldpassword,
            oldpwdtype: this.state.oldpwdtype === 'password' ? 'text' : 'password'
        })

    }

    resendAction = async () => {
        this.setState({ loaderIconVisible: true })
        let errors = this.state.errors;
        errors['otpIssue'] = '';
        errors['otpInvalid'] = '';
        await this.generateOTP(true, this.state.emailToken);
        document.getElementById('first').value = '';
        document.getElementById('second').value = '';
        document.getElementById('third').value = '';
        document.getElementById('fourth').value = '';
        document.getElementById('fifth').value = '';
        document.getElementById('sixth').value = '';

        this.setState({ errors, isResendClicked: true, loaderIconVisible: false });
    }

    toggleNewPwd = () => {
        this.setState({
            showhidenewpassword: !this.state.showhidenewpassword,
            newpwdtype: this.state.newpwdtype === 'password' ? 'text' : 'password'
        })
    }

    toggleRetypeNewPwd = () => {
        this.setState({
            showhideretypenewpassword: !this.state.showhideretypenewpassword,
            retypenewpwdtype: this.state.retypenewpwdtype === 'password' ? 'text' : 'password'
        })
    }

    /**
     * To remove the jwt and auth token when navigating to password reset screen
     * User Story 58183: Registration-unit page shows sign-in / sign-up option, ( sign-in needs a forget password link to reset password or information about it)
     */
    forgetPassword = () => {
        this.props.history.push(`/ForgotPassword`);
        KCStore.removeItem("authorizeJwtToken");
        KCStore.removeItem("Access-Token");
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible, validatedResponse, isPageLoad, email, errors, password, error_modal, success_modal, userData, access_modal, fl_success_modal, fl_home_success_modal, showFirstLogin, isFormValidated } = this.state;
        let getmailID;
        if (userData && userData.length > 0) {
            getmailID = userData[0].customer_admin_user_email;
        }

        if (!isPageLoad) {
            return (<div id="page-wrapper">
                <div className="underConstruction">
                    <div className="underConstructionImg"></div>
                    <h3>You are not authorized to view this page.</h3>
                    <div className="clear"></div>
                </div>
            </div>)
        }
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        const msgDesc = ` <h5>Denied</h5>
                    <p>You don't have permission to register new devices, please contact your 
                    administrator for authorization.</p><br>`
        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" >&nbsp;</a>
                    <div id="login-pre">
						<LanguagePreferenceCommon/>
					</div>
                </nav>
                <div id="page-content-wrapper">
                    <div className="unitRegistrationWrapper">
                        <div className="unitRegFormWrapper">
                            <div className="unitRegSuccess">
                                <div className="unitRegThanksCnt">
                                    <img src={this.getBrandUnitImage(validatedResponse && validatedResponse.BRAND_NAME ? validatedResponse.BRAND_NAME.toUpperCase() : '')} alt={validatedResponse.BRAND_NAME || ''} title={validatedResponse.brand || ''} />
                                    <FormattedMessage id='KC2904' /> <b> {validatedResponse && validatedResponse.BRAND_NAME ? validatedResponse.BRAND_NAME : ''} {validatedResponse && validatedResponse.MODEL_NAME ? validatedResponse.MODEL_NAME : ''}</b> <FormattedMessage id='KC2905' />
                                </div>
                                <div className="signinupDiv">
                                    <div className="signInDiv">
                                        <h6><FormattedMessage id='KC0891' /></h6>
                                        <div className="existingUSignInForm">
                                            <ul>
                                                <li className="form-group">
                                                    <div className="input-group">
                                                        <FormattedMessage id='KC0894'>
                                                            {placeholder => <input className="form-control" autocomplete="off" placeholder={placeholder} type="text" value={email} onChange={(e) => this.setState({ email: e.target.value.toLowerCase() })} onBlur={this.emailblurevent} />}
                                                        </FormattedMessage>
                                                        {errors["emailid"] && <div className="errorMsg">{errors.emailid}</div>}
                                                    </div>

                                                </li>
                                                <li className="form-group pass">
                                                    <div className="input-group">
                                                        <FormattedMessage id='KC0895'>
                                                            {placeholder => <input className="form-control pwd" type={this.state.type} placeholder={placeholder} value={password} onChange={(e) => this.setState({ password: e.target.value })} onBlur={this.passwordblurevent} />}
                                                        </FormattedMessage>
                                                        <span className="input-group-btn" onClick={this.togglePwd}>
                                                            <button className="btn input-group-addon reveal" type="button"><i className={this.state.showhidepassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
                                                        </span>
                                                        {errors["password"] && <div className="errorMsg">{errors.password}</div>}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" disabled={email === '' && password === ''} className="btn btn-default signIn" onClick={this.signIn}><FormattedMessage id='KC0013'/></button> <Link className="forgotpassword resendCodeBtn" id="ForgotPassword" to="/ForgotPassword" title="Forgot Password" onClick={() => this.forgetPassword()}><FormattedMessage id='KC0002'/></Link>
                                    </div>
                                    {!this.state.emailPopup ? <div className="signUpDiv">
                                        <h6><FormattedMessage id='KC0892' /></h6>
                                        {/* <p><FormattedMessage id='KC0893' /></p> */}
                                        <h5><FormattedMessage id='KC2604' /></h5>
                                        <div className="existingUSignInForm">
                                            <ul>
                                                <li className="form-group">
                                                    <div className="input-group">
                                                        <input className="form-control" placeholder={formatMessage({ id: 'KC0007' })} type="email" value={this.state.validateEmail} onBlur={this.emailValidateblurevent} onChange={(e) => this.setState({ validateEmail: e.target.value.toLowerCase() })} />
                                                        <div className="errorMsg">{this.state.errors.validateEmail ? <FormattedMessage id="KC0018"/> : ""}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className={this.state.validateEmail && this.state.validateEmail !== "" ? "btn btn-default" : "btn btn-default disabled"} onClick={() => this.showEmailForm()}><FormattedMessage id='KC0023' /></button>
                                    </div> :
                                        <div className="signUpDiv">
                                            <h6><FormattedMessage id="KC2605"/></h6>
                                            <h4><FormattedMessage id="KC2606"/></h4>
                                            <h5>{this.state.validateEmail}</h5>
                                            <div className="existingUSignInForm">
                                                <ul>
                                                    <li className="form-group">
                                                        <div className="input-group">
                                                            <div id="otp" className="otpForm d-flex flex-row justify-content-center mt-2">
                                                                <input className="m-2 form-control" type="number" id="first" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                                <input className="m-2 form-control" type="number" id="second" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                                <input className="m-2 form-control" type="number" id="third" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                                <input className="m-2 form-control" type="number" id="fourth" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                                <input className="m-2 form-control" type="number" id="fifth" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                                <input className="m-2 form-control" type="number" id="sixth" maxlength="1" onInput={(e) => e.target.value = e.target.value.slice(0, 1)}/>
                                                            </div>
                                                        </div>
                                                        {errors['otpIssue'] && errors['otpIssue'] !== '' ? <div className="required">{errors['otpIssue']}</div> : null}
                                                        {errors['otpInvalid'] && errors['otpInvalid'] !== '' ? <div className="required">{errors['otpInvalid']}</div> : null}
                                                    </li>
                                                </ul>
                                            </div>
                                            <button type="button" className="btn btn-default" disabled={errors['otpIssue'] && errors['otpIssue'] !== '' && this.state.isResendClicked} onClick={this.signUp}><FormattedMessage id='KC0558' /></button>
                                            {!this.state.isResendClicked ? <button type="button" className="btn resendCodeBtn" onClick={() => this.resendAction()}><FormattedMessage id='KC2607' /></button> : null}
                                        </div>}
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for Error Message */}
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationDeniedModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeniedModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationContentDiv" >
                                    <div dangerouslySetInnerHTML={{ __html: msgDesc }}></div>
                                    <Mailto email={getmailID}><div className="email">{loginUser && loginUser.userName ? loginUser.userName : ''} KitchenConnect Admin</div></Mailto>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary errorModalContentOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0012"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for Error Message End */}

                {/* <!-- Registered Modal --> */}
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationRegisteredModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRegisteredModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationContentDiv">
                                    <h5><FormattedMessage id='KC0655' /></h5>
                                    <p>The Unit has been registered and a notification has been sent to {loginUser && loginUser.userName ? loginUser.userName : ''} KitchenConnect Admin.</p>
                                    <h6>Thanks for your trust in KitchenConnect. </h6>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary finishBtn" data-dismiss="modal" onClick={() => { $("div").removeClass("modal-backdrop fade show"); this.props.history.replace('/register-unit') }} type="button"><FormattedMessage id='KC0056' /></button>
                                <button className="btn btn-secondary gotoDashboardBtn" onClick={() => { $("div").removeClass("modal-backdrop fade show"); this.props.history.push('/dashboardLocations') }} type="button">Go to Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- SignIn Modal -->  */}
                <div className={showFirstLogin ? "modal fade showFirstLogin show" : "modal fade"} data-keyboard="false" data-backdrop="static" id="SignInModal" tabIndex="-1" role="dialog" aria-labelledby="SignInModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content">
                            <div className="modal-header loginModalHeader modal-header-danger">
                                Sign Up <Link to="/" className="firstLoginClose" data-dismiss="modal" aria-label="Close">&nbsp;</Link>
                            </div>
                            <div className="modal-body paddingTop0px">
                                <div className="loginForm">
                                    <ul>
                                        <li className="form-group marginBottom10px">
                                            <input type="text" name="emailid" className="form-control disabled" placeholder="Enter email" defaultValue={this.state.username} value={this.state.username} onChange={this.handleChange} onBlur={this.emailblurevent} />
                                            <div className="errorMsg">{this.state.errors.emailid}</div>
                                        </li>
                                        <li className="form-group pass">
                                            <div className="input-group">
                                                <input className="form-control pwd disabled" name="password" placeholder="Old password" defaultValue={this.state.password} value={this.state.password} type={this.state.oldpwdtype} onChange={this.handleChange} onBlur={this.passwordblurevent} />
                                                <div className="errorMsg">{this.state.errors.password}</div>
                                                <span className="input-group-btn" onClick={this.toggleOldPwd}>
                                                    <button className="btn input-group-addon reveal" type="button"><i className={this.state.showhideoldpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
                                                </span>
                                            </div>
                                        </li>

                                        <li className="form-group pass">
                                            <div className="input-group">
                                                <input className="form-control pwd" id="password2" name="newpassword" placeholder="New password" onKeyPress={(e) => this.removeSpacesOnChange(e)} value={this.state.fields.newpassword} type={this.state.newpwdtype} onChange={this.handleChange} onBlur={(e) => this.ValidateinputField(e)} />
                                                <div className="errorMsg">{this.state.errorNewPassword}</div>
                                                <span className="input-group-btn" onClick={this.toggleNewPwd}>
                                                    <button className="btn input-group-addon reveal" type="button"><i className={this.state.showhidenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
                                                </span>
                                            </div>
                                        </li>
                                        <li className="form-group pass">
                                            <div className="input-group">
                                                <input className="form-control pwd" id="password2" name="retypenewpassword" onKeyPress={(e) => this.removeSpacesOnChange(e)} placeholder="Retype new password" value={this.state.fields.retypenewpassword} type={this.state.retypenewpwdtype} onChange={this.handleChange} onBlur={(e) => this.ValidateinputField(e)} />
                                                <div className="errorMsg">{this.state.errorRetypeNewPassword}</div>
                                                <span className="input-group-btn" onClick={this.toggleRetypeNewPwd}>
                                                    <button className="btn input-group-addon reveal" type="button"><i className={this.state.showhideretypenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
                                                </span>
                                            </div>
                                        </li>
                                        <li className="form-group pass">
                                            <div className="customCheckbox customCheckboxWlabel">
                                                <input id="FLRememberMe" name="RememberMe" type="checkbox" value={this.state.rememberme} />
                                                <label htmlFor="FLRememberMe">Remember Me</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter firstLoginBtn">
                                <button className="btn btn-secondary singINBtn" type="button" id="SignInButton" onClick={(e) => this.createPassword(e)}>Sign Up</button>
                            </div>
                        </div>
                        
                        <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                        <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                        <FLSuccessMoalBackToHome open={fl_home_success_modal.open} message={fl_home_success_modal.message} onFLSuccessModel={this.onFLSuccessCloseModal} />
                    </div>
                </div>
                {/* <!-- //SignIn Modal -->
			    <!-- SignIn Confirmation Modal --> */}
                <div className="modal fade" data-keyboard="false" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="singINConfirmation">
                                    You successfully created your personal password!
                                </div>


                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary signINconfirmationOKBtn" type="button">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Registered --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <FLSuccessMoal open={fl_success_modal.open} message={fl_success_modal.message} onCloseModel={this.closeModel} />
                <AccessModal open={access_modal.open} message={access_modal.message} onAccessNowModel={this.accessNowModel} onAccessLaterModel={this.accessLaterModel} />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        locale: state.localeInfo.locale,
    }
}

export default connect(mapStateToProps)(injectIntl(AddUnitSuccess));
