import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../components/controls/viewTopology';
import { v1 as uuidv1 } from 'uuid';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
class AssignLegacyMenuToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    componentWillMount() {
        let { srcfile, previouspath, uploadOption, upload_filename, description, selectedModelFamilyID } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID
		});
    }

    onBackClickHandler = () => {
        const {srcfile, uploadOption, upload_filename, description, selectedModelFamilyID} = this.state;
        this.props.history.push({
            pathname: "/UploadSelectDevice",
            srcfile: srcfile,
            uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID,
            isEdit: false,
            previouspath: "/assignLegacyMenuToTopology"
        });
    }

    submitAddLibrary = async(e,_customId) => {
        e.preventDefault();
        let _this = this;
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        
        if (!libraryDetails && !libraryDetails.libraryId) {
            _this.props.history.push(`/libraryList`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            await http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customerid': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'basecountry': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {                
                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryDetails.libraryId,
                    "libraryName": libraryDetails.fileName,
                    "libraryFileName": libraryDetails.libraryId + "." + libraryDetails.fileFormat,
                    "libraryFileFormat": libraryDetails.fileFormat,
                    "libraryFileSize": libraryDetails.fileSize,
                    "libraryS3Url": libraryDetails.fileS3Url,
                    "type": "Legacy",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": libraryDetails.crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": "",
                    "imageS3Url": "",
                    "cgids": _customId,
                    "json": "{}",
                    "recipeResources": [],
                    "libraryGuid": uuidv1(),
                    "description": libraryDetails.description
                };

                await http({
                    method: "POST",
                    url: MenuUploadAPI.getLibraryList,
                    data: libraryPostData,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    let { data } = res;
                    let { httpCode } = data;
                    if (httpCode === "HTTP_200") {
                        _this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "KC1401"
                            },
                        });
                    }
                    else {
                        _this.setState({
                            loaderIconVisible: false
                        })
                    }
                }).catch(err => {
                    _this.setState({
                        loaderIconVisible: false
                    })
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    static getDerivedStateFromProps = (props) => ({...props});

    render() {
        let { success_modal, error_modal } = this.state;
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={formatMessage({ id: 'KC2262' })} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignLegacyMenuToTopology));