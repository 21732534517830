import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { headerRow } from './plan';
import { BaseService, subscriptionManagement } from '../../service/api';
import http from '../../service/httpService';
import KCStore from '../../utils/kcStorage';

const ManageAccount = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [isActive, setIsActive] = useState(KCStore.getItem("chargifySubscriptionStatus") ? KCStore.getItem("chargifySubscriptionStatus") !== 'Inactive' : false);

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        return () => {
        }
    }, []);

    const getCustomerGroupdet = async () => {
        await http.get(BaseService.customer_Group_det,
            {
                headers: {
                    'customer_id': KCStore.getItem("customerId"),
                    'Content-Type': 'application/json'
                }, data: {},
            }).then(async response => {
                KCStore.setItem("chargifySubscriptionStatus", response.data.chargifySubscriptionStatus || 'Inactive');
                KCStore.setItem("subscriptionId", KCStore.getItem('subscriptionId') !== "" ? KCStore.getItem('subscriptionId') : response.data.subscriptionDetails.chargify_subscription_id ? response.data.subscriptionDetails.chargify_subscription_id : "");
                KCStore.setItem("subscriptionPlan", response.data.subscriptionDetails.kc_subscription_plan_id ? response.data.subscriptionDetails.kc_subscription_plan_id : "");
                if (response?.data.chargifySubscriptionStatus) {
                    setIsActive(response?.data.chargifySubscriptionStatus !== "Inactive" ? true : false)
                }
                setLoaderIconVisible(false);
            }).catch(err => {
                setLoaderIconVisible(false);
            });
    }

    const putActiveDeactive = () => {
        setLoaderIconVisible(true);
        let token = KCStore.getItem("Access-Token");
        let loggedinUser;
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;
        const reqData = {
            activationStatus: isActive ? "INACTIVE-ONLINE" : "ACTIVE",
            cancelSubscription: true,
        };
        return new Promise((resolve, reject) => {
            http.put(subscriptionManagement.updateSubscription, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'customerid': KCStore.getItem("customerId"),
                        'useremail': loggedinUser,
                    }
                }
            )
                .then(function (response) {
                    getCustomerGroupdet();
                    resolve(response);
                }).catch(function (err) {
                    setLoaderIconVisible(false);
                    $('#paymentFaileddModal').modal('show');
                    reject(err);
                });
        });
    }


    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Manage Account')}
                        </ul>
                    </div>
                    <div className="tableSMViewW">
                        <div className="accountSubscription">
                            <h2><FormattedMessage id='KC2480' /></h2>
                            <h3><FormattedMessage id='KC2481' /></h3>

                            {isActive ?
                                <div className="buttonWrapper"><div className="subscriptionactiveS"><FormattedMessage id='KC2482' values={{ status: 'active' }} /></div> <button type="button" className="btn turnOnOffSBtn" data-toggle="modal" data-target="#turnOffSModal"><FormattedMessage id='KC2483' values={{ value: 'Off' }} /></button></div>
                                :
                                <div className="buttonWrapper"><div className="subscriptionInactiveS"><FormattedMessage id='KC2482' values={{ status: 'inactive' }} /></div> <button type="button" className="btn turnOnSBtn" data-toggle="modal" data-target="#turnOnSModal"><FormattedMessage id='KC2483' values={{ value: 'On' }} /></button></div>}
                        </div>
                    </div>
                    <div className="tableSMViewW">
                        <div className="accountSubscription">
                            <h2><FormattedMessage id='KC0198' /> &amp; <FormattedMessage id='KC0004'/></h2>
                            <h3><FormattedMessage id='KC2506' /></h3>

                            <div className="buttonWrapper"><a href="mailto:Support@welbilt.com" className="subscriptionEmailS">Support@welbilt.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Turn-off account subscription --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="turnOffSModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title warningInfo"><FormattedMessage id='KC2476' /></div>
                                <p><FormattedMessage id='KC2477' /></p>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmYes" data-dismiss="modal" type="button" onClick={putActiveDeactive}><FormattedMessage id='KC0611' /></button>
                            <button className="btn confirmNo" data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Turn-off account subscription -->
	<!-- Turn-on account subscription --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="turnOnSModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title warningInfo"><FormattedMessage id='KC2478' /></div>
                                <p><FormattedMessage id='KC2479' /></p>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                            <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={putActiveDeactive}><FormattedMessage id='KC0611' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Turn-on account subscription --> */}
            {/* <!-- Payment details update failed --> */}
            <div class="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="paymentFaileddModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="confirmMContententD">
                                <div class="h2Title failedInfo"> <FormattedMessage id='KC2504' values={{ value: isActive ? 'deactivate' : 'activate' }} /></div>
                                <p><FormattedMessage id='KC2505' values={{ value: isActive ? 'deactivate' : 'activate' }} /> </p>
                            </div>
                        </div>
                        <div class="modal-footer textAlignCenter">
                            <button class="btn confirmnBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0777' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Payment details update failed --> */}
            <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" />
        </div >
    )
};


export default injectIntl(ManageAccount)