import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import DragAndDropUpload from '../../controls/DragAndDropUpload';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CUSTOMER_ID, BRAND_ID, RESTRICTED_CHAR, restrictedCharAvl } from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { BaseService, MenuUploadAPI, unitAPI } from '../../../service/api';
import axios from 'axios';
import KCStore from '../../../utils/kcStorage';
var AdmZip = require('adm-zip');
let md5 = require('md5');

class MenuLibraryUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			loaderUploadIconVisible: false,
			Uploadedfile: {},
			srcfile: {},
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			UpdateNow: true,
			ScheduleUpdate: false,
			brandId: 0,
			startDate: "",
			Scheduledtime: "",
            characterCount: 250,
			default_Count: 250,
            upload_filename: "",
            errorUploadFile: "",
            errorUploadFileName: "",
			descriptionErrorMsg: "",
            description: "",
            uploadOption: "",
			isNextEnabled: false,
			fileExtFiled: {},
			fileExtension: ''
		};
		this.UploadmenuFile = this.UploadmenuFile.bind(this);
		this.MenuUploadFinish = this.MenuUploadFinish.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.onUploadNameChange = this.onUploadNameChange.bind(this);
		this.getModelFamilies = this.getModelFamilies.bind(this);
	}

	componentDidMount = async () => {
		document.body.style.backgroundColor = "#F2F2F2";
		$('div').removeClass("modal-backdrop fade show");
		$('.browseFile').click(function () { $('#browseUpdate').trigger('click'); });
		try {
            await this.getModelFamilies().catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        } catch (error) {
            //Error Goes Here
        }
	}

    componentWillMount() {
        let { srcfile, previouspath, uploadOption, upload_filename, description, selectedModelFamilyID } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
			selectedModelFamilyID: selectedModelFamilyID
		});
    }
    
	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	getModelFamilies = () => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(unitAPI.getModelFamilies,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': BRAND_ID
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let modelList = data && data.body ? data.body : [];
                    this.setState({
                        loaderIconVisible: false,
                        data: modelList.filter(m => m.MODEL_FAMILY_ID !== 3 && m.MODEL_FAMILY_ID !== 4 && m.MODEL_FAMILY_ID !== 5)
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    onUploadNameChange = (e) => {
		let restrictedChar = RESTRICTED_CHAR.split('').some(i => restrictedCharAvl(i,e.target.value));
        if (restrictedChar || e.nativeEvent.data === '\\'){
            return;
        }
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                upload_filename: e.target.value,
                errorUploadFileName: ""
            });
        // }
	}

    // Check the json is valid or not
	isValidJson(json) {
		try {
			JSON.parse(json);
		} catch (e) {
			return false;
		}
		return true;
	}

	checkJSONExist = async (json) => {
		let jsonExist = false;
		await json.forEach((jsonObj) => {
			if (jsonObj.entryName.includes('json') && !jsonObj.entryName.includes('/')) {
				jsonExist = true;
			}
		})
		return jsonExist;
	}

    // Check the unique field is present or not to identify the correct json file
	uniqueKeyFind = (brand, type) => {
		if (brand === "MERRYCHEF") {
			if (type === 'CookBook') {
				return 'menus'
			} else if (type === 'Settings') {
				return 'mode'
            } else if (type === 'Recipe') {
				return 'ordered_steps'
			} else {
				return 'available_components'
			}
		}
		if (brand === "FRYMASTER") {
			if (type === 'CookBook') {
				return 'autoMenuChange'
			} else if (type === 'Settings') {
				return 'general'
            } else if (type === 'Recipe') {
				return 'conditional_steps'
			} else {
				return 'available_components'
			}
		}
	}

    onDragOver(e) {
		console.log('onDragOver', e);
	}

	handleDrop = async(files) => {
		this.setState({
			loaderUploadIconVisible: true
		});
        const { validEntries } = await this.validateFile(files);
		$("#browseUpdate").val('');
	}

	changeFileExt(fileName, newExt) {
		let position = fileName.includes(".") ? fileName.lastIndexOf(".") : fileName.length
		let fileRoot = fileName.substr(0, position)
		let changedFilename = `${fileRoot}.${newExt}`
		return changedFilename
	  }

    validateFile = async(files) => {
        return new Promise((resolve, reject) => {
        let validEntries = false;
        let type = "";
        if (files && files[0]) {
			let fileName = files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileextSplit = fileName.split('.');
			let fileNamewithOutExt = fileextSplit[0];
			let fileext = fileextSplit[1];
			let fileSize = files[0].size;
			fileSize = (fileSize / 1048576).toFixed(2);
            let srcfile = files[0];

			if (fileext === "cbr" || fileext === "CBR" || (KCStore.getItem("brandName") === "FRYMASTER" &&  (fileext === "ini" || fileext === "INI"))) {
				// Redirect to file
				this.handleCbrFileType(srcfile, fileNamewithOutExt, fileext, fileSize);
			} else {
				if (fileext === "zip" || fileext === "ZIP" || fileext === "mccb" || fileext === "MCCB") {
					try {
						let renameFileFormat;
						this.setState({fileExtFiled : srcfile, fileExtension : fileext});
						if(fileext === "mccb" || fileext === "MCCB") {
							let updatedName = this.changeFileExt(fileName, 'zip');
							renameFileFormat = new File([files[0]], updatedName, {type: 'application/x-zip-compressed'});
							srcfile = renameFileFormat;
						}
						var reader = new FileReader();
						reader.readAsDataURL(srcfile);
						reader.onload = async (e) => {
							let fileBuffer = Buffer.from(e.target.result.replace(/^data:application\/x-zip-compressed;base64,/, "").replace(/^data:application\/zip;base64,/, ""), "base64");
							var zip = new AdmZip(fileBuffer);
							var zipEntries = zip.getEntries();
							let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");

                            validzipEntries.forEach((entry, i) => {
								if (entry.entryName.includes('manifest')) {
                                    let manifestBuffer = zip.readFile(entry);
									if (this.isValidJson(manifestBuffer)) {
										let manifestJSON = JSON.parse(manifestBuffer);
										if (manifestJSON.available_components['menus'] && manifestJSON.available_components['settings']) {
											type = "Package";
										}
										else if (manifestJSON.available_components['menus']) { 
											type = "CookBook";
										}
										else {
											type = "Settings";
										}
									}
                                }
							});

							validzipEntries.forEach((entry) => {
                                if (!entry.entryName.includes('manifest')) {
                                    // If the file is json, then need to validate and find the correct file
                                    if (entry.entryName.includes('json')) {
                                        const file = zip.readFile(entry);
										if (this.isValidJson(file)) {
											const fileJSONObj = JSON.parse(file);
											// JSON validation
											if (JSON.stringify(fileJSONObj)) {
												// Get unique key for the selected type and brand
												const uniqueField = this.uniqueKeyFind(KCStore.getItem("brandName"), type);
												// Check if the unique key is present in the JSON
												if (type === "Package") {
													validEntries = true;
												}
												else if (type === "CookBook" && KCStore.getItem("brandName") === 'MERRYCHEF') {
													validEntries = false;
												}
												else if (fileJSONObj[uniqueField]) {
													validEntries = true;
												}
												else {
													validEntries = false;
												}
											}
										}
                                    }
                                }
							})
                            if (validEntries) {
                                this.setState({
                                    srcfile: srcfile,
                                    actionType: 'Upload',
                                    uploadOption: type,
                                    errorUploadFile: "",
									loaderUploadIconVisible: false
                                })
                            }
                            else {
                                this.setState({
                                    errorUploadFile: "KC1178",
									uploadOption: "",
									loaderUploadIconVisible: false
                                })
                            }
						}
					}
					catch (ex) {
						this.openErrorModal("KC1178");
						this.setState({
							loaderUploadIconVisible: false
						})
					}
                    resolve({validEntries, type});
				} else {
					this.setState({
						errorUploadFile: "KC1178",
						uploadOption: "",
						loaderUploadIconVisible: false
                    })
                    reject('KC1178');
				}
			}
		}
    })
    }

	UploadmenuFile = async(event) => {
		this.setState({
			loaderUploadIconVisible: true
		});
        const { files } = event.target;
		const{ validEntries } = await this.validateFile(files);
		$("#browseUpdate").val('');
	}

	MenuUploadFinish = (e) => {
        const {srcfile, uploadOption, upload_filename, description, selectedModelFamilyID} = this.state;
		let descriptiontext;
		let uploadfilename = upload_filename.trim();
		if(description != undefined){
			descriptiontext = description.trim();
		}
		if (!srcfile) {
			this.setState({
				errorUploadFile: "KC1178"
			});
		}
		else if (!uploadfilename) {
			this.setState({
				errorUploadFileName: "KC2107"
			});
		}
		else if (description != undefined && descriptiontext.length > 250) {
			this.setState({
				descriptionErrorMsg: "KC2265"
			});
		}
		else {
			this.props.history.push({
				pathname: "/uploadSelectDevice",
				srcfile: srcfile,
				uploadOption: uploadOption,
				upload_filename: uploadfilename,
				description: descriptiontext,
				selectedModelFamilyID: selectedModelFamilyID,
				previouspath: "/menuLibraryUpload"
			});
		}
	}

	handleCbrFileType(files, fileNamewithOutExt, fileext, fileSize) {
		var reader = new FileReader();
		reader.readAsDataURL(files);
		reader.onload = async (e) => {
			let fileBuffer = Buffer.from(e.target.result.replace(/^data:application\/octet-stream;base64,/, ""), "base64");
			let library_id = (fileNamewithOutExt + '_' + Date.now()).replace(/\s+/g, '');
			let menufilekey = library_id + '.' + fileext;
			let headerpresigned = {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'imagefilekey': "",
				'jsonfilekey': "",
				'audiofilekey': "",
				'menufilekey': menufilekey,
				'isthumbnail': false
			};
			let token = KCStore.getItem('Access-Token');
			headerpresigned.Authorization = `Bearer ${token}`;
			await axios({
				url: `${BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl}`,
				method: "GET",
				headers: headerpresigned,
				data: {}
			}).then(async (response) => {
				let { menuPresignedURL } = response.data.presignedURLs;

				let UploadedMenu3url = "";
				if (menuPresignedURL) {
					UploadedMenu3url = menuPresignedURL.split('?');
					UploadedMenu3url = UploadedMenu3url[0];
				}

				var xhrimage = new XMLHttpRequest();
				xhrimage.open("PUT", menuPresignedURL);
				xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrimage.onloadend = async (res) => {
					if (await res.target.status == 200) {
						let fileData = {};
						fileData.fileS3Url = UploadedMenu3url;
						fileData.libraryId = library_id;
						fileData.crc = md5(fileBuffer, "base64");
						// return fileData;
						let libraryDetails = {};
						libraryDetails.fileName = fileNamewithOutExt;
						libraryDetails.fileFormat = fileext;
						libraryDetails.fileSize = fileSize;
						libraryDetails.fileS3Url = fileData.fileS3Url;
						libraryDetails.crc = fileData.crc;
						libraryDetails.libraryId = fileData.libraryId;
						libraryDetails.fileType = "Legacy";
						KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
						this.setState({
							srcfile: files,
							actionType: 'Upload',
							uploadOption: 'Legacy',
							errorUploadFile: '',
							loaderUploadIconVisible: false
						})
					}
				};
				xhrimage.send(fileBuffer);
			});
		};
	}

    handleWordCount = (e) => {
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            if (e.target.value.length > 250) {
                this.setState({
                    descriptionErrorMsg: "KC2265"
                });
            }
            else {
                const charCount = e.target.value.length;
                const maxCharCount = this.state.default_Count;
                const charLeft = maxCharCount - charCount;
                this.setState({
                    description: e.target.value,
                    characterCount: charLeft,
                    descriptionErrorMsg: ""
                });
            }
        // }
	}

	render() {
		const { loaderIconVisible, loaderUploadIconVisible, uploadOption, srcfile, upload_filename, errorUploadFile, errorUploadFileName, default_Count, characterCount, description, descriptionErrorMsg, fileExtFiled, fileExtension } = this.state;
		let { success_modal, error_modal, isNextEnabled } = this.state;
		const { formatMessage } = this.props.intl;

		if (srcfile && uploadOption && upload_filename) {
			isNextEnabled = true;
		}
		else {
			isNextEnabled = false;
		}

		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="softwareUpdateWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to="/libraryList" title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
								</li>
								<li>
									<FormattedMessage id="KC2257"/>
				            	</li>
								<li>
									<button id="btnnext" className={isNextEnabled ? "btn-default-text activeState" : "disabled border0"} onClick={(e) => this.MenuUploadFinish(e)} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0543"/></button>
								</li>
							</ul>
						</div>
                        <div class="uploadFormWrapper">  
                            <div class="uploadForm">
                                <ul> 
                                    <li>
                                       	<DragAndDropUpload handleDrop={this.handleDrop}>
											<ul>
												<li>
													<div class="drogAndDrop">{ (fileExtension.toLowerCase() === 'mccb' && fileExtFiled && fileExtFiled.name) ? fileExtFiled.name : ((srcfile && srcfile.name)) ? srcfile.name : formatMessage({ id: 'KC2258' })}</div></li> 
												<li>
													<button id="btnBrowse" class="browseFile" title="Browse"><FormattedMessage id="KC0664"/></button>
													<input id="browseUpdate" type="file" className="browseFileS" onChange={(e) => this.UploadmenuFile(e)} />
												</li>
											</ul>
											{loaderUploadIconVisible ? <div class="loading">&nbsp;</div>  : ""}
										</DragAndDropUpload>
                                        <div class="required">{errorUploadFile !== '' ? <FormattedMessage id={errorUploadFile} /> : ""}</div>
                                    </li> 
									
                                    <li>
									{uploadOption ?
                                        <div class="selectedCB">
                                            {uploadOption === "CookBook" ? <span class="cookbook"><FormattedMessage id="KC2009"/></span> : uploadOption === "Settings" ? <span class="settings"><FormattedMessage id="KC1190"/></span> : uploadOption === "Legacy" ? <span class="legacy"><FormattedMessage id="KC1113"/></span> : uploadOption === "Package" ? <span class="package"><FormattedMessage id="KC0737"/></span> : ""}
                                        </div>
										: <div class="input-group">
											<input type="text" readOnly class="form-control fileType" placeholder={formatMessage({ id: 'KC1143' })} /> 
										</div>
									}
                                    </li> 
                                    <li>
                                        <label><FormattedMessage id="KC2259"/> *</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" maxLength="20" placeholder={formatMessage({ id: 'KC2261' })} value={upload_filename} onChange={(e) => this.onUploadNameChange(e)} />  
                                            <div className="errorMsg">{errorUploadFileName !== '' ? <FormattedMessage id={errorUploadFileName} /> : ''}</div>
                                        </div>
                                    </li>
                                    <li>
                                        <label><FormattedMessage id="KC0195"/></label>
                                        <div class="input-group">
                                            <textarea class="form-control" rows="5" cols="50" placeholder={formatMessage({ id: 'KC0560' })} value={description} maxLength={default_Count} onChange={this.handleWordCount} ></textarea> 
                                            <div className="errorMsg">{descriptionErrorMsg !== '' ? <FormattedMessage id={descriptionErrorMsg} /> : ''}</div>
                                            <div class="charCount">{characterCount}</div>
                                        </div>
                                    </li>
                                </ul> 
                            </div>
                        </div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};
export default injectIntl(MenuLibraryUpload);
