import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile,  MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import _ from 'lodash';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { injectIntl } from 'react-intl';
import ViewTopology from '../../../../../components/controls/viewTopology';
import KCStore from '../../../../../utils/kcStorage';
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class AssignSettingsToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    componentWillMount = () => {
        let { previouspath } = this.props.location;
        let libraryDetails = KCStore.getItem("libraryDetails");
        let libraryDetailsObj = JSON.parse(libraryDetails);
        let libraryImage = KCStore.getItem("libraryImage");

        this.setState({
            previouspath: previouspath,
            libraryDetails: libraryDetails,
            imageDataBuffer: Buffer.from(libraryImage, "base64"),
            isEdit: libraryDetailsObj.isEdit,
            isImageChanged: libraryDetailsObj.isImageChanged,
            imageDatab64: libraryImage
        });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader )) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        if (libraryDetails.initialMethod === "Upload") {
            this.props.history.push({
                pathname: "/uploadSelectDevice",
                previouspath: "/assignSettingsToTopology"
            });
        }
        else {
            this.props.history.push({
                pathname: "/settingsEditor",
                imageData: this.state.imageData,
                type: "Settings",
                imageDataBuffer: this.state.imageDataBuffer,
                previouspath: "/assignSettingsToTopology",
                isEdit: this.state.isEdit,
                imageDatab64: this.state.imageDatab64
            });
        }
    }

    submitAddLibrary = (e,_customId) => {
        e.preventDefault();
        let _this = this;
        let httpMethod;
        _this.setState({
            loaderIconVisible: true
        })
        let brandName = KCStore.getItem("brandName");
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;

        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customerid': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'basecountry': KCStore.getItem("basecountry"),
                'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(response => {
            let libraryId;
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            if (libraryDetails.isEdit) {
                httpMethod = "PUT";
                libraryId = libraryDetails.library_id;
            } else {
                httpMethod = "POST";
                libraryId = libraryDetails.settingName.replace(/[^a-zA-Z0-9\n\r.]+/g, '') + '_' + Date.now();
                libraryId = libraryId.replace(/\s+/g, '');
            }
            
            let libraryData = KCStore.getItem("librarySettingsData");
            let libraryDataObj = JSON.parse(libraryData);
            libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
            libraryDataObj.edit_date = todaysDateUTCFormatted;
            libraryDataObj.author = response.data.name;
            libraryDataObj.email = loginUser.username;
            delete libraryDataObj.Import;

            libraryDataObj.scheduler.map((parent, i) => {
                parent.configurations.map((item, j) => {
                    delete item.id;
                })
            })

            if (libraryDataObj.scheduler && libraryDataObj.scheduler.length === 0)  {
                delete libraryDataObj.scheduler;
            }

            if (libraryDataObj.preheat_list && libraryDataObj.preheat_list.length === 0)  {
                delete libraryDataObj.preheat_list;
            }

            if (libraryDataObj.preheat_times && libraryDataObj.preheat_times.length === 0)  {
                delete libraryDataObj.preheat_times;
            }

            // convert JSON object to beautify String
            var jsonStr = beautify(libraryDataObj, null, 2, 80)
            // read json string to Buffer
            const jsonBuffer = Buffer.from(jsonStr);

            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);

            let settings = libraryDataObj.sounds;
            settings.map((item, i) => {
                fileSize = fileSize + parseFloat(item.fileSize ? item.fileSize : 0);
            })
            
            let md5 = require('md5');
            let crc = md5(jsonBuffer, "base64");

            let imagefile = settings && settings.find(a => a.fileType);
            let convertFloat;
            if(imagefile !== undefined){
                convertFloat = parseFloat(imagefile.fileSize ? imagefile.fileSize : 0);
            }
            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": libraryDetails.settingName,
                "libraryFileName": libraryId + ".json",
                "libraryFileFormat": "json",
                "libraryFileSize": (fileSize + convertFloat).toFixed(2),
                "libraryS3Url": "",
                "type": "Settings",
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": "",
                "imageFileFormat": "",
                "imageFileSize": imagefile.fileSize ? imagefile.fileSize : 0,
                "imageS3Url": "",
                "cgids": _customId,
                "json": JSON.stringify(libraryDataObj),
                "settings": settings ? settings : [],
                "libraryGuid": libraryDataObj.id
            };

            if (libraryDetails.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response && response.data && response.data.name + ' (' + loggedinUser + ')';
            }

            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': jsonBuffer ? libraryId + '.json' : "",
                'audiofilekey': '',
                'isthumbnail': false
            }

            uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "KC1334" : "KC1335"
                    },
                });
            }).catch(err => {
                _this.showError(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
        });
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1336`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")}  
                    preSelect={libraryDetails.isEdit ? true : false} 
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignSettingsToTopology));