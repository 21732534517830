import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { BaseService, MenuUploadAPI, UserProfile,UnitInfo } from '../../../service/api';
import { CUSTOMER_ID,BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
class MenuBrands extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			brandList: [],
			loaderIconVisible: true,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			pushOption: "New",
			isAvailable: false
		};
		this.onButtonClickHandler = this.onButtonClickHandler.bind(this);
	}

	getBrands = () => {
		this.setState({ loaderIconVisible: true });
		let url = BaseService.root + MenuUploadAPI.getMenuBrandList;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				brandId: BRAND_ID,
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let {branList} = response.data;
				let convobrand = branList.filter(b => b.BRAND_ID === 6);
				this.setState({
					loaderIconVisible: false,
					brandList: branList,
					isAvailable: convobrand.length > 0 ? true : false
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(KCStore.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customerid': KCStore.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json',
					'basecountry': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				KCStore.setItem("loginInfo", JSON.stringify(loginInfo));
				// this.setState({
				// 	loaderIconVisible:false,
				// })
			}).catch(err => {
				// this.setState({ loaderIconVisible:false });
			});
		}
	}

	componentDidMount = async() => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener("resize", this.updateDimensions());
		KCStore.removeItem('modelIdValue');
		KCStore.removeItem('softwareModelId');
		KCStore.removeItem('selectedFamilyID');
		KCStore.removeItem('showHatIcon');		
		this.getUserInfo();
		await this.getBrands();		
		let zendeskscript = document.getElementById('ze-snippet');
        if (zendeskscript) {
            zendeskscript.remove();
        }
	}

	updateDimensions() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

	componentDidUpdate = () => {
        this.updateDimensions();
    }

	onButtonClickHandler = async (item) => {
		KCStore.setItem("brandName",item.BRAND_NAME);
		KCStore.setItem("selectedbrandID",item.BRAND_ID);
		WoopraEvents(`${Constants.MENU_BRAND_SELECTED} ${item.BRAND_NAME}`);
		if (item.BRAND_NAME === "MERCO"  || item.BRAND_NAME === "GARLAND" || item.BRAND_NAME === "FRYMASTER" || item.BRAND_NAME === "MULTIPLEX" || item.BRAND_NAME === "LINCOLN" || item.BRAND_NAME === "FRYMASTER-UHCTHD" ||  item.BRAND_NAME === "XPRESSCHEF")
		{
			this.props.history.push(`/menuScheduleUpdateNow`);
		}
		else if (item.BRAND_NAME === "CREM")
		{
			this.props.history.push(`/menuLibrary`);
		}
		else if (item.BRAND_NAME === "MERRYCHEF")
		{
			if (KCStore.getItem("brandName") === "MERRYCHEF" || KCStore.getItem('brandName') === "GARLAND") {
				const modelFId = KCStore.getItem("brandName") === "MERRYCHEF" ? '1,2,40,41' : '31,32';
				const brandId = KCStore.getItem("brandName") === "MERRYCHEF" ? '1' : '9';
				await this.getUnitList(modelFId, brandId);
			}
			this.props.history.push(`/libraryList`);
		}
		else if(item.BRAND_NAME === "CONVOTHERM" )
		{
			this.props.history.push(`/legacyLibraryList`);
		}
	}

	getUnitList(modelFamilyIds, brandId) {
		this.setState({
			loaderIconVisible: true
		});
		http.get(`${UnitInfo.list}?pageNum=1&pageSize=24`,
			{
				headers: {
					'Content-Type': 'application/json',
					'cgid': KCStore.getItem("custGroupID"),
					'cid': CUSTOMER_ID,
					'modelfamilyids': modelFamilyIds,
					'brandid': brandId,
					'model': '',
					'machinetype': '',
				},
				data: {}
			}).then(response => {
				let { data } = response;
				let units = data && data.units ? data.units : [];
				let isUnitAvailable = units.length > 0 ? true : false;
				KCStore.setItem("showHatIcon",isUnitAvailable);				
			}).catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}

	// Woopra Events
	trackEvent =(e) => {
		WoopraEvents(`${Constants.MENU}-${e.trackTitle}`)
	}

	render() {
		const { formatMessage } = this.props.intl;
		let {brandList, loaderIconVisible, pushOption, isAvailable} = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		let loginInfo = JSON.parse(KCStore.getItem("loginInfo"));
		if ((Object.keys(brandList).length > 0) && brandList && (brandList !== null) && (brandList !== undefined)) {
			branList = MenuBrandsResponse(brandList);
			branList = _.orderBy(branList, "BRAND_NAME", "asc");
			menuItemList = (
				branList.map((item) =>
					<ul className="tBody" key={item.ID} onClick = {() => this.onButtonClickHandler(item)}>
						<li>{orderId++}</li>
						<li>{item.BRAND_NAME} </li>
						<li className={item.BRAND_NAME === "CONVOTHERM" ? "logoH" : ""}>{<img src={item.img} />} </li>
						<li><button type="button"  class="btn forwardNext">&nbsp;</button></li>
					</ul>
				)
			)
		}
		else {
			menuItemList = (
				<div className="menuBrandsTable">
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={branList !== undefined ? branList.length : 0} classname={'norecordsfoundbluetext'} />
					</div>)
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div className="menuUpdateWrapper">
						
						<div className="menuUpdateHeader">
							<ul>
								<li>
									<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })} className="active" onClick={(e)=> {e.trackTitle="Brands";this.trackEvent(e)}}><FormattedMessage id="KC0661"/></Link>
									{loginInfo.rolename !== "Public Admin" && <Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })} onClick={(e)=> {e.trackTitle="Update Status";this.trackEvent(e)}}><FormattedMessage id="KC0659"/></Link>}
									{isAvailable && <Link to="/menuApps" title={formatMessage({ id: 'KC0660' })} onClick={(e)=> {e.trackTitle="Apps";this.trackEvent(e)}}><FormattedMessage id="KC0660"/></Link>}
								</li>
							</ul>
						</div>

						<div className="menuBrandsTable">
								<ul className="tHead">
									<li>#</li>
									<li><FormattedMessage id="KC0873"/></li>
									<li><FormattedMessage id="KC2332"/></li>
									<li>&nbsp;</li>
								</ul>
						</div>
						<div className='pageScrollbar'>
						<form className="menuBrandsTableDiv">
							<div className="menuBrandsTable">
								
								{menuItemList}
							</div>
						</form>
						</div>
					</div>
				</div>
				{/* <!-- Select Flow Modal --> */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo"> 
                                    <h5><FormattedMessage id="KC1114"/></h5>
                                    <div className="uploadOption">
                                        <div className="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="New" checked={pushOption === "New" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromLibrary" value="New"><FormattedMessage id="KC1743"/></label>
                                        </div> 
                                        <div className="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Legacy" checked={pushOption === "Legacy" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromDevice" value="Legacy"><FormattedMessage id="KC1113"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelPushClick}><FormattedMessage id="KC0021"/></button>
                                <button className="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.submitPushClick}><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Select Flow Modal --> */}
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {

	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MenuBrands));