import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import ViewTopology from '../../../../../components/controls/viewTopology';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

var cookbookJSON = require('../../Templates/CookbookJSON');
var beautify = require("json-beautify");

var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class AssignFrymasterMenuToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response, stringID } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        if (libraryDetails.initialMethod === "Upload") {
            this.props.history.push({
                pathname: "/uploadSelectDevice",
                previouspath: "/assignFrymasterMenuToTopology"
            });
        }
        else {
            this.props.history.push({
                pathname: "/FrymasterMenuEditor",
                isEdit: false,
                previouspath: "/assignFrymasterMenuToTopology",
            });
        }
    }

    submitAddLibrary = (e,_customId) => {
        e.preventDefault();
        let _this = this;
        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;
        
        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/frymasterMenuEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customerid': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'basecountry': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = libraryDetails.cookbookName + '_' + Date.now();
                    libraryId = libraryId.replace(/\s+/g, '');
                }
                
                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let categoryDetailsData = KCStore.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;

                jsonTemplateObj.autoMenuChange = libraryDetails.autoMenuChange ? libraryDetails.autoMenuChange : [];
                jsonTemplateObj.limitedTimeOffer = libraryDetails.limitedTimeOffer ? libraryDetails.limitedTimeOffer : [];

                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_US = item.categoryName;
                    // jsonTemplateObj.menus[i].image = "";
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = "";
                    if (item.recipe_items) {
                        item.recipe_items.map((subitem, j) => {
                            imageSize = imageSize + subitem.IMAGE_FILE_SIZE ? parseFloat(subitem.IMAGE_FILE_SIZE) : 0.00;
                        })
                    }
                })

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": (imageSize).toFixed(2),
                    "imageS3Url": "",
                    "cgids": _customId,
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categoryDetailsObj ? categoryDetailsObj : [],
                    "libraryGuid": jsonTemplateObj.id
                };

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "KC1400" : "KC1401"
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology
                    title={formatMessage({ id: 'KC1496' })} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")} 
                    preSelect={libraryDetails.isEdit ? true : false} />
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignFrymasterMenuToTopology));
